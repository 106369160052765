import { TextField, Typography, useMediaQuery } from '@mui/material';
import { StockCheckStatus } from './../../../enums/StockCheckStatus';
import { theme } from '../../../theme';
import { StockCheck, StockCheckItem } from './../../../app/services/api/generated';

type IProps = {
    item: StockCheckItem,
    uom: string,
    stockCheckDetail?: StockCheck,
    selectedZoneId: string,
    allZonesEnabled: boolean,
    handleQuantityChange: (itemId: number | undefined, valueId: number | undefined, newQuantityString: string) => void;
    handleQuantityBlur: (itemId: number | undefined, valueId: number | undefined, newQuantityString: string) => void;
}

const StockCountQuantityInput = ({ item, uom, stockCheckDetail, selectedZoneId, allZonesEnabled, handleQuantityChange, handleQuantityBlur } : IProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const foundItem = item?.itemValues?.find(value => value.uom === uom);

  return (
    <div style={{ width:'144px', marginLeft: '10px',
      marginRight: '10px',  display:'flex', alignItems:'center' }}>
      <div>
        <span className="title"> {uom}</span>
        {uom == 'EA' ? (<div  className="conversion-to-each"
          style={{ color: theme.palette.custom.gray[400],  width: isMobile ? '60px' : '10px' }}></div>):
          (<div  className="conversion-to-each"
            style={{ color: theme.palette.custom.gray[400],  width: '60px' }}>
            {foundItem && foundItem.conversionToEach  &&
            
              <Typography  variant='textXS'>({foundItem.conversionToEach} EA)</Typography>
            }</div>
          )}
      </div>

      {foundItem ? (
        <>
          
          <TextField
            className="quantity-field"
            type="text"
            size="small"
            name="quantity-input"
            variant="outlined"
            value={foundItem.countQuantity ?? ''}
            disabled={stockCheckDetail?.status === StockCheckStatus.Pending || (selectedZoneId === '-1' && !allZonesEnabled)}
            sx={{ 
              width: '73px', ml: '10px', backgroundColor: stockCheckDetail?.status === StockCheckStatus.Pending
                ? theme.palette.custom.gray[200]
                : undefined,
            }}
            onChange={(e) => handleQuantityChange(item.id, foundItem.id, e.target.value)}
            onBlur={(e) => handleQuantityBlur(item.id, foundItem.id, e.target.value)}
          />
        </>
      ) : (
        <>
          <span
            className="quantity-field"
            style={{
              width: '73px', 
              height:'40px', 
              marginLeft: '10px', 
              paddingTop: '8.5px',
              borderRadius: '4px',
              paddingBottom: '8.5px',
              paddingLeft: '14px',
              paddingRight: '14px', 
              lineHeight: '23px',
            }}
          >---</span>
        </>
      )}
    </div>
  );
};

export default StockCountQuantityInput;
