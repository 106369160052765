import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Autocomplete, FormControl, TextField, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../../contexts/PurchaseOrderDetailsContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const PurchaseOrderDetailsAddItemsModal = () => {
  const { t } = useTranslation('purchaseOrderDetails');

  const { handleUomChange,
    showAddItemModal,
    closeAddItemModal,
    handleSearchTermChange,
    items,
    isLoadingItemData,
    itemSelected,
    selectedItems,
    searchTerm,
    handleModalQuantityChange,
    handleCostPriceChange,
    confirmAddItems,
    lines } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;

  return (
    <Dialog onClose={closeAddItemModal}
      maxWidth='md'
      fullWidth
      open={showAddItemModal}>
      <DialogTitle>
        {t('addModal.title')}
      </DialogTitle>
      <IconButton
        onClick={closeAddItemModal}
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <FormControl fullWidth>
          <Autocomplete
            id='selected-recipe-category'
            fullWidth
            multiple
            disableClearable
            options={items}
            getOptionDisabled={(option) =>
              selectedItems.map(x => x.itemNumber).includes(option.itemNumber) || lines.map(x => x.itemNumber).includes(option.itemNumber)
            }
            onChange={(e, i) => i && itemSelected(i)}
            loading={isLoadingItemData}
            inputValue={searchTerm}
            onInputChange={(event, newInputValue) => {
              handleSearchTermChange(newInputValue);
            }}
            getOptionKey={(option) => option.id ?? ''}
            getOptionLabel={(option) => (option && `${option.itemNumber} - ${option.description}`) ?? ''}
            renderInput={(params) => (
              <TextField {...params}
                label={t('addModal.searchForItem')}
              />
            )}
          />
        </FormControl>
        {selectedItems.length > 0 &&
          <TableContainer sx={{ mt: '12px' }}>
            <Table>
              <TableHead>
                <TableCell>
                  Number
                </TableCell>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  UOM
                </TableCell>
                <TableCell>
                  Quantity
                </TableCell>
                <TableCell>
                  Cost Price
                </TableCell>
              </TableHead>
              <TableBody>
                {selectedItems.map(item => <TableRow key={item.id}>
                  <TableCell>
                    {item.itemNumber}
                  </TableCell>
                  <TableCell>
                    {item.description}
                  </TableCell>
                  <TableCell>
                    <Select value={item.uom ?? ''}
                      onChange={(e) => handleUomChange(item.id ?? -1, e.target.value)}>
                      {item.possibleUoms?.map((uom) => (
                        <MenuItem key={uom.code}
                          value={uom.code}>
                          {`${uom.description} (${uom.code})`}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField value={item.quantity}
                      type='number'
                      onChange={(e) => handleModalQuantityChange(item.id ?? -1, e.target.value)}></TextField>
                  </TableCell>
                  <TableCell>
                    <TextField value={item.costPrice}
                      type='number'
                      onChange={(e) => handleCostPriceChange(item.id ?? -1, e.target.value)}></TextField>
                  </TableCell>
                </TableRow>)}
              </TableBody>
            </Table>
          </TableContainer>}
      </DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='primary'
          disabled={selectedItems.some(x => ((x.quantity ?? 0) <= 0) || ((x.costPrice ?? 0) <= 0))}
          size="lg"
          onClick={confirmAddItems}>
          {t('addModal.addItems')}
        </Button>
      </DialogActions>
    </Dialog >
  );
};