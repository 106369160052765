import { Box } from '@mui/material';
import React from 'react';

interface PageAreaProps {
  children: React.ReactNode
}


const PageArea: React.FC<PageAreaProps> = props => {
  return (
    <Box className="page-area" 
      sx={{ padding:'32px' }}>
      {props.children}
    </Box>
  );
};

export default PageArea;