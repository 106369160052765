import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateTransactionsContext, CreateTransactionsValues } from '../contexts/CreateTransactionsContext';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { CreateTransactionsItemsTable } from './CreateTransactionsItemsTable';
import { CreateTransactionsHeader } from './CreateTransactionsHeader';
import { CreateTransactionsButtons } from './CreateTransactionsButtons';
import { CreateTransactionsTopForms } from './CreateTransactionsTopForms';
import { CreateTransactionsNote } from './CreateTransactionsNote';
import { CreateTransactionsDuplicateConfirmDialog } from './CreateTransactionsDuplicateConfirmDialog';
import { CreateTransactionsChangeCostConfirmDialog } from './CreateTransactionsChangeCostConfirmDialog';
import { CreateTransactionsChangeTypeConfirmDialog } from './CreateTransactionsChangeTypeConfirmDialog';
import { applyRoundAndFormat } from '../../../../../utils';
import LeavePageModal from '../../../../../components/shared/dialogs/LeavePageModal';

export const MainDialog = () => {
  const { onCancel, openModal, totalReceiptValue, t, currencyCode, hasNewChanges } = useContext(CreateTransactionsContext) as CreateTransactionsValues;
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState<boolean>(false);

  const handleCancel = () => {
    if(hasNewChanges) {
      if (!showCancelConfirmationModal) {
        setShowCancelConfirmationModal(true);
      }}
    else{
      onCancel();
    }
  };

  const handleCancelConfirmation = (confirmed: boolean) => {
    setShowCancelConfirmationModal(false);

    if(confirmed){
      onCancel();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      onClose={handleCancel}
      open={openModal}
    >
      <CreateTransactionsHeader handleCancel={handleCancel}/>
      <DialogContent sx={{ pb: 0 }}>
        <Grid container
          spacing={5}>
          <CreateTransactionsTopForms />
          <CreateTransactionsItemsTable />
          <CreateTransactionsNote />
          {totalReceiptValue > 0 && <Grid xs= {12}
            textAlign={'center'}
            sx={{ mt:10 }}>
            <Typography variant="textLG">
              { t( 'totalReceiptValue') + ' ' + applyRoundAndFormat(totalReceiptValue, currencyCode)}
            </Typography>
          </Grid>
          }
        </Grid>
      </DialogContent>
      <CreateTransactionsButtons onCancel = {handleCancel} />
      
      <CreateTransactionsDuplicateConfirmDialog></CreateTransactionsDuplicateConfirmDialog>
      <CreateTransactionsChangeCostConfirmDialog></CreateTransactionsChangeCostConfirmDialog>
      <CreateTransactionsChangeTypeConfirmDialog></CreateTransactionsChangeTypeConfirmDialog>
      {showCancelConfirmationModal  && hasNewChanges && (
        <LeavePageModal open = {showCancelConfirmationModal}
          onConfirmUrl={'/transactions'}
          onClose={()=>handleCancelConfirmation(false) }
          onConfirm={() => handleCancelConfirmation(true)}
          leaveWarningTitle =  {t('leaveModalTitle')}
          leaveWarningContent = {t('leaveModalContent')} />
      )}
    </Dialog>
  );
};

