import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import useLogError from '../../../hooks/useLogError';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useTranslation } from 'react-i18next';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import RoundAndFormatValue from '../../../components/shared/RoundAndFormatValue';
import { formatDateString } from '../../../utils';
import { UserContext } from '../../../components/shared/useUser';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

type Props = {
  currencyCode?: string;
}

const Cost: FC<Props> = ({ currencyCode }) => {
  const { t } = useTranslation('productDetails');
  const { selectedStore } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const sku = searchParams.get('sku');
  const { logError } = useLogError();
  const { getItemCostHistory } = useNswagClient();
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>([]);
  const [date, setDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

  useEffect(() => {
    if (selectedStore) {
      loadData();
    }
  }, []);

  const loadData = (selectedDate?: string) => {
    if (sku && selectedStore?.storeNumber) {
      setLoading(true);
      getItemCostHistory(sku, selectedStore?.storeNumber, selectedDate)
        .then((result) => {
          setData(result?.data ?? []);
        })
        .catch((error) => {
          logError(error);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
    loadData(event.target.value);
  };

  const handleClearFilters = async () => {
    setDate(dayjs().format('YYYY-MM-DD'));
    loadData();
  };
  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', borderRadius: '5px', width: '100%', height: '100%', my: 5, px: 5, pb: 5 }}>
      <Grid
        container
        md={12}
        xs={12}
        py={5}
      >
        <Grid item
          xs={12} 
          md={4}
          p={5}>
          <TextField
            label={t('costsTab.date')}
            type="date"
            required
            value={date}
            onChange={handleDateChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid> 
        <Grid item
          xs={12}
          md={3}
          p={5}>
          <Button fullWidth
            variant="secondary"
            size="lg"
            onClick={handleClearFilters}>
            {t('clear')}
          </Button>
        </Grid>
      </Grid>
      
      <Grid item
        mt={5}
        xs={12}>
        {
          loading ? <LoadingWheel /> : (
            <TableContainer>
              <Table>
                <TableHead
                  sx={{ paddingTop: '10px' }}>
                  <TableRow>
                    <TableCell>
                      {t('costsTab.quantity')}
                    </TableCell>
                    <TableCell>
                      {t('costsTab.unitCost')}
                    </TableCell>
                    <TableCell>
                      {t('costsTab.totalCost')}
                    </TableCell>
                    <TableCell>
                      {t('costsTab.date')}
                    </TableCell>
                    <TableCell>
                      {t('costsTab.reason')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data?.length === 0 ?
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Typography variant="textSM">{t('noData')}</Typography>
                        </TableCell>
                      </TableRow>
                      : 
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      data?.map((item: any) => (
                        <TableRow key={item.date}>
                          <TableCell>
                            {item?.quantity?.toFixed(2) ?? 'N/A'}
                            {item?.uom}
                          </TableCell>
                          <TableCell>
                            <RoundAndFormatValue
                              value={item?.unitCostPrice ?? 0}
                              currencyCode={currencyCode}
                            />
                          </TableCell>
                          <TableCell>
                            <RoundAndFormatValue
                              value={item?.totalCostPrice ?? 0}
                              currencyCode={currencyCode}
                            />
                          </TableCell>
                          <TableCell>
                            {formatDateString(item?.date)}
                          </TableCell>
                          <TableCell>
                            {item?.reason}
                          </TableCell>
                        </TableRow>
                      ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )
        }
      </Grid>
    </Paper>
  );
};

export default Cost;