import { Box, Typography, Grid, TextField, Button, SvgIcon } from '@mui/material';
import CustomPagination from '../../../../components/forms/Pagination';
import LoadingWheel from '../../../../components/ui/LoadingWheel';
import { theme } from '../../../../theme';
import { Search } from '@mui/icons-material';
import { UserContext } from '../../../../components/shared/useUser';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import useLogError from '../../../../hooks/useLogError';
import { useContext, useState, useEffect, FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import AllItemsList from './AllItemsList';
import { Item } from '../../../../app/services/api/generated';

type Props = {
  hasNewItemCreated: number;
  setItemCount: Dispatch<SetStateAction<number | undefined>>;
  itemCount: number | undefined;
}

const ItemsTab: FC<Props> = ({ hasNewItemCreated, itemCount, setItemCount }) => {
  const { t } = useTranslation('products');
  const { selectedStore } = useContext(UserContext);
  const { getItems } = useNswagClient();
  const { logError } = useLogError();
  const [allItems, setAllItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof Item>('itemNumber');

  const isAsc = order === 'asc';
  const rowsPerPage = 20;

  const maxPages = () => {
    return Math.ceil(itemCount! / rowsPerPage);
  };

  useEffect(() => {
    loadData();
  }, [hasNewItemCreated, page]);

  const loadData = (passedOrder?: 'asc' | 'desc', passedOrderBy?: keyof Item, passedSearchTerm?: string, passedPage?: number) => {
    const orderToUse = passedOrder ?? order;
    const orderByToUse = passedOrderBy ?? orderBy;
    const searchTermToUse = passedSearchTerm ?? searchTerm;
    const pageToUse = passedPage ?? page;

    const pageSkip = searchTermToUse != '' || pageToUse == 0 ? 0 : rowsPerPage * pageToUse;

    setIsLoading(true);
    if (selectedStore?.storeNumber) {
      getItems(searchTermToUse, selectedStore.storeNumber, undefined, undefined, undefined, undefined, undefined, true, true, false, orderByToUse, orderToUse, pageSkip, rowsPerPage).then((result) => {
        if (result?.data?.items) {
          setAllItems(result.data.items);
          setItemCount(result.data.itemCount);
        }
      })
        .catch((error) => {
          logError(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleSort = (orderBy: keyof Item) => {
    const newDirection = isAsc ? 'desc' : 'asc';
    setOrder(newDirection);
    setOrderBy(orderBy);
    loadData(newDirection, orderBy);
  };

  const onSearch = () => {
    setPage(0);
    loadData(undefined, undefined, undefined, 0);
  };

  const onClear = () => {
    setSearchTerm('');
    setPage(0);
    loadData(undefined, undefined, '', 0);
  };

  return (
    <Box sx={{
      border: '1px solid',
      borderColor: theme.palette.custom.gray[300],
      py: '0px',
      textAlign: 'left',
    }}>
      <Grid sx={{ py: '12px', px: '8px' }}
        container
        spacing={5}
        mb={5}
      >
        <Grid item
          xs={12}>
          <TextField
            label={t('search')}
            size='small'
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            fullWidth />
        </Grid>
        <Grid item
          container
          xs={12}
          spacing={5}
          justifyContent='flex-end'>
          <Grid item
            xs={6}>
            <Button
              disabled={isLoading}
              fullWidth
              onClick={onClear}
              variant='secondary'
              size='lg'
              sx={{ mr: 4 }}
            >
              <Typography>{t('clear')}</Typography>
            </Button>
          </Grid>
          <Grid item
            xs={6}>
            <Button
              disabled={isLoading}
              fullWidth
              onClick={onSearch}
              size='lg'
              startIcon={<SvgIcon><Search /></SvgIcon>}>
              <Typography>{t('search')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {
        isLoading ? (
          <LoadingWheel />
        ) : (
          <>
            <AllItemsList
              list={allItems}
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
            <CustomPagination
              page={page}
              setPage={setPage}
              maxPages={maxPages()}
              breakpointForChangeDisplay={120000}
            ></CustomPagination>
          </>
        )
      }
    </Box>
  );
};

export default ItemsTab;