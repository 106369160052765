import { Grid } from '@mui/material';
import ApplicationRolesBox from './components/ApplicationRolesBox';
import useNswagClient from '../../hooks/api/useNswagClient';
import useLogError from '../../hooks/useLogError';
import PageArea from '../../components/shared/PageArea';
import { useEffect, useState } from 'react';
import { ConfigurationName } from '../../enums/ConfigurationName';
import { Persona, Configuration, SuccessResponse_1OfIEnumerable_1OfPersona, Store, SuccessResponse_1OfList_1OfKeyValuePair_2OfStringAndString, KeyValuePair_2OfStringAndString, SuccessResponse_1OfGetConfigurationResponse } from '../../app/services/api/generated';

const ManageApplicationRoles = () => {
  const { getPersonas, getRoles, getConfiguration } = useNswagClient();
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [roles, setRoles] = useState<KeyValuePair_2OfStringAndString[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { logError } = useLogError();
  const [selectedStore] = useState<Store | null>(() => {
    const storedStore = localStorage.getItem('selectedStore');
    return storedStore ? JSON.parse(storedStore) : null;
  });

  useEffect(() => {
    loadData();
    handleFetchRoles();
  }, []);

  const loadData = () => {
    setIsLoading(true);
    getPersonas()
      .then((result: SuccessResponse_1OfIEnumerable_1OfPersona) => {
        if (result?.data) {
          setPersonas(result.data);
        }
      })
      .catch((error) => {
        logError(error);
      })
      .finally(() => (setIsLoading(false)));
  };

  const handleFetchRoles = () => {
    // we have to filter out FranchiseDynamicBrandingWrite if the configuration is disabled or missing
    getConfiguration(ConfigurationName.FranchiseDynamicBranding, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '')
      .then((result: SuccessResponse_1OfGetConfigurationResponse) => {
        if (result?.data) {
          const config = result.data as Configuration;
          getRoles()
            .then((result: SuccessResponse_1OfList_1OfKeyValuePair_2OfStringAndString) => {
              if (!result?.data || !config || config.value == 'false') {
                result.data = result.data?.filter(role => role.key !== 'FranchiseDynamicBrandingWrite');
              }
              setRoles(result.data ?? []);
            })
            .catch((error) => {
              logError(error);
            });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  return <PageArea>
    <Grid container>
      <ApplicationRolesBox
        personas={personas}
        loadData={loadData}
        isLoading={isLoading}
        roles={roles}/>
    </Grid>
  </PageArea>;
};

export default ManageApplicationRoles;