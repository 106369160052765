import { Box, Button, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useNswagClient from '../../hooks/api/useNswagClient';
import useLogError from '../../hooks/useLogError';
import { GetItemsResponse,  WasteCheckSheetResponse } from '../../app/services/api/generated';
import LoadingWheel from '../../components/ui/LoadingWheel';
import { UserContext } from '../../components/shared/useUser';
import CreateEditWasteChecksheet from './components/CreateEditWasteChecksheet';
import { ExtendedGetItemsResponse } from './interfaces/ExtendedGetItemsResponse';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import BackButton from '../../components/shared/actions/BackButton';

const WasteCheckSheetDetailsPage = () => {
  const navigate = useNavigate();
  const { getItems, getWasteById } = useNswagClient();
  const [itemsData, setItemsData] = useState<ExtendedGetItemsResponse>({});

  const { logError } = useLogError();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { id } = useParams<{ id: string | undefined }>();
  const parsedId = id ? parseInt(id, 10) : 0;

  const { selectedStore } = useContext(UserContext);

  const [submitButtonClicked, setSubmitButtonClicked] = useState<boolean>(false); 
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [submittedBy, setSubmittedBy] = useState<string>('');
  const [submittedDate, setSubmittedDate] = useState<string | undefined>('');
  const [exitAndSave, setExitAndSave] = useState(false);
  const [hasInputValues, setHasInputValues] = useState(false);

  const { t } = useTranslation('wasteChecksheet');

  const hasRecentActivity = true;
  const isDailyWaste = true;
  const sort = 'ItemNumber';

  useEffect(() => {
    loadData();
  }, [selectedStore]);

  const isEditMode = !!(id && id !== '0');
  
  const loadData = () => {
    const storeNumber = selectedStore?.storeNumber;
    setIsLoading(true);

    // Call both API functions concurrently
    Promise.all([
      getItems(undefined, storeNumber, hasRecentActivity, undefined, undefined,undefined, undefined, undefined, undefined, isDailyWaste, sort),
      isEditMode && id ? getWasteById(parseInt(id, 10)) : Promise.resolve(null),
    ])
      .then(([itemsResult, wasteResult]) => {

        // Handle items data
        if (itemsResult?.data) {
          setItemsData(itemsResult.data);
          setIsLoading(false);
        }

        // Handle waste data
        if (wasteResult?.data && itemsResult?.data) {
          mapWasteDataToItems(wasteResult.data, itemsResult.data);
        } else {
          console.log('ID is undefined');
        }

      })
      .catch((error) => {
        setIsLoading(false);
        logError(error);
      });
  };

  const mapWasteDataToItems = (wasteData: WasteCheckSheetResponse, itemsDataToBeMapped: GetItemsResponse) => {
    const wasteItems = wasteData?.wasteHeaders?.items ?? [];
    if(wasteData.wasteHeaders?.submittedBy){
      setIsArchived(true);
      setSubmittedDate(wasteData.wasteHeaders?.submittedDate);
      setSubmittedBy(wasteData.wasteHeaders?.submittedBy);
    }
    // Create a map for faster lookup
    const wasteItemsMap = new Map(wasteItems.map((item) => [item.itemId, item]));
  
    const updatedItemsData = itemsDataToBeMapped?.items?.map((item) => {
      const wasteItem = wasteItemsMap.get(item.id);
      if (wasteItem) {
        return {
          ...item,
          pastDueQty: wasteItem.pastDueQty,
          sampledQty: wasteItem.sampledQty,
          damagedQty: wasteItem.damagedQty,
          storeUseQty: wasteItem.storeUseQty,
        };
      }
      return item;
    });
      // Update the state with the updated itemsData
    setItemsData((prevState) => ({
      ...prevState,
      items: updatedItemsData,
    }));
  };

  const handleExitAndSave = () => {
    setExitAndSave(true);
  };

  const getTitle = () => { 
    if(isArchived) {
      return t('finishedTitle');
    }

    if (isEditMode) {
      return t('editTitle');
    }  
    
    return t('createNew');
  };

  const handleBack = () => {
    navigate('/store/waste-checksheets');
  };

  const checkInputValues = (hasInputValues: boolean) => {
    setHasInputValues(hasInputValues);
  };


  return (
    <Box display="flex"
      width="100%"
      height="100%"
      flexDirection="column" >
      <PageArea>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          
          { (isArchived || !isDirty) ? (
            <BackButton 
              handleClick={handleBack} 
              title={t('back')}
              isLink={false}
            />
          ) : (
            <BackButton 
              handleClick={handleExitAndSave} 
              title={t('exitAndSave')}
              isLink={false}
            />
          )}
        </Grid>
        {!isLoading &&  <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <PageTitle>{getTitle()}</PageTitle>
        </Grid>}

        {!isArchived && <Grid item
          justifyContent="flex-end"
          xs={12}
          sx={{ textAlign: 'right' }}>
          <Button
            variant="primary"
            size="lg"
            type='submit'
            sx={{ ml: 8 }}
            disabled={!hasInputValues}
            onClick={() => {setSubmitButtonClicked(!submitButtonClicked);}}
          >
            {t('submitBtn')}
          </Button>
        </Grid>}
       
        <Grid item
          xs={12}
          sx={{ pt: '20px' }}>
          {isLoading && <LoadingWheel />}
          {itemsData && !isLoading && selectedStore &&
            <CreateEditWasteChecksheet itemsData={itemsData}
              isEditMode={isEditMode}
              id={parsedId}
              submitButtonClicked={submitButtonClicked}
              storeNumber= {selectedStore.storeNumber}
              isArchived = {isArchived}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              submittedBy={submittedBy}
              submittedDate={submittedDate}
              exitAndSave={exitAndSave}
              checkInputValues={checkInputValues}
            />
          }
        </Grid>
      </PageArea>
    </Box>
  );
};

export default  WasteCheckSheetDetailsPage ;