/* eslint-disable indent */
import { Link } from '@mui/material';
import { ArrowLeft } from '../../../assets';

type BackButtonType = {
    title: string; 
    isLink: boolean; 
    url?: string;
    styles?: {},
    handleClick?: () => void;
}

const BackButton = ({ url, title, isLink, styles, handleClick }: BackButtonType) => (
    <Link
        {...(isLink ? { href: url } : { onClick: handleClick, component: 'button' })}
        underline="none"
        sx={{
            ...styles,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px',
            lineHeight: '20px',
            width: 'fit-content',
            fontWeight: '600',
            underline: 'none',
            cursor: 'pointer',
        }}
    >
        <ArrowLeft
            style={{
                width: '20px',
                height: '20px',
                marginRight: '6px',
            }}
        />
        <span>{title}</span>
    </Link>
);

export default BackButton;
