import { AppBar, Toolbar, IconButton } from '@mui/material';
import { Menu02 } from '../../../../assets';
import { useContext } from 'react';
import { DrawerContext, DrawerValues } from '../contexts/DrawerContext';

export const DrawerMobileAppBar = () => {
  const { setOpen, isMobile } = useContext(DrawerContext) as DrawerValues;

  if (!isMobile) return <></>;
  
  return (
    <AppBar sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      px: 6,
    }}>
      <img alt='logo'
        src={'/logo-reverse.svg'}
        height='26px' />
      <Toolbar sx={{ justifyContent: 'flex-end' }}>
        <IconButton
          size="large"
          aria-label="menu"
          edge="end"
          sx={{
            mr: 2,
            color: 'common.white',
          }}
          onClick={() => setOpen(true)}
        >
          <Menu02 />
        </IconButton>
      </Toolbar>
    </AppBar>);
};