import { Box, Button, SvgIcon, Tab, Tabs, Typography } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTabPanel } from './components/CustomTabPanel';
import { theme } from '../../theme';
import ReportDialog from './components/shared/ReportDialog';
import { ReportNavItem, ReportTypesData } from './components/shared/ReportTypesData';
import { ReportTabType } from '../../enums/ReportTabType';
import ReportHistoryTab from './components/ReportHistoryTab';
import UserReportsTab from './components/UserReportsTab';
import { UserContext } from './../../components/shared/useUser';
import useNswagClient from './../../hooks/api/useNswagClient';
import { ReportType } from './../../enums/ReportType';
import { ConfigurationName } from './../../enums/ConfigurationName';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

const Reporting = () => {
  const { t } = useTranslation('common');
  const [tab, setTab] = useState(0);
  const { user, selectedStore, hasPermissionTo, isAdmin } = useContext(UserContext);

  const { getConfiguration } = useNswagClient();
  const [open, setOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportNavItem>(ReportTypesData[0]);
  const [filteredReports, setFilteredReports] = useState<ReportNavItem[]>([...ReportTypesData]);

  useEffect(() => {
    if (selectedStore) {
      getFilteredReports();
    }
  }, [user]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleGenerateClick = (reportType: string) => {
    const item = ReportTypesData.find(x => x.title === reportType);

    if (!item) return;

    setSelectedReport(item);
    setOpen(true);
  };

  const getFilteredReports = () => {
    if (isAdmin() || selectedStore?.franchiseName === 'EG ON THE MOVE LTD') {
      setFilteredReports((prev) => [...prev]);
    }
    if (!isAdmin() && selectedStore?.franchiseName !== 'EG ON THE MOVE LTD') {
      setFilteredReports((prev) => [...prev.filter(x => x.title !== ReportType.StockCountSummary)]); 
    }
    if (!hasPermissionTo(['MadeInStoreRead'])) {
      setFilteredReports((prev) => [...prev.filter(x => x.title !== ReportType.MadeInStore)]);
    }

    if (!selectedStore?.franchiseName?.toLowerCase().includes('amrest')){
      setFilteredReports((prev) => [...prev.filter(x => x.title !== ReportType.ItemUsage)]);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfiguration(ConfigurationName.COG, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
      if (res.data.value !== 'true') {
        setFilteredReports((prev) => [...prev.filter(x => x.title !== ReportType.Cogs)]);
      }
    });
  };

  return (
    <PageArea>
      <Box
        sx={{ 
          textAlign: 'left',
          color: 'black',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}>
        <PageTitle>{' ' + t('reporting.title')}</PageTitle>
      </Box>
      <Box sx={{
        py: '12px',
        color: 'black',
        fontWeight: 'bold',
      }}>
        <Tabs aria-label="Report tabs"
          value={tab}
          onChange={handleTabChange}>
          <Tab label={t('reporting.generate-report')} />
          <Tab label={t('reporting.reports')} />
          {
            isAdmin() && <Tab label={t('reporting.user-reports')} />
          }
        </Tabs>
      </Box>
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', p: '12px', border: `solid 1px ${theme.palette.common.white}`,
        borderRadius: '4px',
      }}>
        <CustomTabPanel value={tab}
          index={ReportTabType.GenerateReport}>
          {
            filteredReports.map((item) => {
              return (
                <Box key={item.title}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    minHeight: '60px',
                    p: '12px',
                    alignItems: 'center',
                    justifyContent: 'left',
                    mb: '12px',
                    border: `solid 1px ${theme.palette.custom.gray[200]}`,
                    borderRadius: '4px',
                  }}>
                  <SvgIcon sx={{ width: '20px', mr: '24px' }}>{item.icon}</SvgIcon>
                  <Typography variant="textMD"
                    sx={{ display: 'flex', flex: 1, justifyContent: 'left' }}>{t(item.title)}</Typography>
                  <Button size='lg'
                    onClick={() => handleGenerateClick(item.title)}>{t('reporting.generate')}</Button>
                </Box>
              );
            })
          }
        </CustomTabPanel>
        <CustomTabPanel value={tab}
          index={ReportTabType.ReportHistory}>
          <ReportHistoryTab />
        </CustomTabPanel>
        <CustomTabPanel value={tab}
          index={ReportTabType.UserReports}>
          <UserReportsTab />
        </CustomTabPanel>
      </Box>
      <ReportDialog
        open={open}
        onClose={handleClose}
        reportItem={selectedReport}
      />
    </PageArea>
  );
};

export default Reporting;