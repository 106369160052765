import * as React from 'react';
import { Box } from '@mui/material';
import IdleTimer from '../IdleTimer';
import Drawer from '../../ui/drawer/Drawer';

type PropsWithChildren<P> = P & { children?: React.ReactNode };

type IProps = PropsWithChildren<{}>;

const Layout = (props: IProps) => {
  return (
    <Box display='flex'
      flexDirection='row'
      sx={{
        marginTop: {
          xs: '56px',
          md: 0,
        },
        maxWidth: '100%',
        minWidth: '100%',
        width: '100%',
      }}
    >
      <Drawer />
      <Box sx={{
        maxWidth: { xs: '100%' }, width: { xs: '100%' },
      }}>
        <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>{props.children}</Box>
      </Box>
      <IdleTimer />
    </Box>
  );
};

export default Layout;
