import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Products from '../OrderItems/components/OrderableItems/Products';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

const OrderableItems = () => {
  const { t } = useTranslation('products');

  return (
    <PageArea>
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        alignItems='start'
      >
        <PageTitle>{t('orderTitle')}</PageTitle>
      </Box>     
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
      >
        <Products />
      </Box>
    </PageArea>
  );
};

export default OrderableItems;