/* eslint-disable indent */

interface EnvironmentConfig {
    apiBaseUrl: string;
    ewsCompanyId: string;
    ewsApiKey: string;
    auth0BaseUrl: string;
    auth0ClientId: string;
    auth0Audience: string;
    auth0dConnectionName: string;
    auth0AuthoriseBaseUrl: string;
    auth0LogoutBaseUrl: string;
}

export const isNonStarbucksEnvironment = (): boolean => {
    switch (window.location.hostname) {
        case 'localhost':
        case 'orderly-sscm-uat-apac-app-djb3avcdbuf2a8ck.southeastasia-01.azurewebsites.net':
        case 'orderly-sscm-uat-emea-app.azurewebsites.net':
        case 'sscm-apac-uat.orderly.io':
        case 'app-uat.orderly.io':
        case 'app.orderly.io':
        case 'app-apac.orderly.io':
            return true;
        default:
            return false;
    }
};

export const isPodEnabledEnvironment = (): boolean => {
    switch (window.location.hostname) {
        case 'localhost':
        case 'sscm-test-emea-web.azurewebsites.net':
        case 'sscm-test-emea-web-v2.azurewebsites.net':
        case 'sscm-test-emea-web-v3.azurewebsites.net':
        case 'sscm-test-emea-web-v4.azurewebsites.net':
        case 'sscm-test-emea-web-v5.azurewebsites.net':
        case 'sds-uat.orderly.io':
        case 'sds.orderly.io':
            return true;
        default:
            return false;
    }
};

export const isReturnToVendorButtonVisibleForFranchise = (franchiseName : string): boolean => {
    switch (franchiseName) {
        case 'NE Apple Green GB':
        case 'Pasta Evangelists UK':
        case 'Insomnia Cookies UK':
        case 'BK':
            return false;
        default:
            return true;
    }
};

const commonConfig = {
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
    apiBaseUrl: '/api',
};

const sharedTestConfig: EnvironmentConfig = {
    ...commonConfig,
    ewsCompanyId: '12878',
    ewsApiKey: '728fb4f8-27af-425f-b753-222e76ec8f70',
    auth0BaseUrl: 'https://easywebstore-test.eu.auth0.com/oauth',
    auth0ClientId: '6xZ78H92Z6Kc5i948NtFBat1W1dOkg52',
    auth0dConnectionName: 'Azure-AD-Test',
    auth0AuthoriseBaseUrl: 'https://easywebstore-test.eu.auth0.com/authorize',
    auth0LogoutBaseUrl: 'https://easywebstore-test.eu.auth0.com/v2/logout',
};

const uatLacEnvironment: EnvironmentConfig = {
    ...sharedTestConfig,
    ewsCompanyId: '13479',
    ewsApiKey: 'e2850a69-35d2-4b01-91bf-461749d7c02a',
    auth0BaseUrl: 'https://orderly-inventory-us.us.auth0.com/oauth',
    auth0ClientId: 'Ytd5WFuna0qJortL1kFU96qzrzfN4GH6',
    auth0AuthoriseBaseUrl: 'https://orderly-inventory-us.us.auth0.com/authorize',
    auth0LogoutBaseUrl: 'https://orderly-inventory-us.us.auth0.com/v2/logout',
};

const prodLacEnvironment: EnvironmentConfig = {
  ...sharedTestConfig,
  ewsCompanyId: '13479',
  ewsApiKey: 'e2850a69-35d2-4b01-91bf-461749d7c02a',
  auth0BaseUrl: 'https://orderly-inventory-us.us.auth0.com/oauth',
  auth0ClientId: 'Ytd5WFuna0qJortL1kFU96qzrzfN4GH6',
  auth0AuthoriseBaseUrl: 'https://orderly-inventory-us.us.auth0.com/authorize',
  auth0LogoutBaseUrl: 'https://orderly-inventory-us.us.auth0.com/v2/logout',
};


const prodEnvironment: EnvironmentConfig = {
    ...commonConfig,
    ewsCompanyId: '13190',
    ewsApiKey: '86da15a9-19be-4380-bc3e-ef718ad20230',
    auth0BaseUrl: 'https://easywebstore-prod.eu.auth0.com/oauth',
    auth0ClientId: '8wPKByOptTCVoSTGCsMr6XfrdDl1uhbt',
    auth0dConnectionName: 'Azure-AD-Prod',
    auth0AuthoriseBaseUrl: 'https://easywebstore-prod.eu.auth0.com/authorize',
    auth0LogoutBaseUrl: 'https://easywebstore-prod.eu.auth0.com/v2/logout',
};

const orderlySaaSEmeaProdEnvironment: EnvironmentConfig = {
    auth0Audience: 'https://app.orderly.io',
    apiBaseUrl: '/api',
    ewsCompanyId: '13493',
    ewsApiKey: '202b2dfd-ef44-4dc5-b80f-dc65b0d8c7a6',
    auth0BaseUrl: 'https://orderlyio.eu.auth0.com/oauth',
    auth0ClientId: '6UhfgHX0zSwxZ0MeruZVAvOT1rZ5HOQJ',
    auth0dConnectionName: 'Azure-AD-Prod',
    auth0AuthoriseBaseUrl: 'https://orderlyio.eu.auth0.com/authorize',
    auth0LogoutBaseUrl: 'https://orderlyio.eu.auth0.com/v2/logout',
};

const uatApacEnvironment: EnvironmentConfig = {
    apiBaseUrl: '/api',
    ewsCompanyId: '13492',
    ewsApiKey: '1535c7d9-413e-443b-97f8-570a0ea48746',
    auth0BaseUrl: 'https://orderly-inventory.au.auth0.com/oauth',
    auth0ClientId: 'dKipib370EIUUUTZyXPHRZW96TCjHQfZ',
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
    auth0dConnectionName: 'Azure-AD-Prod',
    auth0AuthoriseBaseUrl: 'https://orderly-inventory.au.auth0.com/authorize',
    auth0LogoutBaseUrl: 'https://orderly-inventory.au.auth0.com/v2/logout',
};

const prodApacEnvironment: EnvironmentConfig = {
    apiBaseUrl: '/api',
    ewsCompanyId: '13492',
    ewsApiKey: '91c37dcd-b2c3-4c2f-9bc7-d9fcf3e2d9be',
    auth0BaseUrl: 'https://orderly-inventory.au.auth0.com/oauth',
    auth0ClientId: 'dKipib370EIUUUTZyXPHRZW96TCjHQfZ',
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
    auth0dConnectionName: 'Azure-AD-Prod',
    auth0AuthoriseBaseUrl: 'https://orderly-inventory.au.auth0.com/authorize',
    auth0LogoutBaseUrl: 'https://orderly-inventory.au.auth0.com/v2/logout',
};

const environmentMapping: { [hostname: string]: EnvironmentConfig } = {
    'localhost': sharedTestConfig,
    'sscm-test-emea-web.azurewebsites.net': sharedTestConfig,
    'sscm-test-emea-web-v2.azurewebsites.net': sharedTestConfig,
    'sscm-test-emea-web-v3.azurewebsites.net': sharedTestConfig,
    'sscm-test-emea-web-v4.azurewebsites.net': sharedTestConfig,
    'sscm-test-emea-web-v5.azurewebsites.net': sharedTestConfig,
    'orderly-sscm-uat-emea-app.azurewebsites.net': sharedTestConfig,
    'app-uat.orderly.io': sharedTestConfig,
    'sds-uat.orderly.io': sharedTestConfig,
    'sds.orderly.io': prodEnvironment,
    'app.orderly.io': orderlySaaSEmeaProdEnvironment,
    'sbux-sscm-prod-emea-app.azurewebsites.net': prodEnvironment,
    'orderly-sscm-uat-lac-app-ctekfjb7g2d5dcf7.westus2-01.azurewebsites.net': uatLacEnvironment,
    'orderly-sscm-uat-lac.orderly.io': uatLacEnvironment,
    'sbux-sscm-uat-lac-app-gpdbbyfkctc6g9ez.westus2-01.azurewebsites.net': uatLacEnvironment,
    'sds-starbucks-uat-lac.orderly.io': uatLacEnvironment,
    'orderly-sscm-uat-apac-app-djb3avcdbuf2a8ck.southeastasia-01.azurewebsites.net': uatApacEnvironment,
    'sscm-apac-uat.orderly.io': uatApacEnvironment,
    'app-apac.orderly.io': prodApacEnvironment,
    'sds-starbucks-lac.orderly.io': prodLacEnvironment,
    'sbux-sscm-prod-lac-app-cpbdanebedc9c5gy.westus2-01.azurewebsites.net': prodLacEnvironment,
};

export const getApiConfig = (): EnvironmentConfig => {
    const hostname = window.location.hostname;
    return environmentMapping[hostname] ?? sharedTestConfig;
};
