import axios from 'axios';
import dayjs from 'dayjs';

export const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
};

export const standardizeDateFormat = (date: string) => {
  const formattedDate = date.split('/').map(part => part.padStart(2, '0')).join('/');
  const parsedDate = dayjs(formattedDate, 'MM/DD/YYYY');
  return parsedDate.format('DD-MM-YYYY');
};

export const getFormattedDate = (date: Date) => {
  const month = date.getMonth() + 1;
  const strMonth = month.toString().padStart(2, '0');
  const day = date.getDate();
  const strDay = day.toString().padStart(2, '0');
  const year = date.getFullYear();
  return strDay + '/' + strMonth + '/' + year;
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date: Date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
};

export const truncateText = (text: string | undefined, maxLength: number) => {
  if (!text) {
    return '';
  }
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export const formatDateForReport = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
};

export const getAdjustedOffsetDate = (date: Date) => {
  const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); // Adjust for timezone offset
  const year = adjustedDate.getFullYear();
  const month = String(adjustedDate.getMonth() + 1).padStart(2, '0');
  const day = String(adjustedDate.getDate()).padStart(2, '0');
  const hours = String(adjustedDate.getHours()).padStart(2, '0');
  const minutes = String(adjustedDate.getMinutes()).padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const formatDateHourString = (dateString: string) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export const compareStringsAsLower = (a: string, b: string): number => {
  return a.toLowerCase().localeCompare(b.toLowerCase());
};

export const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const compare = <T>(a: T, b: T, sortField: keyof T, sortDir: 'asc' | 'desc'): number => {
  const aValue = a[sortField];
  const bValue = b[sortField];

  if (sortDir === 'asc') {
    if (aValue > bValue) {
      return 1;
    } else if (aValue < bValue) {
      return -1;
    } else {
      return 0;
    }
  } else if (sortDir === 'desc') {
    if (aValue > bValue) {
      return -1;
    } else if (aValue < bValue) {
      return 1;
    } else {
      return 0;
    }
  }

  return 0;
};

// Function to format the date in DD/MM/YYYY
export const formatDateString = (inputDateString: string): string => {
  if (inputDateString) {
    const inputDate = new Date(inputDateString);

    // Check if the date is valid
    if (!isNaN(inputDate.getTime())) {
      const day = inputDate.getDate().toString().padStart(2, '0');
      const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const year = inputDate.getFullYear();

      return `${day}/${month}/${year}`;
    } else {
      return 'Invalid Date';
    }
  }
  else
    return '';
};

export const applyRoundAndFormat = (value: number, currencyCode?: string, maxFractionDigits = 2) => {
  const fallbackCurrencyCode = 'GBP';
  const roundedValue = Math.round(value * 1e8) / 1e8; // Round to 8 decimal places
  // Custom format for MXN to avoid the "MX$" prefix
  if (currencyCode === 'MXN') {
    return `$${new Intl.NumberFormat(undefined, {
      style: 'decimal', // Use 'decimal' style to avoid any currency-specific symbols
      minimumFractionDigits: 2,
      maximumFractionDigits: maxFractionDigits,
    }).format(roundedValue)}`;
  }
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode ?? fallbackCurrencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: maxFractionDigits,
  }).format(roundedValue);
};

export const applyRoundAndFormatDecimal = (value: number, maxFractionDigits = 2) => {
  const roundedValue = Math.round(value * 1e8) / 1e8;
  return new Intl.NumberFormat(undefined, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: maxFractionDigits,
  }).format(roundedValue);
};

export const exportFile = (data: Blob, type: string, title: string) => {
  const blob = new Blob([data], { type: type });

  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = title;

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
};

export const convertFileToImage = (file: File): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      resolve(img);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const getFileFromBase64 = (b64Data: string, fileName: string) => {
  const contentType = 'image';
  const sliceSize = 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  const file = new File([blob], fileName, { type: 'image/png' });
  return file;
};

export const base64ToBlob = (base64: string, type = 'image/jpg') => {
  const base64Data = base64.replace(/^data:.+;base64,/, '');
  const binaryString = window.atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type });
};

export const adjustDateByDays = (date: Date, days: number, endDate?: boolean): Date => {
  const adjustedDate = new Date(date);
  adjustedDate.setDate(adjustedDate.getDate() + days);
  if (endDate) {
    adjustedDate.setUTCHours(23, 59, 59, 0);
  } else {
    adjustedDate.setUTCHours(0, 0, 0, 0);
  }

  return adjustedDate;
};

export const getCurrencySymbol = (currencyCode: string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });

  let currencySymbol = '';
  formatter.formatToParts().forEach(part => {
    if (part.type === 'currency') {
      currencySymbol = part.value;
    }
  });

  return currencySymbol;
};

export const roundToSignificantDigit = (value: number) => {
  const fixedValue = 2;
  let precision = 0;

  let val = value - Math.round(value);

  if (val === 0) {
    return parseFloat(value.toFixed(fixedValue));
  }

  while (Math.abs(val) < 1) {
    val *= 10;
    precision++;
  }

  if (precision === 1) {
    return parseFloat(value.toFixed(fixedValue));
  }

  return parseFloat(value.toFixed(precision));
};

export const extractErrorMessage = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    return error.message;
  } else {
    return error as string;
  }
};

export const formatNumber = (value: number | null | undefined, minDecimals = 0, maxDecimals = 3, useGrouping = true): string => {
  if (value == null || isNaN(value)) {
    return ''; // Invalid input
  }

  const minDecimalsAdjusted = Number.isInteger(value) ? 0 : minDecimals;

  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: minDecimalsAdjusted,
    maximumFractionDigits: maxDecimals,
    useGrouping,
  }).format(value);
};