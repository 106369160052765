/* eslint-disable react/jsx-no-undef */
import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { theme } from '../../../theme';
import { Trash01 , Plus, Copy01 } from '../../../assets';
import { AutomatedTaskSchedule, ScheduleRecurrenceFrequency } from '../../../app/services/api/generated';
import { ScheduleCreateContext, ScheduleCreateValues } from '../context/ScheduledReportsContext';
import dayjs from 'dayjs';

export interface Schedules  {
schedules : AutomatedTaskSchedule[] | undefined;
showSchedules:boolean;
}

interface ActionTypes {
  index : number,
  description: string,
}

const scheduleType: ActionTypes[] = [
  { index: 0, description: 'Frequency and Interval' },
  { index: 1, description: 'Monthly Date' },
  { index: 2, description: 'Last day of month' },
];

const frequencyType: ActionTypes[] = [
  { index: 0, description: 'Select Frequency' },
  { index: 1, description: 'Day' },
  { index: 2, description: 'Week' },
];

const CreateScheduledReports =()=> {
  const { 
    t,
    schedules,
    showSchedules,
    interval,
    setSchedules,
    addNewSchedule,
    deleteScheduleRow,
    getAllPreviewDates,
    endDateErrors,
  } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const alldays = Array.from({ length: 28 }, (x, i) => i + 1);

  const copySchedule = (index: number) => {
    const newSchedule = structuredClone(schedules!.find((a, i) => i == index));
    if (newSchedule){
      newSchedule.id = 0;
      setSchedules(schedules!.concat([newSchedule]));
    }
  };
  const handleIntervalChange = (id: number, value: number, index: number) => {
    if (value > 0){
      const newSchedules = schedules?.map((a, i) => {
        const returnValue = { ...a };
        if (a.id == id && id != 0) {
          returnValue.recurrenceInterval = value;
        }
        if (a.id == 0 && i == index) {
          returnValue.recurrenceInterval = value;
        }
        return returnValue;
      });
      setSchedules(newSchedules);
    }
  };

  const handleTypeSelect = (id: number, index: number) => (event: SelectChangeEvent<number>) => {
    const newSchedules = schedules?.map((a, i) => {
      const returnValue = { ...a };
      if (a.id == id && id != 0) {
        returnValue.scheduleType = Number(event.target.value);
      }
      if (a.id == 0 && i == index) {
        returnValue.scheduleType = Number(event.target.value);
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };
  const handleDayChange = (id: number, value: number, index:number) => {
    const newSchedules = schedules?.map((a, i) => {
      const returnValue = { ...a };
      if (a.id == id && id != 0) {
        returnValue.recurrenceFrequency = value as ScheduleRecurrenceFrequency;
      }
      if (a.id == 0 && i == index) {
        returnValue.recurrenceFrequency = value as ScheduleRecurrenceFrequency;
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };

  const handleActiveChange = (id: number, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSchedules = schedules?.map((a, i) => {
      const returnValue = { ...a };
      if (a.id == id && id != 0) {
        returnValue.isActive = event.target.checked;
      }
      if (a.id == 0 && i == index) {
        returnValue.isActive = event.target.checked;
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };
  
  const handleDaysChange = (id: number, value: number, index: number) => {
    const newSchedules = schedules?.map((a, i) => {
      const returnValue = { ...a };
      if (a.id == id && id != 0) {
        returnValue.scheduledDayOfMonth = value ;
      }
      if (a.id == 0 && i == index) {
        returnValue.scheduledDayOfMonth = value;
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };

  const handleStartChange = (id: number, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSchedules = schedules?.map((a, i) => {
      const returnValue = { ...a };
      if (i === index) {
        returnValue.startDate = event.target.value;
        if(returnValue.endDate != '' && returnValue.endDate != undefined){
          const tempStartDate = new Date(returnValue.startDate);
          const tempEndDate = new Date(returnValue.endDate);
          if(tempEndDate < tempStartDate){
            returnValue.endDate = '';
          }
        }
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };
  const handleEndChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSchedules = schedules?.map((a, i) => {
      const returnValue = { ...a };
      if (i == index) {
        returnValue.endDate = event.target.value;
      }
      return returnValue;
    });
    setSchedules(newSchedules);
  };   

  return (
    
    <Box sx={{ border:'2px solid', borderRadius:'4px', borderColor: theme.palette.primary[500] }}>
      <Grid container
        sx={{ p:'10px' }}
      >
        <Grid item 
          xs ={6}
        >
          <Typography variant="textXL"
            sx={{ textAlign:'left',fontWeight:'bold' }}>{t('scheduleTitle')}</Typography>
        </Grid>
        <Grid item 
          xs ={6} 
          sx ={{ display:'flex',justifyContent:'flex-end',alignItems:'center' }}>
          { schedules?.length! > 0  && (
            <Button
              size='lg'
              sx={{ mr: 3 }}
              variant='bad'
              onClick={() => deleteScheduleRow()}
            ><Trash01/>{t('remove')}
            </Button>)}
          { schedules?.length! > 0  && (
            <Button
              size='lg'
              sx={{ mr: 3 }}
              variant='primary'
              onClick={getAllPreviewDates}
            ><Plus/>{t('previewDate')}
            </Button>
          )}
          <Button
            size='lg'
            sx={{ mr: 3 }}
            variant='primary'
            onClick={addNewSchedule} 
          ><Plus/>{t('addNew')}
          </Button>
        </Grid>
      </Grid>
      
      {schedules?.map((row, index) => (
        showSchedules && (
          <Grid container
            spacing={3}
            key={row.id}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
            sx={{ p: '10px', pb: '10px' }}
          >
            <Grid item
              xs={12}
              md={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{t('active')}</Typography>
                <Switch
                  defaultChecked={row.isActive}
                  onChange={handleActiveChange(row.id ?? -1, index)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
            </Grid>

            <Grid item
              xs={12}
              md={2}
              sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl fullWidth
                size='small'
                sx={{ minHeight: '84px' }}
              >
                <TextField
                  id="datetime-input"
                  label={t('startDay')}
                  type="datetime-local"
                  size="small"
                  variant="outlined"
                  value={dayjs(row.startDate).format('YYYY-MM-DDTHH:mm')}
                  onChange={handleStartChange(row.id ?? -1, index)}
                  InputLabelProps={{ 
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item
              xs={12}
              md={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <FormControl fullWidth
                size="small"
                sx={{ minHeight: '84px' }} // Adjust this value if needed to fit your layout
              >
                <TextField
                  id="datetime-input"
                  label={t('endDate')}
                  type="datetime-local"
                  size="small"
                  variant="outlined"
                  value={dayjs(row.endDate).format('YYYY-MM-DDTHH:mm')}
                  onChange={handleEndChange(index)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: dayjs(row.endDate).format('YYYY-MM-DDTHH:mm'),
                  }}
                  helperText={endDateErrors[index ?? -1] ?? ''}
                  error={!!endDateErrors[index ?? -1]}
                />
              </FormControl>
            </Grid>

            <Grid item
              xs={12}
              md={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <FormControl fullWidth 
                size="small">
                <InputLabel id="type-label">{t('scheduleType')}</InputLabel>
                <Select
                  label={t('scheduleType')}
                  size="small"
                  variant="outlined"
                  value={row.scheduleType}
                  onChange={handleTypeSelect(row.id ?? -1, index)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  {scheduleType.map((option) => (
                    <MenuItem key={option.index}
                      value={option.index}>
                      {option.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {row.scheduleType === 0 && (
              <Grid item
                xs={12}
                md={2}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <FormControl fullWidth
                  size="small">
                  <InputLabel id="type-label">{t('frequency')}</InputLabel>
                  <Select
                    label={t('frequency')}
                    size="small"
                    variant="outlined"
                    value={row.recurrenceFrequency }
                    onChange={(e) => handleDayChange(row.id ?? -1, Number(e.target.value), index)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {frequencyType.map((option) => (
                      <MenuItem key={option.index}
                        value={option.index}
                        disabled={option.index === 0}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {row.scheduleType === 1 && (
              <Grid item
                xs={12}
                md={2}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <FormControl fullWidth
                  size="small">
                  <InputLabel id="type-label">{t('dayofMonth')}</InputLabel>
                  <Select
                    label={t('dayofMonth')}
                    size="small"
                    variant="outlined"
                    value={row.scheduledDayOfMonth}
                    onChange={(e) => handleDaysChange(row.id ?? -1, Number(e.target.value), index)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {alldays.map((option) => (
                      <MenuItem key={option}
                        value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {row.scheduleType === 0 && (
              <Grid item
                xs={12}
                md={2}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <TextField value={interval ?? row.recurrenceInterval}
                  fullWidth
                  type='number'
                  onChange={(e) => handleIntervalChange(row.id ?? -1, Number(e.target.value), index)}
                  label={t('interval')}/>
              </Grid>
            )}
            <Grid item
              xs={isMobile ? 6 : 12}
              md={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'flex-start' : 'flex-end' }}>
              <Copy01 color={theme.palette.primary[700]}
                onClick={() => copySchedule(index)} 
              />
            </Grid>

            <Grid item
              xs={isMobile ? 6 : 12}
              md={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Trash01 color={theme.palette.error[500]}
                onClick={() => deleteScheduleRow(index)} />
            </Grid>
          </Grid>)
      ))}
      {!showSchedules && (
        <Alert severity='info'
          variant='outlined'
          sx={{ mb: '12px', mx: '8px' }}>
          {t('noSchedules')}
        </Alert>
      )}

    </Box>
    
  );
};

export default CreateScheduledReports;