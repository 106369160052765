import { useContext, useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  SvgIcon,
  Alert,
  Snackbar,
  Typography,
  Box,
} from '@mui/material';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { ItemRequest, WasteCheckSheetRequest } from '../../../app/services/api/generated';
import { Minus, Printer, Save01 } from '../../../assets';
import SaveModal from './SaveModal';
import { ExtendedGetItemsResponse } from '../interfaces/ExtendedGetItemsResponse';
import { ItemWasteChecksheetData } from '../interfaces/ItemWasteChecksheetData';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useNavigate } from 'react-router-dom';
import { ItemPreview } from '../interfaces/ItemsPreview';
import SubmitWasteChecksheetModal from './SubmitWasteChecksheetModal';
import useLogError from '../../../hooks/useLogError';
import { Category } from '@mui/icons-material';
import { formatDateHourString } from '../../../utils';
import { UserContext } from '../../../components/shared/useUser';
import { useBlocker } from 'react-router-dom';
import LeavePageModal from '../../../components/shared/dialogs/LeavePageModal';

interface Category {
  name: string;
  items: ItemWasteChecksheetData[];
  submitButtonClicked: boolean;
}

type CreateEditWasteChecksheetProps = { 
  itemsData: ExtendedGetItemsResponse, 
  isEditMode: boolean, 
  id:number , 
  storeNumber: string | undefined, 
  submitButtonClicked: boolean, 
  isDirty: boolean,
  setIsDirty: (value: boolean) => void,
  isArchived: boolean, 
  submittedBy: string, 
  submittedDate: string | undefined, 
  exitAndSave: boolean, 
  checkInputValues: (hasInputValues: boolean) => void;
}

const CreateEditWasteChecksheet = (props: CreateEditWasteChecksheetProps) => {
  const { t } = useTranslation('wasteChecksheet');
  const [isLoading, setIsLoading] = useState(false);
  const [showSavedAlert, setShowSavedAlert] = useState(false); 
  const [showErrorSavedAlert, setShowErrorSavedAlert] = useState(false); 
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [showSubmitAlert, setShowSubmitAlert] = useState(false); 
  const [showErrorSubmitAlert, setShowErrorSubmitAlert] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(''); 
  const [openSubmitModal, setOpenSubmitModal] = useState(false); 
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemsByCategory, setItemsByCategory] = useState<Category[]>([]); 
  const [itemsPreviewOnSubmitModal, setItemsPreviewOnSubmitModal] = useState<ItemPreview[]>([]);
  const [itemsRequest, setItemsRequest] =  useState<ItemRequest[]>([]); 
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const { hasPermissionTo, isAdmin } = useContext(UserContext);
  const hasPermissionToWrite = hasPermissionTo(['DailyWasteCheckSheetWrite']) || isAdmin() ;
  const [modifiedItems, setModifiedItems] = useState<Set<number>>(new Set());
  const [lastFocusedItemId, setLastFocusedItemId] = useState<number | null>(null);
  const [showAutosaveMessage, setShowAutosaveMessage] = useState<boolean>(false);
  const [inactivityTimer, setInactivityTimer] = useState<NodeJS.Timeout | null>(null);
  const [navigateToIndex, setNavigateToIndex] = useState<boolean>(false);

  const { logError } = useLogError();

  const navigate = useNavigate();

  const INACTIVITY_TIMEOUT = 60000; // 1 min on milliseconds

  const { createWaste, submitWaste } = useNswagClient();
  const blocker = useBlocker(() => modifiedItems.size > 0 &&  !props.exitAndSave);

  useEffect(() => {
    const beforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
      if (modifiedItems.size > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [modifiedItems]);

  useEffect(() => {
    if (navigateToIndex) {
      navigate('/store/daily-waste-checksheet');
      if (blocker?.state === 'blocked') {
        blocker.proceed();
      }
      setNavigateToIndex(false);
    }
  }, [navigateToIndex, blocker]);
  
  const handleLeavePageModalCancel = () => {
    if (blocker?.proceed) {
      blocker.proceed(); 
    } };

  const handleLeavePageModalClose = () => {
    if (blocker?.reset) {
      blocker.reset(); 
    } };

  const handleLeavePageModalConfirm = () => {
    if(isSaveSubmitEnabled()){
      handleSaveConfirmModal();
    }
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };

  useEffect(() => {
    setIsLoading(true);
 
    const categorizedItems = groupCategoryItems(props.itemsData);
    setItemsByCategory(categorizedItems);  
    setIsLoading(false);
  }, [props.itemsData]);

  useEffect(() => {
    if (!firstTimeLoad) {
      handleSubmitButton();
    } 
    else{
      setFirstTimeLoad(false);
    }
  }, [props.submitButtonClicked]);

  useEffect(() => {
    if(props.exitAndSave)
    {  
      if(isSaveSubmitEnabled()){
        handleSaveConfirmModal();
      }
      else{
        handleAfterSave();
      }
    }

  }, [props.exitAndSave]);

  useEffect(() => {
    if (modifiedItems.size > 0 && props.isEditMode) {
      handleAutosaveMessage(false);
      startInactivityTimer();
    }
  }, [modifiedItems]);

  const startInactivityTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }
    const timer = setTimeout(() => {
      if (modifiedItems.size > 0) {
        modifiedItems.forEach(itemId => {
          autosaveItem(itemId);
        });
        props.setIsDirty(false);
      }
    }, INACTIVITY_TIMEOUT); 
    setInactivityTimer(timer);
  };

  const handleAutosaveMessage = (changesSaved : boolean) => {
    setShowAutosaveMessage(changesSaved);
  };
  
  const handleItemFocus = (itemId: number) => {
    if (lastFocusedItemId !== null && lastFocusedItemId !== itemId && modifiedItems.has(lastFocusedItemId)) {
      autosaveItem(lastFocusedItemId);
      props.setIsDirty(false);
    }
    setLastFocusedItemId(itemId);
  };

  const autosaveItem = (itemId: number) => {
    if (itemId) {
    
      const filterItems = flattenAndFilterItems();

      const requestData: WasteCheckSheetRequest = {
        id: props.id,
        storeNumber: props?.storeNumber ?? '',
        items: filterItems,
      };
  
      createWaste(requestData)
        .then(() => {
          handleAutosaveMessage(true);
          setModifiedItems(prev => {
            const newModifiedItems = new Set(prev);
            newModifiedItems.delete(itemId);
            return newModifiedItems;
          });
        })
        .catch(error => {
          setErrorMessage(error);
          setIsLoading(false);
          setShowErrorSavedAlert(true);
          logError(error);
        });
    }
    else {
      handleAutosaveMessage(false);
    }
  };
    
  const groupCategoryItems = (itemsData: ExtendedGetItemsResponse): Category[] => {
    if (!itemsData?.items?.length) {
      return [];
    }

    const categories: { [key: string]: Category } = {};

    for (const item of itemsData.items) {
      const category = item.primaryCategory?.toUpperCase();
      if (category) {
        categories[category] = categories[category] || { name: category, items: [] };
        categories[category].items.push(item);
      }
    }

    const sortedCategories = Object.values(categories).sort((a, b) => a.name.localeCompare(b.name));
    return sortedCategories;
  };
  const calculateTotal = (itemWaste: ItemWasteChecksheetData) => {
    const pastDueQty = itemWaste.pastDueQty! > 0 ? itemWaste.pastDueQty : 0;
    const sampledQty = itemWaste.sampledQty! > 0 ? itemWaste.sampledQty : 0;
    const damagedQty = itemWaste.damagedQty! > 0 ? itemWaste.damagedQty : 0;
    const storeUseQty = itemWaste.storeUseQty! > 0 ? itemWaste.storeUseQty : 0;
    const total = pastDueQty! + sampledQty! + damagedQty! + storeUseQty!;
    return (Math.round(Number(total)* 1000) / 1000);
  };

  const resetItemQuantities = (category: Category) => {
    return category.items.map(item => ({
      ...item,
      pastDueQty: undefined,
      sampledQty: undefined,
      damagedQty: undefined,
      storeUseQty: undefined,
    }));
  };
  
  const resetCategoryItems = (prevItemsByCategory: Category[]) => {
    return prevItemsByCategory.map(category => ({
      ...category,
      items: resetItemQuantities(category),
    }));
  };
  
  const handleReset =() =>{
    setItemsByCategory(prevItemsByCategory => resetCategoryItems(prevItemsByCategory)); 
  };

  const flattenAndFilterItems = () : ItemRequest[] => {
    const flattenItems = itemsByCategory.flatMap(category => category.items);

    const filteredItems: ItemRequest[] = flattenItems.filter(
      item =>
        (item.pastDueQty ?? 0) > 0 ||
        (item.sampledQty ?? 0) > 0 ||
        (item.damagedQty ?? 0) > 0 ||
        (item.storeUseQty ?? 0) > 0,
    ).map((item) => ({
      id: item.id ?? 0,
      itemNumber: item.itemNumber ?? '',
      description: item.description ?? '',
      uom: item.uom ?? '',
      pastDueQty: item.pastDueQty ?? 0,
      sampledQty: item.sampledQty ?? 0,
      damagedQty: item.damagedQty ?? 0,
      storeUseQty: item.storeUseQty ?? 0,
    }));

    setItemsRequest(filteredItems);
    return filteredItems;
  };

  const createPreviewItems = (itemsRequest: ItemRequest[]): ItemPreview[] => {
    const previewItems: ItemPreview[] = [];
  
    for (const item of itemsRequest) {
      for (const type of ['pastDueQty', 'sampledQty', 'damagedQty', 'storeUseQty'] as const) {
        const qty = item[type] ?? 0; 
        if (qty > 0) {
          previewItems.push({
            itemNumber: item.itemNumber,
            description: item.description ? item.description : '',
            qty: qty,
            type: type === 'pastDueQty' ? 'pastDate' : type.replace('Qty', ''),
          });
        }
      }
    }
  
    return previewItems;
  };

  const isSaveSubmitEnabled = () => {
    const submitValid = !isSaving;
    props.checkInputValues(submitValid);
    return submitValid;
  };
  
  const handleSubmitButton = () => {
    const filteredItems = flattenAndFilterItems();
    const previewItems = createPreviewItems(filteredItems);
    setItemsPreviewOnSubmitModal(previewItems);
    setOpenSubmitModal(true);
  };

  const handleConfirmSubmitModal = () => {
  
    setIsSubmitting(true);
    props.setIsDirty(false);

    const requestData: WasteCheckSheetRequest = {
      id: props.id,
      storeNumber: props?.storeNumber ?? '',
      items: itemsRequest,
    };
    submitWaste(requestData)
      .then(() => {
        setIsSubmitting(false);
        setOpenSubmitModal(false);
       
        
        handleReset();
        navigate('/store/daily-waste-checksheet/0');
        setShowSubmitAlert(true);
      })
      .catch((error) => {
        setErrorMessage(error);
        setShowErrorSubmitAlert(true);
        setIsSubmitting(false);
        logError(error);        
      });
  };
  const handleSubmitCancelModal = () => {
    setIsSubmitting(false);
    setOpenSubmitModal(false);
  };

  const handleSaveConfirmModal = () => {
    
    setIsSaving(true);
    props.setIsDirty(false);
    const filterItems = flattenAndFilterItems();

    const requestData: WasteCheckSheetRequest = {
      id: props.id,
      storeNumber: props?.storeNumber ?? '',
      items: filterItems,
    };

    createWaste(requestData)
      .then(() => {
        setModifiedItems(new Set());
        setIsSaving(false);
        setShowSavedAlert(true);

        if(!props.isEditMode || props.exitAndSave){
          setTimeout(() => {
            handleAfterSave(); }, 1000);     
        }
        setOpenSaveModal(false);
      })
      .catch(error => {
        setErrorMessage(error);
        setIsLoading(false);
        setIsSaving(false);
        setOpenSaveModal(false);
        setShowErrorSavedAlert(true);
        logError(error);
      });
  };

  const saveButtonHandler = () => {
    setOpenSaveModal(true);
  };

  const handleSaveCancelModal = () => {
    setIsSaving(false);
    setOpenSaveModal(false);
  };

  const handleQuantityChange = (categoryIndex: number, itemIndex: number, field: keyof ItemWasteChecksheetData, value: string) => {
    const updatedItemsByCategory = [...itemsByCategory]; 
    const parsedValue = value === '' ? null : (Math.round(Number(value)* 1000) / 1000);
    
    const itemToUpdate = updatedItemsByCategory[categoryIndex].items[itemIndex];
    const updatedItemId = itemToUpdate?.id;
    if (updatedItemId && !modifiedItems.has(updatedItemId)) {
      setModifiedItems(prev => new Set(prev.add(updatedItemId)));
      props.setIsDirty(true);
    }
    switch (field) {
    case 'pastDueQty':
      itemToUpdate.pastDueQty = parsedValue ?? undefined;
      break;
    case 'sampledQty':
      itemToUpdate.sampledQty = parsedValue ?? undefined;
      break;
    case 'damagedQty':
      itemToUpdate.damagedQty = parsedValue ?? undefined;
      break;
    case 'storeUseQty':
      itemToUpdate.storeUseQty = parsedValue ?? undefined;
      break;
    default:
      break;
    }  
    setItemsByCategory(updatedItemsByCategory); 
  };

  const handleAfterSave = () => {
    navigate('/store/waste-checksheets');
  };

  const printTableContainer = () => {
    const printableGridItems = document.querySelectorAll('.waste-checksheet-categories');
  
    if (printableGridItems.length > 0) {
      // Hide the fieldsets with class MuiOutlinedInput-notchedOutline
      printableGridItems.forEach((printableGridItem) => {
        const fieldsets = printableGridItem.querySelectorAll('.MuiOutlinedInput-notchedOutline');
        fieldsets.forEach((fieldset) => {
          (fieldset as HTMLElement).style.display = 'none';
        });
      });

      const WinPrint = window.open('', '', 'left=0,top=0,width=1650,height=900,toolbar=0,scrollbars=0,status=0');

      if (WinPrint) {
      
        // Open the new window and begin writing the content
        WinPrint.document.write('<html><head><title>Print</title>');
        WinPrint.document.write('<style>');
        WinPrint.document.write(`
        table { table-layout: fixed; }
         .waste-checksheet-categories {margin-bottom: 20px}
         .waste-checksheet-categories .title {font-size: 1.3em; color: #636363; margin-bottom: 5px;}
        th{text-align: left;}
        .description { width: 300px;}
        td {border-top: 1px solid #ddd; overflow: hidden; padding: 15px 0px; font-size: 10px; white-space: normal;}
        .uom {padding-left: 10px;}
        tr:first-child > td {border-top: 2px solid #ddd;}
        tr {border-top: 1px solid #ddd;}
        .normal-field {width:150px;}
        .normal-field input {width: 50px;height: 30px; border-radius: 5px !important;border: 2px solid darkgray; display: flex; align-items: center; text-align: center; }
        @media print {.stock-list {page-break-after: always;&:last-of-type {page-break-after: avoid;}}}
        `);
        WinPrint.document.write('</style></head><body>');
  
        // Loop through each Grid item and append its content to the print window
        printableGridItems.forEach((grid) => {
          WinPrint.document.write('<div class="waste-checksheet-categories">');
          WinPrint.document.write(grid.innerHTML);
          WinPrint.document.write('</div>');
        });
  
        WinPrint.document.write('</body></html>');
        WinPrint.document.close();
        WinPrint.focus();
  
        // Delay the print function by a short timeout to ensure content is fully loaded
        setTimeout(() => {
          // Initiate the print action
          WinPrint.print();
          WinPrint.close();
          // Restore visibility of input fields after printing
          printableGridItems.forEach((printableGridItem) => {
            const fieldsets = printableGridItem.querySelectorAll('.MuiOutlinedInput-notchedOutline');
            fieldsets.forEach((fieldset) => {
              (fieldset as HTMLElement).style.display = '';
            });
          });
        }, 500);
      }
      
    }
  };
  return (
    <Box sx= {{ border: '1px solid', borderColor: theme.palette.custom.gray[300] }}> 
      <Grid container>
        {props.itemsData.itemCount === 0 && <Typography variant="textMD">
          {t('details.noDataMessage')}</Typography> }
        {props?.itemsData?.items && ( <>
          {!props.isArchived &&  hasPermissionToWrite &&  (  <Grid container
            justifyContent="flex-end"
            sx={{ my: '12px', px: '8px' }}
            spacing={5}>
            <Grid item
              xs={12}
              md={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'right' }}>
              <Button
                variant="secondary"
                size="lg"
                startIcon={<SvgIcon><Printer /></SvgIcon>}
                onClick={printTableContainer}
            
              >
                {t('printChecksheetBtn') }                  
              </Button>
            </Grid>

            {hasPermissionToWrite &&  <Grid item
              xs={12}
              md={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'right' }}>
              <Button
                variant="primary"
                size="lg"
                startIcon={<SvgIcon><Save01 /></SvgIcon>
                }
                onClick={saveButtonHandler}
                disabled={!isSaveSubmitEnabled()} 
                fullWidth
              >
                {t('saveBtn') }   
              </Button>
            </Grid>}
            <Grid item
              xs={12}
              md={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'right' }}>
              <Button
                variant="bad"
                size="lg"
                startIcon={<SvgIcon><Minus /></SvgIcon>}
                fullWidth
                onClick={handleReset}
              >
                {t('resetBtn') }   
              </Button>
            </Grid>
          </Grid>) }
          <Grid item
            xs={12}
            
            sx={{ textAlign: 'right',  mr:'8px' }}>
            <Typography variant='textLG'
              sx={{
                visibility: !showSavedAlert && showAutosaveMessage ? 'visible' : 'hidden',
              }}
            >
              {t('changesSaved') } 
            </Typography>
          </Grid>
          {props.isArchived && (  <Grid container
            spacing={2}
          >
            <Grid item 
              xs={12}
              md={4}
              sx={{  mx:'15px', my:'15px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <Typography variant='textSM'
                textAlign={'left'}> {t('list.submittedDate')}</Typography>
              <TextField
                id="waste-date"
                type="string"
                disabled
                value={formatDateHourString(props.submittedDate!)} 
                InputLabelProps={{ shrink: true }}
                size='small'
                style={{ backgroundColor: theme.palette.custom.gray[100] }}
              />
            </Grid>
            <Grid item 
              xs={12}
              md={4}
              sx={{ mx:'15px', my:'15px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <Typography variant='textSM'
                textAlign={'left'}>{t('list.submittedBy')}</Typography>
              <TextField
                id="waste-submitted-by"
                type="text"
                disabled
                value={props.submittedBy}
                InputLabelProps={{ shrink: true }}
                size='small'
                style={{ backgroundColor: theme.palette.custom.gray[100] }}
              />
            </Grid>
          </Grid>) }
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={showSavedAlert}
            onClose={()=>{setShowSavedAlert(false);}}
            autoHideDuration={5000}
          ><Alert 
              onClose={()=>{setShowSavedAlert(false);}}

              variant="filled"
              severity="success"
              sx={{ width: '100%' }}>
              {t('changesSaved') } 
            </Alert></Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={showErrorSavedAlert}
            onClose={()=>{setShowErrorSavedAlert(false);}}
            autoHideDuration={5000}
          ><Alert 
              onClose={()=>{setShowErrorSavedAlert(false);}}

              variant="filled"
              severity="error"
              sx={{ width: '100%' }}>
              {t('save.error') }   
            </Alert></Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={showSubmitAlert}
            onClose={()=>{setShowSubmitAlert(false);}}
            autoHideDuration={5000}
          ><Alert 
              onClose={()=>{setShowSubmitAlert(false);}}

              variant="filled"
              severity="success"
              sx={{ width: '100%' }}>
              {t('submit.success') }   
            </Alert></Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={showErrorSubmitAlert}
            onClose={()=>{setShowErrorSubmitAlert(false);}}
            autoHideDuration={5000}
          ><Alert 
              onClose={()=>{setShowErrorSubmitAlert(false);}}

              variant="filled"
              severity="error"
              sx={{ width: '100%' }}>
              {t('submit.error') + ' ' + errorMessage}   
            </Alert></Snackbar>

          {isLoading && <LoadingWheel/>}
          {/* Stock items table */}
          { !isLoading && <><Grid item
            id='waste-checksheet-grid'
            xs={12}
            mt='12px'
            className='waste-checksheet-categories'
          >
            {itemsByCategory.map((category, categoryIndex) => (
              <TableContainer
                sx={{ maxWidth: '100%' }}
                key={category.name}
                id='waste-checksheet-items-table-container'>
                <Table>
                  <TableHead>
                    <TableRow sx={{
                      height: '45px', verticalAlign: 'middle',
                    }}>
                      <TableCell colSpan={10}
                        className='title'>
                        {category.name}
                      </TableCell>
                    </TableRow>
                    <TableRow className='normal-field'>
                      <TableCell style={{ width: '10%' }}
                        align="left">{t('details.itemNumber')}</TableCell>
                      <TableCell style={{ width: '20%', maxWidth: '20%', minWidth: '20%' }}
                        align="left"
                        className='description'>{t('details.itemDescription')}</TableCell>
                      <TableCell style={{ width: '15%' }}
                        align="left"
                        className='uom'>{t('details.lowestUom')}</TableCell>
                      <TableCell style={{ width: '10%' }}
                        align="left">{t('details.pastDueQty')}</TableCell>
                      <TableCell style={{ width: '10%' }}
                        align="left">{t('details.sampledQty')}</TableCell>
                      <TableCell style={{ width: '10%' }}
                        align="left">{t('details.damagedQty')}</TableCell>
                      <TableCell style={{ width: '10%' }}
                        align="left">{t('details.storeUseQty')}</TableCell>
                      <TableCell style={{ width: '15%' }}
                        align="left">{t('details.total')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {category.items.sort((a, b) => a.description?.toLocaleLowerCase().localeCompare(b.description?.toLocaleLowerCase() ?? '') ?? -1)
                      .map((item, itemIndex) => (
                        <TableRow className='normal-field'
                          hover
                          style={{ cursor: 'pointer' }}
                          onFocus={props.isEditMode ? () => handleItemFocus(item.id ?? 0) : undefined}
                          key={item.id}>
                          <TableCell>
                            {item.itemNumber}
                          </TableCell>
                          <TableCell className='description'>
                            {item.description}
                          </TableCell>
                          <TableCell className='uom'>
                            {item.uom}
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              size="small"
                              id="past-date"
                              name="past-date"
                              variant="outlined"
                              disabled = {props.isArchived || !hasPermissionToWrite} 
                              value={item.pastDueQty ?? ''}
                              sx={{ 
                                backgroundColor: props.isArchived ? theme.palette.custom.gray[100] : 'inherit',
                                minWidth: '80px', 
                                width: '100%',   
      
                              }}
                              onChange={(e) => handleQuantityChange(categoryIndex, itemIndex, 'pastDueQty', e.target.value)}                   
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              size="small"
                              id="sampled"
                              name="sampled"
                              variant="outlined"
                              disabled = {props.isArchived || !hasPermissionToWrite}
                              sx={{ 
                                backgroundColor: props.isArchived ? theme.palette.custom.gray[100] : 'inherit',
                                minWidth: '80px', 
                                width: '100%',    
                              }}
                              value={item.sampledQty ?? ''}
                              onChange={(e) => handleQuantityChange(categoryIndex, itemIndex, 'sampledQty', e.target.value)} 
                              inputProps={{ min: 0 }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              size="small"
                              id="damaged"
                              name="damaged"
                              variant="outlined"
                              disabled = {props.isArchived || !hasPermissionToWrite}
                              sx={{ 
                                backgroundColor: props.isArchived ? theme.palette.custom.gray[100] : 'inherit',
                                minWidth: '80px',  
                                width: '100%',    
      
                              }}
                              value={item.damagedQty ?? ''}
                              onChange={(e) => handleQuantityChange(categoryIndex, itemIndex, 'damagedQty', e.target.value)} 
                              inputProps={{ min: 0 }}
                            />
                          </TableCell> 
                          <TableCell>
                            <TextField
                              type="number"
                              size="small"
                              id="store-use"
                              name="store-use"
                              variant="outlined"
                              disabled = {props.isArchived || !hasPermissionToWrite}
                              sx={{ 
                                backgroundColor: props.isArchived ? theme.palette.custom.gray[100] : 'inherit',
                                minWidth: '80px',  
                                width: '100%',   
      
                              }}
                              value={item.storeUseQty ?? ''}
                              onChange={(e) => handleQuantityChange(categoryIndex, itemIndex, 'storeUseQty', e.target.value)} 
                              inputProps={{ min: 0 }}
                            />
                          </TableCell>  
                          <TableCell>
                            <TextField
                              type="number"
                              size="small"
                              id="quantity-input"
                              name="quantity-input"
                              variant="outlined"
                              disabled
                              sx={{ 
                                backgroundColor: props.isArchived ? theme.palette.custom.gray[100] : 'inherit',
                                minWidth: '80px',  
                                width: '100%',    
      
                              }}
                              value = {calculateTotal(item)}  
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>))}
          </Grid>

          <SaveModal open={openSaveModal}
            onClose={handleSaveCancelModal}
            onConfirm={handleSaveConfirmModal}
            isSaving= {isSaving}></SaveModal>
          <SubmitWasteChecksheetModal open={openSubmitModal}
            onClose={handleSubmitCancelModal}
            onConfirm={handleConfirmSubmitModal}
            data={itemsPreviewOnSubmitModal}
            isSubmitting= {isSubmitting}></SubmitWasteChecksheetModal>
          <LeavePageModal
            open={blocker.state === 'blocked' && modifiedItems.size > 0}
            onConfirm={handleLeavePageModalConfirm}
            onClose={handleLeavePageModalClose}
            onCancel={handleLeavePageModalCancel}
            onConfirmUrl='/store/daily-waste-checksheet'
            leaveWarningContent={t('saveOnLeaveWarningContent')}
            leaveWarningTitle={t('saveOnLeaveWarningTitle')}
            
          />
          </>
          } </>)}
      </Grid>
    </Box>
  );
};
export default CreateEditWasteChecksheet;

