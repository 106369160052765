import React, { memo } from 'react';
import {
  Grid, TableRow, TableCell, Box,
} from '@mui/material';
import { theme } from '../../../theme';
import { applyRoundAndFormat } from '../../../utils';
import Tooltip from '@mui/material/Tooltip';
import { PredictedDemandModel } from '../../../app/services/api/podClient';
import './OrderForm.css';
import TextWithOverflowTooltip from '../../../components/shared/TextWithOverflowTooltip';
import { OrderFormUpdate, OrderFormItem, DeliveryDates } from '../../../app/services/api/orderManagementClient';
import QuantityCell from './QuantityCell';


interface OrderFormRowProps {
    categoryId: number;
    item: OrderFormItem;
    dates: DeliveryDates[];
    orderFormUpdates: OrderFormUpdate;
    handleSingleQuantityChange: (
        newQuantity: number,
        sku: string,
        itemId: number,
        dateIndex: number
    ) => void;
    autosaveItem: () => void;
    canSaveOrder: boolean;
    isQuantityError: (item: OrderFormItem, currentValue: string) => boolean;
    quantityError: (item: OrderFormItem, currentValue: string) => string | null;
    showMessageWhenNoCost: boolean;
    currencyCode: string;
    t: (key: string) => string;
    isLargeScreen: boolean;
    predictedDemands: PredictedDemandModel[];
    showPredictedQuantities: boolean;
    handleMagicWandClicked: (predictedQuantity: number, item: OrderFormItem, index: number) => void;
}

const OrderFormRow: React.FC<OrderFormRowProps> = memo(function OrderFormRow({
  item,
  categoryId,
  dates,
  orderFormUpdates,
  handleSingleQuantityChange,
  autosaveItem,
  canSaveOrder,
  isQuantityError,
  quantityError,
  showMessageWhenNoCost,
  currencyCode,
  t,
  isLargeScreen,
  predictedDemands,
  showPredictedQuantities,
  handleMagicWandClicked,
}) {
  const renderProductInfoCell = () => (
    <TableCell sx={{ width: '40%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <Grid container
        alignItems={'center'}
        spacing={1}
        wrap="nowrap">
        <Grid item
          sx={{ width: 'auto', flexShrink: 1 }}
        >
          <Box
            sx={{
              width: '100px',
              height: isLargeScreen ? '75px' : '100px',
              justifyContent: 'center',
              backgroundColor: (item?.thumbnailUrl ?? '') != '' ? theme.palette.common.white : theme.palette.custom.gray[200], 
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              border: '1px solid #ddd',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              imageRendering: '-webkit-optimize-contrast',
              backgroundImage: `url(${item.thumbnailUrl ? item.thumbnailUrl : ''})`,
            }}
          />
        </Grid>
        <Grid item
          sx={{ minWidth: 0, paddingLeft: '10px !important', flex: 1 }}>
          {isLargeScreen ? (
            <Grid container
              spacing={2}>
              <Grid item
                xs={6}>
                <TextWithOverflowTooltip text={item.name} />
                <TextWithOverflowTooltip text={item.sku} />
                <TextWithOverflowTooltip text={item.category} />
                {(showMessageWhenNoCost || item.price === 0) ? (
                  <TextWithOverflowTooltip text="" />
                ) : (
                  <TextWithOverflowTooltip text={applyRoundAndFormat(item.price, currencyCode)} />
                )}
              </Grid>
              <Grid item
                xs={6}>
                <TextWithOverflowTooltip
                  text={`${t('orderableUOM')} ${item.orderableUom} (${item.packSize}${item.primaryUom})`}
                />
                <TextWithOverflowTooltip text={`${t('primaryUOM')} ${item.primaryUom}`} />
                <Tooltip title={`${t('stockOnHand')}: ${item.stockOnHand}`}
                  arrow>
                  <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                    {t('stockOnHandABR')} {item.stockOnHand}
                  </Box>
                </Tooltip>
                <Tooltip title={`${t('stockOnOrder')}: ${item.stockOnOrder}`}
                  arrow>
                  <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                    {t('StockOnOrderABR')} {item.stockOnOrder}
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            <>
              {item.name} <br />
              {item.sku} <br />
              {item.category} <br />
              {(showMessageWhenNoCost || item.price === 0) ? '' : applyRoundAndFormat(item.price, currencyCode)}
              <br />
              {t('orderableUOM')} {item.orderableUom} ({item.packSize}
              {item.primaryUom}) <br />
              {t('primaryUOM')} {item.primaryUom} <br />
              <Tooltip title={`${t('stockOnHandr')}: ${item.stockOnOrder}`}
                arrow>
                <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                  {t('stockOnHandABR')} {item.stockOnOrder}
                </Box>
              </Tooltip>
              <Tooltip title={`${t('stockOnOrder')}: ${item.stockOnOrder}`}
                arrow>
                <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                  {t('StockOnOrderABR')} {item.stockOnOrder}
                </Box>
              </Tooltip>
              <br />
            </>
          )}
        </Grid>
      </Grid>
    </TableCell>
  );

  // QuantityCell for each date
  const renderQuantityCells = dates.map((dateObj, index) => {
    const date = dateObj.deliveryDate;
    const originalQty = item.quantity[index].toString();
    const updatedQty =
            orderFormUpdates.items.find((u) => u.id === item.id)?.dates.find((d) => d.date === date)?.quantity ??
            originalQty;
    const currentValue = updatedQty.toString();

    const podItem = predictedDemands.find((x) => x.itemNumber === item.sku);
    const predictedItem = podItem?.predictedDemandItems[index];
    const hasPrediction = predictedItem?.hasPrediction;
    const showPrediction = showPredictedQuantities && hasPrediction;
    const predictedQuantity = hasPrediction ? predictedItem.quantity : 0;
    const matchesPredictedValue = predictedQuantity.toString() === currentValue;
    const borderColor = showPrediction && matchesPredictedValue ? theme.palette.success[400] : '';

    const maxOrderQuantity = podItem?.predictionInfo?.maxOrderQuantity ?? undefined;
    const predictedDemandQuantity = predictedItem?.predictedDemandQuantity ?? undefined;
    const safetyStock = podItem?.predictionInfo?.safetyStock ?? undefined;

    return (
      <TableCell key={`${item.id}-${date}`} 
        align="center">
        <QuantityCell
          item={item}
          dateIndex={index}
          key={`quantityCell-${item.id}-${date}-${currentValue}`} 
          currentValue={currentValue}
          handleSingleQuantityChange={handleSingleQuantityChange}
          autosaveItem={autosaveItem}
          canSave={canSaveOrder}
          isError={isQuantityError(item, currentValue)}
          helperText={quantityError(item, currentValue)}
          showPrediction={showPrediction}
          predictedQuantity={predictedQuantity}
          onMagicWandClick={() => handleMagicWandClicked(predictedQuantity, item, index)}
          borderColor={borderColor}
          maxOrderQuantity={maxOrderQuantity}
          predictedDemandQuantity={predictedDemandQuantity}
          safetyStock={safetyStock}
          primaryUom={item.primaryUom}
        />
      </TableCell>
    );
  });

  return (
    <TableRow>
      {renderProductInfoCell()}
      {renderQuantityCells}
    </TableRow>
  );
});

export default OrderFormRow;