import { ReportDownloadDetail } from '../interfaces/ReportDownloadDetail';
import { ReportDetails } from './../../../app/services/api/generated';
import { ReportType } from './../../../enums/ReportType';
import { getAdjustedOffsetDate } from './../../../utils';

export const setFriendlyName = (name: string): string => {
  const nameRelationships = [
    { name: ReportType.ActualVsTheory, match: 'theory_vs_actual_' },
    { name: ReportType.StockOnHand, match: 'stock_on_hand_' },
    { name: ReportType.Waste, match: 'waste_' },
    { name: ReportType.ItemActivity, match: 'item_activity_' },
    { name: ReportType.Transfers, match: 'transfers_' },
    { name: ReportType.ExtendedStockCount, match: 'extended_stock_count_' },
    { name: ReportType.StockCount, match: 'stock_count_' },
    { name: ReportType.ItemTimeout, match: 'item_time_out_' },
    { name: ReportType.CostOfSales, match: 'cost_of_sales_' },
    { name: ReportType.StockDays, match: 'stock_days_' },
    { name: ReportType.Invoice, match: 'InvoiceReconciliation_' },
    { name: ReportType.StockCountSummary, match: 'stockcountsummary_' },
    { name: ReportType.Margin, match: 'margin_' },
    { name: ReportType.EgActualVsTheory, match: 'EGTheoryActualReport_' },
    { name: ReportType.EgWaste, match: 'EGWasteReport_' },
    { name: ReportType.EgCostOfSales, match: 'EGCostOfSalesReport_' },
    { name: ReportType.EgItemActivity, match: 'EGItemActivityReport_' },
    { name: ReportType.EgItemTimeout, match: 'EGItemTimeOutReport_' },
    { name: ReportType.EgStockDays, match: 'EGStockDaysReport_' },
    { name: ReportType.EgStockOnHand, match: 'EGStockOnHandReport_' },
    { name: ReportType.EgTransfers, match: 'EGTransferReport_' },
    { name: ReportType.CreditPurchase, match: 'creditpurchase_' },
    { name: ReportType.StockCountVsUsage, match: 'Stock_Counts_Vs_Usage_' },
    { name: ReportType.DcShortage, match: 'dc_shortage_' },
    { name: ReportType.ConstrainedItem, match: 'constraineditem_' },
    { name: ReportType.ItemStockOut, match: 'item_stock_out_' },
    { name: ReportType.Receipt, match: 'receipts_' },
    { name: ReportType.MadeInStore, match: 'made_in_store_' },
    { name: ReportType.Cogs, match: 'cogs_' },
    { name: ReportType.SalesCheck, match: 'checks_' },
    { name: ReportType.ItemUsage, match: 'item_usage' },
  ];
  
  for (const nameRel of nameRelationships) {
    if (name.includes(nameRel.match)) {
      return nameRel.name;
    }
  }
  
  return name;
};
  
export const prepareReports = (reports: ReportDetails[]) => {
  const expandedReports: ReportDownloadDetail[] = [];
  
  for (const report of reports) {
    let from = '';
    let to = '';
  
    if (!report.name) {
      continue;
    }
  
    const name = setFriendlyName(report.name);
    const splitName = report.name.split('_');
    if (splitName[splitName.length - 1] === 'report') {
      if (report.name.includes('theory_vs_actual_') ||
          report.name.includes('waste_') ||
          report.name.includes('item_activity_') ||
          report.name.includes('transfers_') ||
          report.name.includes('item_time_out_') ||
          report.name.includes('margin_') ||
          report.name.includes('InvoiceReconciliation_') ||
          report.name.includes('EGTheoryActualReport_') ||
          report.name.includes('EGWasteReport_') ||
          report.name.includes('EGCostOfSalesReport_') ||
          report.name.includes('EGItemActivityReport_') ||
          report.name.includes('EGItemTimeOutReport_') ||
          report.name.includes('EGStockDaysReport_') ||
          report.name.includes('EGStockOnHandReport_') ||
          report.name.includes('EGTransferReport_') ||
          report.name.includes('creditpurchase_') ||
          report.name.includes('Stock_Counts_Vs_Usage_') ||
          report.name.includes('constraineditem_') ||
          report.name.includes('item_stock_out_') ||
          report.name.includes('receipts_') ||
          report.name.includes('made_in_store_') ||
          report.name.includes('checks_') ||
          report.name.includes('cogs_') ||
          report.name.includes('item_usage')
      ) {
        from = buildDateString(splitName[splitName.length - 3]);
        to = buildDateString(splitName[splitName.length - 2]);
      }
    }
  
    expandedReports.push({
      id: report.id,
      name: report.name,
      displayName: name,
      createdAt: getAdjustedOffsetDate(new Date(report.createdAt ?? '')),
      dateFrom: from,
      dateTo: to,
      storeNumber: report.starbucksStoreNumber,
      storeName: report.storeName,
    });
  }
  
  return expandedReports;
};

const buildDateString = (rawDate: string): string => {
  if (rawDate.length === 6) {
    const breakdown = rawDate.match(/.{1,2}/g);
    
    //MM/DD/YY
    return breakdown ?  `${breakdown[1]}/${breakdown[2]}/${breakdown[0]}` : '';
  }

  return '';
};