import { Box, Modal, Button, Autocomplete, TextField, TextFieldProps, Typography, useTheme, useMediaQuery, Stack, FilterOptionsState } from '@mui/material';
import { Building02 } from '../../../../../assets';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../shared/useUser';
import { Store } from '../../../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../../../theme/context/themeProviderContext';
import useNswagClient from '../../../../../hooks/api/useNswagClient';
import useLogError from '../../../../../hooks/useLogError';
import { useSelectedBranding } from '../../../../../hooks/useSelectedBranding';

const textClamp = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};

interface SelectStoreModalProps {
    open: boolean,
    onConfirm: () => void,
    onClose: () => void,
}

export const SelectStoreModal = ({ onConfirm, onClose, open }: SelectStoreModalProps) => {
  const { user, setSelectedStore } = useContext(UserContext);
  const [newStore, setNewStore] = useState<Store | null>(null);
  const theme = useTheme();
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const { getBrandingForFranchise } = useNswagClient();
  const [, changeBranding] = useContext(ThemeContext);
  const branding = useSelectedBranding();
  const { logError } = useLogError();

  useEffect(() => {
    setNewStore(null);
  }, [onClose]);

  const updateStore = ((store: Store) => {
    setSelectedStore(store);

    if (store?.franchiseId && store.franchiseId != branding.franchiseId) {
      updateBranding(store.franchiseId);
    } else {
      onConfirm();
    }
  });

  const updateBranding = ((id: number) => {
    getBrandingForFranchise(id)
      .then((result) => {
        if (result?.data)
          changeBranding(result.data);

        if (!result)
          changeBranding(result);
      })
      .catch((error) => {
        logError(error);
      })
      .finally(() => {
        onConfirm();
      });
  });

  const filter = (options: Store[], state: FilterOptionsState<Store>)  => {
    return options.filter((option) => option.storeNumber?.startsWith(state.inputValue) || option.storeName?.toLowerCase().includes(state.inputValue.toLowerCase()));
  };

  return (<Modal
    open={open}
    onClose={onClose}
  >
    <Stack
      position='absolute'
      gap={9}
      top='50%'
      left='50%'
      minHeight='200px'
      bgcolor='background.paper'
      border='1px solid grey'
      boxShadow='24px'
      p={6}
      width={`${isMobile ? '300px' : '600px'}`}
      sx={{
        transform: 'translate(-50%, -50%)',
      }}>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        gap={6}>
        <Building02 />
        <Typography id="modal-modal-title">
          {t('selectAStore')}
        </Typography>
      </Box>
      <Autocomplete
        onChange={(event, newValue: Store | null) => {
          if (newValue) setNewStore(newValue);
        }}
        id="combo-box-demo"
        options={user?.stores ?? []}
        sx={{ width: '100%', overflow: 'auto', pt: 4 }}
        getOptionLabel={(option: Store) => option.storeName ?? ''}
        filterOptions={(options, state) =>filter(options, state)}
        renderOption={(props, option) => (
          <Box component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 }, gap: 2 }}
            {...props}
            key={(option?.storeName ?? '') + (option?.storeNumber ?? '')}>
            <Building02 style={{ minWidth: '24px' }}/>
            <Typography
              variant='textSM'
              sx={{
                ...textClamp,
              }}>
              {option.storeName}
            </Typography>
          </Box>
        )}
        renderInput={(params) => {
          return <TextField placeholder={t('selectAStore')}
            {...params as TextFieldProps} />;
        }} />
      <Box display='flex'
        flexDirection='row'
        justifyContent='center'
        gap={9}>
        <Button variant='secondary'
          onClick={onClose}
          type='button'>{t('dismiss')}</Button>
        <Button
          disabled={!newStore}
          onClick={() => {
            if (newStore) updateStore(newStore);
          }}
          type='button'
        >
          {t('confirm')}</Button>
      </Box>
    </Stack>
  </Modal>);
};