import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { theme } from '../../../theme';
import { Item as ItemOM } from '../../../app/services/api/orderManagementClient';
import { Item, StockItem as ItemIM } from '../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { useProductDetailsContext } from '../context/ProductDetailsContext';
import { applyRoundAndFormat } from '../../../utils';
import { useSearchParams } from 'react-router-dom';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { UserContext } from '../../../components/shared/useUser';

type DetailsProps = {
  imData?: ItemIM;
  omData?: ItemOM;
  currencyCode?: string;
}

const Details: FC<DetailsProps> = ({ imData, omData, currencyCode }) => {
  const { t } = useTranslation('productDetails');
  const { minimumQuantity, setMinimumQuantity, isEditMode, isValid, setIsValid } = useProductDetailsContext();
  const [searchParams] = useSearchParams();
  const isOrderable = searchParams.get('orderable') === 'true';
  const [stockData, setStockData] = useState<Item>();
  const { getByItemNumber } = useNswagClient();
  const { selectedStore } = useContext(UserContext);
  const sku = searchParams.get('sku');

  useEffect(() => {
    getItem(sku ?? '');
  }, []);

  const onQuantityChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setMinimumQuantity(value);
      setIsValid(!(value === '0' || value === ''));
    }
  };

  const getItem = async (sku: string): Promise<void> => {
    const itemResponse = await getByItemNumber(sku, selectedStore?.storeNumber);
    setStockData(itemResponse.data);
  };

  return (
    <>
      <Paper sx={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', width: '100%', height: '100%', my: 5, px: '32px', py: '32px', pt: '16px' }}>
        <Typography
          variant='textMD'
          fontWeight='bold'
          component='div'
          color={theme.palette.common.white}
          sx={{ pb: '16px' }}
        >
          {imData?.description ?? omData?.name}
        </Typography>
        <Grid container
          spacing={8}
          color={theme.palette.common.white}>
          <Grid item>
            <Box
              height='200px'
              width='200px'
              border={`1px solid ${theme.palette.common.white}`}
              sx={{ 
                backgroundColor: (omData?.thumbnailUrl ?? '') != '' ? theme.palette.common.white : theme.palette.custom.gray[200], 
                borderRadius: '5px',
                backgroundImage: `url(${omData?.thumbnailUrl ?? ''})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                imageRendering: '-webkit-optimize-contrast',
                flexShrink:0,
                flexGrow:0,
              }}
            >
            </Box>
          </Grid>
          <Grid container 
            item
            width={'auto'}
            flexGrow={1}
            justifyContent={'stretch'}
            color={theme.palette.common.white}>
            <Box>
              <InfoDetail label={isOrderable ? t('sku') : t('itemNumber')}
                value={imData?.itemNumber ?? omData?.sku ?? t('notAvailable')} />
              <InfoDetail label={t('soh')}
                value={omData?.stockOnHand ?? t('notAvailable')} />
              <InfoDetail label={t('category')}
                value={imData?.primaryCategory ?? omData?.category ?? t('notAvailable')}/>
              <InfoDetail label={t('unitCostPrice')}
                value={applyRoundAndFormat(imData?.costPrice ?? omData?.price ?? 0, currencyCode)}/>
            </Box>
            <Box>
              <InfoDetail label={t('shippableUOM')}
                value={stockData?.pickPackUom ?? stockData?.uom ?? t('notAvailable')}/>
              <InfoDetail label={t('primaryUOM')}
                value={omData?.primaryUom ?? imData?.uom ?? t('notAvailable')} />
              <InfoDetail label={t('primaryPerShippableUOM')}
                value={omData?.packSize ?? t('notAvailable')}/>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Grid container
        py={5}
        px={10}
        direction='column'
        xs={12}
        md={5}
        lg={5}
        rowGap={5}
      >
        <Grid item>
          <InfoDetail label={t('bomEnabled')}
            value={imData?.isBom ? t('enabled') : t('notEnabled')}
          />
        </Grid>
        <Grid item>
          <InfoDetail label={t('posEnabled')}
            value={imData?.isPosEnabled ? t('enabled') : t('notEnabled')}
          />
        </Grid>
        <Grid item>
          <InfoDetail label={t('stockItem')}
            value={imData?.isStockItem ? t('enabled') : t('notEnabled')}
          />
        </Grid>
        <Grid item>
          <InfoDetail label={t('saleItem')}
            value={stockData?.isSaleItem ? t('enabled') : t('notEnabled')}
          />
        </Grid>
        <Grid item>
          <InfoDetail label={t('status')}
            value={stockData?.status ?? t('notAvailable')}
          />
        </Grid>
        <Grid item>
          {isEditMode ? (
            <EditableInfoDetail label={t('minOrderQty')}>
              <TextField
                size='small'
                placeholder={imData?.minimumQuantity?.toString() ?? t('minQtyPlaceholder')}
                sx={{ pl: 2 }}
                value={minimumQuantity}
                onChange={onQuantityChange}
                error={!isValid}
                helperText={!isValid ? t('minQuantityValidationMessage') : ''}
              />
            </EditableInfoDetail>
          ) : (
            <InfoDetail label={t('minOrderQty')}
              value={imData?.minimumQuantity ?? Number(0).toFixed(2)}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

type InfoDetailProps = {
  label: string;
  value: string | number | boolean;
  height?: string;
}

const InfoDetail = ({ label, value }: InfoDetailProps) => {
  return (
    <Typography variant='textSM' 
      component='div'
      p={1}
      paddingBottom={'16px'}>{label}: {value}</Typography>
  );
};

type EditableInfoDetailProps = {
  label: string;
  children: JSX.Element;
  height?: string;
}

const EditableInfoDetail = ({ label, children, height }: EditableInfoDetailProps) => {
  return (
    <Box display='flex'
      height={height}
      flexDirection='row'
      alignItems='baseline'
    >
      <Typography variant='textSM'
        p={1}
      >{label}:</Typography>
      {children}
    </Box>
  );
};

export default Details;