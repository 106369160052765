import { Box, Grid, Button, Select, MenuItem, TextField, InputAdornment, SvgIcon, Autocomplete, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, useMediaQuery, Paper, Typography, FormControlLabel, Switch } from '@mui/material';
import { ItemsFilterOptions, client, Item, Category, DeliverySchedule, OrderDetails, OrderType, Supplier, CutOffTimeLeft } from '../../../../app/services/api/orderManagementClient';
import { isAutoShipOrder, OrderType as OrderTypeEnum, OrderTypeTranslationMap } from '../../../../enums/OrderType';
import { ChevronRight, MagicWand01, SearchSm } from '../../../../assets';
import CustomPagination from '../../../../components/forms/Pagination';
import { UserContext } from '../../../../components/shared/useUser';
import LoadingWheel from '../../../../components/ui/LoadingWheel';
import useLogError from '../../../../hooks/useLogError';
import { useContext, useState, useEffect, useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../theme';
import { Pill } from '../../../../components/shared/Pill';
import ProductRow from './ProductRow';
import dayjs, { Dayjs } from 'dayjs';
import useCurrencyCodeConfiguration from '../../../../hooks/configurations/useCurrencyCodeConfiguration';
import { useNavigate } from 'react-router-dom';
import DatePickerLocalized from '../../../../components/shared/DatePickerLocalized';
import { starbucksFranchises } from '../../../../enums/FranchiseNames';
import { OrderCategoriesTranslationMap, OrdersCategoriesParentsMap, isOrderCategory, isOrderCategoryParent } from '../../../../enums/OrderCategories';
import { PredictedDemandModel, podClient } from './../../../../app/services/api/podClient';
import { isPodEnabledEnvironment } from '../../../../app/services/environment';
import useNswagClient from './../../../../hooks/api/useNswagClient';

const Products = () => {
  const { selectedStore } = useContext(UserContext);
  const { logError } = useLogError();
  const { t } = useTranslation('products');
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const pageReset = useRef(false);
  const productRowRefs = useRef<{matchPredictedQuantities: () => void }[]>([]);

  const [itemSearch, setItemSearch] = useState<string>('');
  const [orderType, setOrderType] = useState<number>(-1);
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);
  const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc');
  const [category, setCategory] = useState<number>(-1);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedScheduleDate, setSelectedScheduleDate] = useState<Dayjs | null>(null);
  const [activeOrder, setActiveOrder] = useState<OrderDetails>();
  const [deliverySchedules, setDeliverySchedules] = useState<DeliverySchedule[]>([]);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [supplier, setSupplier] = useState<string>('');
  const [showPredictedQuantity, setShowPredictedQuantity] = useState<boolean>(true);
  const [isPodEnabled, setIsPodEnabled] = useState(false);
  const [predictedDemands, setPredictedDemands] = useState<PredictedDemandModel[]>([]);
  const { getConversions } = useNswagClient();

  const pageSize = 20;
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  const isStarBucksRetail = starbucksFranchises.includes(selectedStore?.franchiseName ?? '');
  const showMessageWhenNoCost = isStarBucksRetail && activeOrder?.status != 'Order Confirmed';

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  useEffect(() => {
    const fetchOrderTypesOnPageLoad = async () => {
      await fetchOrderTypes();
    };
    fetchOrderTypesOnPageLoad();

    if (isPodEnabledEnvironment()) {
      loadPodStoreConfiguration();
    }
  }, [selectedStore]);

  useEffect(() => {
    if (isPodEnabled && selectedScheduleDate && filteredItems.length > 0) {
      loadPredictedDemands();
    }
  }, [isPodEnabled, selectedScheduleDate, filteredItems]);

  useEffect(() => {
    const fetchItemsOnPagination = async () => {
      if (!loading && orderType !== -1 && !pageReset.current) {
        setLoading(true);
        await fetchProducts({
          item: itemSearch,
          orderType: orderType,
          storeNumber: selectedStore?.storeNumber,
          categoryId: category,
          supplier: supplier,
          pageNumber: page,
          pageSize: pageSize,
          sort: 'Name',
          direction: sortBy,
        });
        setLoading(false);
      }
      pageReset.current = false;
      scrollOnTop();
    };
    fetchItemsOnPagination().catch((ex) => { console.error(ex); });
  }, [page]);

  const scrollOnTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const loadData = async (storeNumber: string | undefined, itemSearch: string, categoryId: number, orderType: number, supplier: string, pageNumber: number, pageSize: number, sort: string, direction: string) => {
    if (storeNumber) {
      setLoading(true);
      await fetchProducts(getFilterOptions(storeNumber, itemSearch, categoryId, orderType, supplier, pageNumber, pageSize, sort, direction));
      await fetchCategories(orderType, supplier);
      scrollOnTop();
      setLoading(false);
    }
  };

  const loadPodStoreConfiguration = async () => {
    try {
      if (selectedStore?.franchiseName && selectedStore.storeNumber) {
        const response = await podClient.getStoreConfiguration(selectedStore.franchiseName, selectedStore.storeNumber);
        setIsPodEnabled(response.isPodEnabled);
      }
    } catch (error) {
      logError(error);
    }
  };

  const loadPredictedDemands = async () => {
    try {
      const parsedDate = dayjs(selectedScheduleDate, 'DD-MM-YYYY');
      const dates = [parsedDate.format('DD-MM-YYYY')];
      const nextDate = deliverySchedules.find(x => parsedDate.isBefore(dayjs(x.deliveryDate, 'DD-MM-YYYY')));

      if (nextDate) {
        dates.push(nextDate.deliveryDate);
      }
      
      if (selectedStore?.franchiseName && selectedStore.storeNumber) {
        const predictions = await podClient.getPredictionsForItems(selectedStore.franchiseName, selectedStore.storeNumber, dates,  filteredItems.map(x => x.sku));
        await loadUomConversions(predictions);
      }
    } catch (error) {
      logError(error);
    } 
  };

  const loadUomConversions = async (predictions: PredictedDemandModel[]) => {
    const itemNumbers = predictions.map(x => x.itemNumber);
    const items = filteredItems.filter(x => itemNumbers.includes(x.sku));
    const response = await getConversions(undefined, undefined, undefined, undefined, selectedStore?.storeNumber);

    const conversions = response.conversions?.filter(conversion => {
      const item = items?.find(i => i.sku === conversion?.itemId);
      return item && item.primaryUom === conversion?.fromUom && item.orderableUom === conversion.toUom;
    });

    predictions.forEach((x) => {
      const conversion = conversions?.find(c => c?.itemId === x.itemNumber);
      const conversionNumber = conversion?.conversionNumber ?? 1;

      x.predictedDemandItems = x.predictedDemandItems.map(({ quantity, ...rest }) => ({
        ...rest,
        quantity: Math.ceil(quantity / conversionNumber),
      }));
    });

    setPredictedDemands(predictions);
  };

  const fetchProducts = async (filters: ItemsFilterOptions) => {
    try {
      const itemsResponse = await client.getItems(filters);
      setFilteredItems(itemsResponse.data.items);
      setTotalPages(itemsResponse.data.totalPages);
      setTotalCount(itemsResponse.data.totalCount);
      if (page !== itemsResponse.data.pageNumber) {
        pageReset.current = true;
        setPage(itemsResponse.data.pageNumber);
      }
      if (activeOrder) {
        sortItemsByActiveOrder(itemsResponse.data.items, activeOrder);
      }
    } catch (error) {
      logError(error);
    }
  };

  const fetchOrderTypes = async () => {
    try {
      const orderTypesResponse = await client.getOrderTypes(selectedStore?.storeNumber);
      if (orderTypesResponse.success && orderTypesResponse.data.length > 0) {
        const nonAutoshipOrderTypes = orderTypesResponse.data.filter(x => !isAutoShipOrder(x.id));
        setOrderTypes(nonAutoshipOrderTypes);
        const defaultOrderType = nonAutoshipOrderTypes.length > 0 ? nonAutoshipOrderTypes[0].id : -1;
        setOrderType(defaultOrderType);
        await fetchSuppliers();
        await fetchDeliverySchedules(defaultOrderType, '');

        await loadData(selectedStore?.storeNumber, itemSearch, category, defaultOrderType, supplier, page, pageSize, 'Name', sortBy);
      }
    } catch (error) {
      logError(error);
    }
  };

  const fetchCategories = async (selectedOrderType: number, supplier: string) => {
    try {
      const categories = await client.getCategories(selectedStore?.storeNumber, selectedOrderType, supplier);
      setCategories(categories.data);
    } catch (error) {
      setCategories([]);
    }
  };

  const fetchSuppliers = async () => {
    try {
      const suppliers = await client.getSuppliers(selectedStore?.storeNumber);
      setSuppliers(suppliers.data);
    } catch (error) {
      logError(error);
    }
  };

  const disableUnavailableDates = (date: Dayjs) => {
    const dayjsDate = dayjs(date);

    if (isAutoShipOrder(orderType)) {
      return true;
    }

    return !deliverySchedules.some((schedule) =>
      dayjsDate.isSame(dayjs(schedule.deliveryDate, 'DD-MM-YYYY'), 'day'),
    );
  };

  const fetchDeliverySchedules = async (selectedOrderType: number | undefined, supplierNumber?: string) => {
    try {
      const deliverySchedulesResponse = await client.getDeliverySchedules(selectedStore?.storeNumber, selectedOrderType, supplierNumber);
      setDeliverySchedules(deliverySchedulesResponse.data ?? []);

      await findNearestOrderDate(deliverySchedulesResponse.data ?? [], selectedOrderType ?? 0, supplierNumber ?? '');

    } catch (error) {
      logError(error);
    }
  };

  const findNearestOrderDate = async (deliverySchedules: DeliverySchedule[], selectedOrderType: number, supplierNumber: string) => {

    const sortedSchedules = deliverySchedules.map(schedule => ({
      ...schedule,
      deliveryDateObj: new Date(schedule.deliveryDate.split('-').reverse().join('-')),
    })).sort((a, b) => a.deliveryDateObj.getTime() - b.deliveryDateObj.getTime());

    const date = sortedSchedules[0].deliveryDate;
    const formattedDate = dayjs(date, 'DD-MM-YYYY');

    try {
      const orderResponse = await client.getOrderByDeliveryDate(selectedStore?.storeNumber, selectedOrderType, date, supplierNumber);
      if (orderResponse?.data) {
        setActiveOrder(orderResponse.data);
        sortItemsByActiveOrder(filteredItems, orderResponse.data);
        setSelectedScheduleDate(formattedDate);
      }
    } catch (error) {
      logError(error);
    }
  };

  const getFilterOptions = (storeNumber: string, itemSearch: string, categoryId: number, orderType: number, supplier: string, pageNumber: number, pageSize: number, sort: string, direction: string) => {
    const filterOptions: ItemsFilterOptions = {
      storeNumber: storeNumber,
      item: itemSearch,
      categoryId: categoryId,
      orderType: orderType,
      supplier: supplier,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sort: sort,
      direction: direction,
    };
    return filterOptions;
  };

  const handleSearch = async () => {
    await loadData(selectedStore?.storeNumber, itemSearch, category, orderType, supplier, page, pageSize, 'Name', sortBy);
  };

  const clearFilters = async () => {
    const defaultOrderType = orderTypes ? orderTypes[0].id : -1;
    setItemSearch('');
    setCategory(-1);
    setOrderType(defaultOrderType);
    setSortBy('asc');
    setPage(0);
    setActiveOrder(undefined);
    setSelectedScheduleDate(null);
    await loadData(selectedStore?.storeNumber, '', -1, defaultOrderType, '', 0, pageSize, 'Name', 'asc');
  };

  const onOrderTypeChange = async (orderTypeId: number) => {
    setLoading(true);
    setSelectedScheduleDate(null);
    setActiveOrder(undefined);
    setOrderType(orderTypeId);
    setPage(0);
    setItemSearch('');
    setCategory(-1);
    const supplier = suppliers && suppliers.length > 0 && orderTypeId === OrderTypeEnum.DirectStoreDelivery ? suppliers[0].number : '';
    setSupplier(supplier);
    await fetchCategories(orderTypeId, supplier);
    await fetchProducts({
      item: '',
      orderType: orderTypeId,
      storeNumber: selectedStore?.storeNumber,
      categoryId: -1,
      supplier: supplier,
      pageNumber: 0,
      pageSize: 15,
      sort: 'Name',
      direction: 'asc',
    });
    await fetchDeliverySchedules(orderTypeId, supplier);
    setLoading(false);
  };

  const handleSupplierChange = async (number: string) => {
    setSupplier(number);
    setLoading(true);
    await fetchProducts({
      item: itemSearch,
      orderType: orderType,
      storeNumber: selectedStore?.storeNumber,
      categoryId: category,
      supplier: number,
      pageNumber: page,
      pageSize: pageSize,
      sort: 'Name',
      direction: sortBy,
    });
    await fetchCategories(orderType, number);
    await fetchDeliverySchedules(orderType, number);
    setLoading(false);
  };

  const onDeliveryDatePick = async (newDate: Dayjs | null) => {
    if (!newDate) {
      return;
    }

    const formattedDate = dayjs(newDate).format('DD-MM-YYYY');
    const highlightedDates = deliverySchedules.map(schedule => schedule.deliveryDate);

    if (highlightedDates.includes(formattedDate)) {
      try {
        const orderResponse = await client.getOrderByDeliveryDate(selectedStore?.storeNumber, orderType, formattedDate, supplier);
        if (orderResponse?.data) {
          setActiveOrder(orderResponse.data);
          sortItemsByActiveOrder(filteredItems, orderResponse.data);
          setSelectedScheduleDate(newDate);
        }
      } catch (error) {
        logError(error);
      }
    }
  };

  const sortItemsByActiveOrder = (allItems: Item[], selectedOrder: OrderDetails) => {
    const sortedItems: Item[] = [];
    const existingItems = allItems.filter(item =>
      selectedOrder.items.some(i => i.sku === item.sku),
    );
    const possibleItems = allItems.filter((element) => !existingItems.includes(element));
    sortedItems.push(...existingItems, ...possibleItems);
    setFilteredItems(sortedItems);
  };

  const onAddItemConfirmed = () => {
    onDeliveryDatePick(selectedScheduleDate);
  };

  const getBasketQuantity = (itemId: number) => {
    return activeOrder?.items?.find(item => item.itemId === itemId)?.quantity ?? 0;
  };

  const handleShowPredictedQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowPredictedQuantity(event.target.checked);
  };

  const matchPredictedQuantities = () => {
    productRowRefs.current.forEach(childRef => {
      if (childRef?.matchPredictedQuantities) {
        childRef.matchPredictedQuantities();
      }
    });
  };

  const cutOffTimeLeftFormat = (cutOffTimeLeft: CutOffTimeLeft) => {
    if (!cutOffTimeLeft ||
      cutOffTimeLeft?.days == undefined ||
      !cutOffTimeLeft?.minutes == undefined ||
      !cutOffTimeLeft?.hours == undefined ||
      (cutOffTimeLeft?.days === 0 && cutOffTimeLeft?.hours === 0 && cutOffTimeLeft?.minutes === 0)) {
      return `${t('cutOffHasPassed')}`;
    }

    const daysString = cutOffTimeLeft?.days > 0 ? `${cutOffTimeLeft.days} ${t('days')}, ` : '';
    const hoursString = cutOffTimeLeft?.hours > 0 ? `${cutOffTimeLeft.hours} ${t('hours')}, ` : '';
    const minutesString = `${cutOffTimeLeft?.minutes} ${t('minutes')}`;

    return `${daysString}${hoursString}${minutesString}`;
  };

  const autocompleteOptions = [{ id: -1, name: 'All', parentName: '' }, ...(Array.isArray(categories) ? categories : [])];
  const orderTypesOptions = [...(Array.isArray(orderTypes) ? orderTypes : [])];

  const getOptionLabel = (option: { parentName?: string; name: string }): string => {
    const parentName = option.parentName;
    const categoryName = option.name;

    const translatedParent = parentName && isOrderCategoryParent(parentName)
      ? t(OrdersCategoriesParentsMap[parentName as keyof typeof OrdersCategoriesParentsMap])
      : parentName;

    const translatedCategory = isOrderCategory(categoryName)
      ? t(OrderCategoriesTranslationMap[categoryName as keyof typeof OrderCategoriesTranslationMap])
      : categoryName;

    if (parentName) {
      return translatedParent
        ? `${translatedParent} > ${translatedCategory}`
        : `${parentName} > ${translatedCategory}`;
    } else {
      return translatedCategory;
    }
  };

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignContent: 'center', my: '24px', py: '5px' }}>
      <Box mb={6}
        sx={{ px: '24px' }}
        textAlign='left'
        flexDirection='row'
        alignItems='center'>
        <Typography variant='textLG'>{t('orderableItems')}</Typography>
        <Pill text={`${totalCount} ${t('items')}`}/>
      </Box>
      <Grid container>
        <Grid item
          container
          mt={5}
          xs={12}
        >
          <Grid item
            container
            xs={12}
            sx={{ px: '24px' }}
          >
            <Grid
              container
              alignSelf='center'
              xs={12}
              md={12}
            >
              <Grid item
                ml={10}
                xs={6}
                md={2}>
                <Typography
                  variant='textMD'>{t('selectOrder')}</Typography> </Grid>
              <Grid item
                xs={6}
                md={2}><Button
                  variant='primary'
                  size='lg'
                  disabled={!activeOrder}
                  onClick={() => navigate(`/store/order/${activeOrder?.id}`)}
                  startIcon={<ChevronRight />}>
                  {t('view')}
                </Button></Grid>
              <Grid item
                xs={12}
                md={5}> {activeOrder?.cutOffTimeLeft && <Typography
                  variant='textMD'>{t('timeToOrderCutoff')} <strong>{cutOffTimeLeftFormat(activeOrder.cutOffTimeLeft)}</strong></Typography>}
              </Grid>
            </Grid>
            <Grid item
              alignSelf='center'
              xl={4}
              md={isMobile ? 12 : 7}
              xs={isMobile ? 12 : 6}
            >
              <DatePickerLocalized
                label=''
                useCalendar={true}
                valueForDateCalendar={selectedScheduleDate}
                onChangeDateCalendar={onDeliveryDatePick}
                shouldDisableDateCalendar={disableUnavailableDates}
                disabled={orderType < 0}
                disableHighlightToday={true}
              />
            </Grid>
            <Grid item
              alignSelf='center'
              xl={8}
              md={isMobile ? 12 : 5}
              xs={isMobile ? 12 : 6}
            >
              <Grid container
                spacing={3}
                mt={3}
                p={5}>
                <Grid item
                  xl={orderType === OrderTypeEnum.DirectStoreDelivery ? 4 : 6}
                  xs={12}
                  md={6}
                  p={3}>
                  <TextField
                    fullWidth
                    size='small'
                    label={t('orderType')}
                    value={orderType}
                    required
                    onChange={(e) => onOrderTypeChange(Number(e.target.value))}
                    select
                  >
                    {orderTypesOptions.map((type) => (
                      <MenuItem key={type.id}
                        value={type.id}>
                        {t(OrderTypeTranslationMap[type.name as keyof typeof OrderTypeTranslationMap]) || type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {orderType === OrderTypeEnum.DirectStoreDelivery &&
                  <Grid item
                    xl={4}
                    md={!isMobile ? 6 : 12}
                    xs={12}
                    alignSelf='flex-end'
                    p={5}>
                    <TextField
                      fullWidth
                      size='small'
                      label='Supplier'
                      disabled={loading}
                      value={supplier}
                      required
                      onChange={(e) => handleSupplierChange(e.target.value)}
                      select
                    >
                      {suppliers.map((type) => (
                        <MenuItem key={type.id}
                          value={type.number}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                }
                <Grid item
                  xl={orderType === OrderTypeEnum.DirectStoreDelivery ? 4 : 6}
                  xs={12}
                  md={orderType === OrderTypeEnum.DirectStoreDelivery ? 12 : 6}
                  p={3}>
                  <Select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value as 'asc' | 'desc')}
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                    fullWidth
                  >
                    <MenuItem value={'asc'}>{t('asc')}</MenuItem>
                    <MenuItem value={'desc'}>{t('desc')}</MenuItem>
                  </Select>
                </Grid>
                <Grid item
                  xs={12}
                  md={6}
                  p={3}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={t('searchBy')}
                    value={itemSearch}
                    onChange={(e) => setItemSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon>
                            <SearchSm />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item
                  xs={12}
                  md={6}
                  p={3}>
                  <Autocomplete
                    size="small"
                    options={autocompleteOptions}
                    getOptionLabel={getOptionLabel}
                    value={categories.find(cat => cat.id === category) ?? autocompleteOptions[0]}
                    onChange={(event, newValue) => {
                      setCategory(newValue ? newValue.id : -1);
                    }}
                    renderInput={(params) => (
                      <TextField {...params}
                        label={t('category')}
                        placeholder="Choose..." />
                    )}
                  />
                </Grid>
                <Grid item
                  xs={12}
                  md={6}
                  p={3}>
                  <Button
                    fullWidth
                    variant="secondary"
                    disabled={loading}
                    onClick={clearFilters}
                    size="lg"
                  >
                    {t('clearAll')}
                  </Button>
                </Grid>

                <Grid item
                  xs={12}
                  md={6}
                  p={3}>
                  <Button
                    fullWidth
                    variant="primary"
                    disabled={loading}
                    onClick={handleSearch}
                    size="lg"
                    startIcon={<SearchSm />}
                  >
                    {t('search')}
                  </Button>
                </Grid>
                {
                  isPodEnabled && 
                  <>
                    <Grid item
                      xs={12}
                      md={6}
                      p={3}>
                      <FormControlLabel control={
                        <Switch checked={showPredictedQuantity}
                          onChange={handleShowPredictedQuantityChange}/>
                      }
                      label={t('showPredictedQuantities')} />
                    </Grid>
                    {
                      showPredictedQuantity && 
                      <Grid item
                        xs={12}
                        md={6}
                        p={3}>
                        <Button fullWidth
                          variant="primary"
                          disabled={loading}
                          size="lg"
                          startIcon={<MagicWand01 />}
                          onClick={matchPredictedQuantities}>
                          {t('matchPredictedQuantities')}
                        </Button>
                      </Grid>
                    }
                  </>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item
            xs={12}
            py={3}>
            {loading ? (
              <LoadingWheel />
            ) :
              <>
                <TableContainer>
                  <Table>
                    <TableHead
                      sx={{
                        pt: '10px',
                      }}>
                      <TableRow>
                        <TableCell
                          sx={{ fontWeight: 'bold' }}
                          align="center">
                          {t('itemDetails')}
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold' }}
                          align="left">
                          {t('basketQuantity')}
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold' }}
                          align="left">
                          {t('quantity')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredItems.map((item) => (
                        <ProductRow key={item.id}
                          product={item}
                          selectedOrderId={activeOrder?.id}
                          basketQuantity={getBasketQuantity(item.id)}
                          predictedItem={predictedDemands.find(x => x.itemNumber === item.sku)}
                          showPredictedQuantity={showPredictedQuantity}
                          onAddItemConfirmed={onAddItemConfirmed}
                          currencyCode={currencyCode}
                          showMessageWhenNoCost={showMessageWhenNoCost}
                          orderType={orderType}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          ref={(ref: any) => {
                            productRowRefs.current[item.id] = ref!;
                          }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer><CustomPagination
                  page={page}
                  setPage={setPage}
                  maxPages={totalPages}
                  breakpointForChangeDisplay={120000}
                ></CustomPagination>
              </>
            }
          </Grid>
        </Grid>
      </Grid>
    </Paper >
  );
};

export default Products;