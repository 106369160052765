import { Box, Grid } from '@mui/material';
import useNswagClient from '../../hooks/api/useNswagClient';
import { useContext, useState } from 'react';
import { GetStockResponse } from '../../app/services/api/generated';
import { UserContext } from '../../components/shared/useUser';
import { useTranslation } from 'react-i18next';
import StockOnHandBox from './components/StockOnHandBox';
import { StockFilterOptions } from './components/interfaces/StockFilterOptions';
import useLogError from '../../hooks/useLogError';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';

const StockOnHandPage = () => {
  const { t } = useTranslation('common');
  const { selectedStore } = useContext(UserContext);
  const { getStock } = useNswagClient();
  const [stockData, setStockData] = useState<GetStockResponse>({});
  const { logError } = useLogError();

  const loadFilterData = (options: StockFilterOptions = {}) => {
    const storeNumber = selectedStore?.storeNumber;
  
    if(options.storeFilters){
      getStock('', options.search, options.fieldToSort, options.sortDirection, options.skip, options.take, options.filters, options.categoryFilters, options.storeFilters, options.hasRecentActivity, options.isMadeInStore)
        .then((result) => {
          if (result?.data) {
            setStockData(result.data);
          }
        })
        .catch((error) => {
          logError(error);
        });
    }else if (storeNumber) {
      getStock(storeNumber, options.search, options.fieldToSort, options.sortDirection, options.skip, options.take, options.filters, options.categoryFilters, options.storeFilters, options.hasRecentActivity, options.isMadeInStore)
        .then((result) => {
          if (result?.data) {
            setStockData(result.data);
          }
        })
        .catch((error) => {
          logError(error);
        });
    } else {
      console.error('Selected store number is undefined');
    }
  };

  return (
    <Box display="flex"
      width="100%"
      height="100%"
      flexDirection="column" >
      <PageArea>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <PageTitle>{t('stockOnHand.title')}</PageTitle>
        </Grid>
        <Grid item
          xs={12}>
          <StockOnHandBox stockOnHands={stockData}
            loadData={loadFilterData}/>
        </Grid>
      </PageArea>
    </Box>
  );
};

export default StockOnHandPage;
