import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography,  TableCell, TableRow, TableContainer, Table, TableBody, DialogActions,  Box,  FormControlLabel, Checkbox, FormGroup, DialogContent, Dialog, DialogTitle, IconButton } from '@mui/material';
import { UserContext } from '../../../../components/shared/useUser';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import { GetItemsResponse, Item } from '../../../../app/services/api/generated';
import { SearchSm } from  '../../../../assets';
import CustomPagination from '../../../../components/forms/Pagination';
import { theme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import useLogError from '../../../../hooks/useLogError';
import { StockCountCreateContext, StockCountCreateValues } from '../../contexts/StockCountCreateContext';
import CloseIcon from '@mui/icons-material/Close';

const StockCountCreateItemsModal=() => {
  const { isItemsModalOpen,
    handleClose,
    handleItems,
  } = useContext(StockCountCreateContext) as StockCountCreateValues;

  const { selectedStore } = useContext(UserContext);
  const { getItems } = useNswagClient();
  const [data, setData] = useState<GetItemsResponse | undefined>();
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState<number | undefined>();
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [tempData, setTempData] = useState<Item[] | undefined>([]);
  const [ticked, setTicked] = useState(false);
  const { t } = useTranslation('createStockCount');
  const { logError } = useLogError();
  const madein = undefined;
  const stock = true;
  const product = undefined;
  const sale = undefined;
  const material = undefined;
  const activity = undefined;
  const isDailyWaste = undefined;
  const sort  = '';
  const direction = '';
  const addItems = undefined;

  useEffect(() => {
    if (buttonClicked) {
      getItemData();
    }
  }, [ page ]);

  useEffect(() => {
    if(isItemsModalOpen) {
      setSearch('');
      setData(undefined);
      setCount(0);
    }
  }, [isItemsModalOpen]);

  const getItemData = () => {
    const skip = rowsPerPage * page;
    const storeNumber = selectedStore?.storeNumber === undefined? '':selectedStore.storeNumber;
    getItems(search, storeNumber, activity, madein, undefined, stock, sale, product, material, isDailyWaste, sort, direction, skip, rowsPerPage, addItems).then((result) => {
      if (result ) {  
        const finaldata  = result.data;
        setData(finaldata);
        setCount(result.data?.itemCount);
      } else {
        logError(result);
      }})
      .catch((error) => {
        logError(error);
      });
  };
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSearch = () => {
    setPage(0);
    setButtonClicked(true);
    getItemData();
  };

  const addItemToTempList = (item : Item) => {
    if(!tempData?.some(e => e.id == item.id)) {
      setTempData([...tempData ?? [], item]);
    }
  };
  
  const handleConfirm = () => {
    setSearch('');
    setButtonClicked(false);
    handleItems(tempData || [], ticked);
    !ticked && setTempData([]);
  };
  
  const handleItemChange = (item: number, isChecked: boolean) => {
    if (isChecked) {      
      const selecteditem = data?.items?.find(row => row.id === item);
      if (selecteditem) {
        addItemToTempList(selecteditem);
      }
    } else {
      setTempData(prevData => prevData?.filter(row => row.id !== item) || []);
    }
  };

  const toggleCheckbox = () => {
    setTicked(!ticked);
  };

  const maxPages = () => count ? Math.ceil(count / rowsPerPage) : 0;

  return (
    <Dialog open={isItemsModalOpen}
      onClose={handleClose}
      maxWidth='md'
      fullWidth>
      <DialogTitle>
        {t('itemsLookup')}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.custom.gray[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Grid
            container
            spacing={2}
            display='flex'
            flexWrap={ 'nowrap'}
            alignItems='self-end'
            sx={{ borderTop:'1px solid', borderColor: theme.palette.custom.gray.main }}
          >
            <Grid item
              xs={10}
              sx={{ pt:'5px' }}>
              <TextField
                fullWidth
                placeholder='Search'
                id="input-with-icon-textfield"
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}
                value ={search}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid
              item
              xs ={2}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
              <Button  
                size='lg'
                onClick={handleSearch}><SearchSm/></Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ pt:'10px' }}>
          <Typography 
            variant ='textSM'
            sx ={{ color:theme.palette.custom.gray[500] }}> {t('search')}</Typography> 
        </Box>
        <Box sx={{ width: '100%', textAlign: 'left', border: '1px solid' }}>
          <TableContainer >
            <Table>
              <TableBody>
                {data?.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ display: 'flex', alignItems: 'center' }} >
                      <Checkbox
                        color="primary"
                        checked={ tempData?.some((item) => item.id === row.id)}
                        onChange={(e) => handleItemChange(row.id!, e.target.checked)}
                      />
                      <Grid style={{ marginLeft: '8px' }}>
                        {row.itemNumber}< br/>
                        {row.description}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination 
            page={page}
            setPage={setPage}
            maxPages={maxPages()}
            breakpointForChangeDisplay={120000} />
        </Box>
        <FormGroup>
          <FormControlLabel 
            control={<Checkbox />}
            label={t('tickToContinue')} 
            checked={ticked}
            onChange={toggleCheckbox}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          size='lg'
          onClick={handleClose}>{t('cancel')}</Button>
        <Button
          size='lg'
          onClick={handleConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockCountCreateItemsModal;