/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link, Button, Grid, TextField, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useNswagClient from '../../../hooks/api/useNswagClient';

const ActivateAccount = (props: { switchDisplay: () => void }) => {
  const { t } = useTranslation('authentication');
  const location = useLocation();
  const { activateUser } = useNswagClient();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setToken(queryParams.get('accessToken'));
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', `"${token}"`);
    }
  }, [token]);

  const handlePassword1Change = (e: any) => {
    setPassword1(e.target.value);
    setPasswordError('');
  };

  const handlePassword2Change = (e: any) => {
    setPassword2(e.target.value);
    setPasswordError('');
  };

  const isFormValid = () => {
    if (!password1) {
      setPasswordError(t('error_messages.password_required'));
      return false;
    }

    if (password1.length < 8) {
      setPasswordError(t('error_messages.password_min_length'));
      return false;
    }

    if (password1 !== password2) {
      setPasswordError(t('error_messages.passwords_must_match'));
      return false;
    }

    const hasUppercase = /[A-Z]/.test(password1);
    const hasLowercase = /[a-z]/.test(password1);
    const hasNumber = /\d/.test(password1);

    if (!hasUppercase || !hasLowercase || !hasNumber) {
      setPasswordError(t('error_messages.password_complexity'));
      return false;
    }

    return true;
  };

  const sendActivateUserRequest = () => {
    setIsLoading(true);
    activateUser({ password: password1 })
      .then(() => {
        setShowMessage((showMessage) => !showMessage);
      })
      .catch((error) => {
        setPasswordError(t('token_expired_message'));
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isFormValid()) {
      sendActivateUserRequest();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', width: '360px' }}>
      <Typography variant="displaySM"
        sx={{ textAlign: 'start' }}>
        {t('account_activation_header')}
      </Typography>
      {!showMessage && <Box>
        <Typography
          variant="textMD"
          sx={{ textAlign: 'start', pt: '12px' }}>
          {t('password_creation_instructions')}
        </Typography>
        <Typography
          variant="textMD"
          sx={{ textAlign: 'start', mb: 2, ml: 1 }}>
          {t('password_requirements')}
        </Typography>
        <TextField
          sx={{ pt: '12px' }}
          value={password1}
          onChange={handlePassword1Change}
          variant="outlined"
          placeholder='Password'
          type='password'
          fullWidth
          required />
        <TextField
          sx={{ pt: '12px' }}
          value={password2}
          onChange={handlePassword2Change}
          variant="outlined"
          type='password'
          placeholder='Confirm Password'
          error={!!passwordError}
          helperText={passwordError}
          fullWidth
          required />
        <Grid sx={{ pt: '12px' }}>
          <Button
            type='submit'
            variant="primary"
            fullWidth
            disabled={isLoading}
            onClick={handleSubmit}>
            {t('activate_btn')}
          </Button>
        </Grid>
      </Box>}

      {showMessage && <Box>
        <Typography
          variant="textMD"
          sx={{ textAlign: 'start', pt: '12px' }}>
          {t('account_activation_complete_header')}
        </Typography>
      </Box>}

      <Link sx={{ textDecoration: 'none', pt: '12px' }}
        onClick={props.switchDisplay}
        href="#" >
        {t('forgotPasswordReturnToLogin')}
      </Link>
    </Box>
  );
};

export default ActivateAccount;