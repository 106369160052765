import React, { ChangeEvent, createContext, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { TFunction } from 'i18next';
import { useStockCountCreate } from '../hooks/useStockCountCreate';
import { User, Item, PrimaryCategoryStockCount, StockCheckItem } from '../../../app/services/api/generated';
import { Blocker } from 'react-router-dom';

export interface StockCountCreateValues {
    t: TFunction<'createStockCount', undefined>,
    isCategoriesModalOpen: boolean,
    isItemsModalOpen: boolean,
    isBulkModalOpen: boolean,
    hasCategories: boolean,
    isCategoryDeleteModalOpen: boolean,
    isDeleteModalOpen: boolean,
    categoryId: number | undefined,
    itemNumber: number | undefined,
    hasItems: boolean,
    isMobile: boolean,
    allUsers: User[],
    handleSmartCountChange: (event: SelectChangeEvent) => void,
    handleQtyChange: (id: number, newValue: string) => void,
    handleValueChange: (id: number, newValue: string) => void,
    handleLinkClick: () => void,
    handleDateChange: (event: ChangeEvent<HTMLInputElement>) => void,
    handleUserSelect: (event: SelectChangeEvent<string>) => void,
    handleSelectChange: (event: SelectChangeEvent<number>) => void,
    generateTimeOptions: () => { value: number; label: string; }[],
    handleInputValue: (event: ChangeEvent<HTMLInputElement>) => void,
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
    submitStock: () => void,
    handleClickBulkClick: () => void,
    handleClick: () => void,
    deleteItem: () => void,
    deleteCategory: () => void,
    handleClickOpen: () => void,
    handleClose: () => void,
    handleBulkItems: (data: Item[]) => void,
    handleItems: (data: Item[], ticked: boolean) => void,
    deleteItemRow: (id: number) => void,
    deleteCategoryRow: (id: number) => void,
    deleteItems: (data: Item[]) => void,
    deleteCategories: (data: PrimaryCategoryStockCount[]) => void,
    handleCategories: (data: PrimaryCategoryStockCount[]) => void,
    handleSliderChange: (event: Event, newValue: number | number[]) => void,
    handleIsTimeLockedChanged: (event: SelectChangeEvent) => void,
    name: string,
    selectedDate: string,
    today: string,
    selectedUser: string,
    approvalValue: number | undefined,
    selectedTime: number | undefined,
    isTimeLocked: boolean,
    sliderValue: number,
    categoryData: PrimaryCategoryStockCount[],
    convertedItems: StockCheckItem[],
    itemsData: Item[],
    closeSnackBar: () => void,
    isSnackBarOpen: boolean,
    snackBarSeverity: 'error' | 'warning' | 'info' | 'success',
    snackbarMessage: string,
    snackBarDuration: number | null,
    isSmartCount: boolean,
    blocker: Blocker,
    handleLeavePageModalClose: () => void,
    handleLeavePageModalConfirm: () => void,
    isSubmitting: boolean,
    currencySymbol: string,
}

export const StockCountCreateContext = createContext<null | StockCountCreateValues>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export const StockCountCreateProvider = ({ children }: ProviderProps) => {
  const {
    t,
    isCategoriesModalOpen,
    isItemsModalOpen,
    isBulkModalOpen,
    hasCategories,
    isCategoryDeleteModalOpen,
    isDeleteModalOpen,
    categoryId,
    itemNumber,
    hasItems,
    isMobile,
    allUsers,
    handleSmartCountChange,
    handleQtyChange,
    handleValueChange,
    handleLinkClick,
    handleDateChange,
    handleUserSelect,
    handleSelectChange,
    generateTimeOptions,
    handleInputValue,
    handleInputChange,
    submitStock,
    handleClickBulkClick,
    handleClick,
    deleteItem,
    deleteCategory,
    handleClickOpen,
    handleClose,
    handleBulkItems,
    handleItems,
    deleteItemRow,
    deleteCategoryRow,
    deleteItems,
    deleteCategories,
    handleCategories,
    handleSliderChange,
    handleIsTimeLockedChanged,
    name,
    selectedDate,
    today,
    selectedUser,
    approvalValue,
    selectedTime,
    isTimeLocked,
    sliderValue,
    categoryData,
    convertedItems,
    itemsData,
    closeSnackBar,
    isSnackBarOpen,
    snackBarSeverity,
    snackbarMessage,
    snackBarDuration,
    isSmartCount,
    blocker,
    handleLeavePageModalClose,
    handleLeavePageModalConfirm,
    isSubmitting,
    currencySymbol,
  } = useStockCountCreate();

  const passedValue = {
    t,
    isCategoriesModalOpen,
    isItemsModalOpen,
    isBulkModalOpen,
    hasCategories,
    isCategoryDeleteModalOpen,
    isDeleteModalOpen,
    categoryId,
    itemNumber,
    hasItems,
    isMobile,
    allUsers,
    handleSmartCountChange,
    handleQtyChange,
    handleValueChange,
    handleLinkClick,
    handleDateChange,
    handleUserSelect,
    handleSelectChange,
    generateTimeOptions,
    handleInputValue,
    handleInputChange,
    submitStock,
    handleClickBulkClick,
    handleClick,
    deleteItem,
    deleteCategory,
    handleClickOpen,
    handleClose,
    handleBulkItems,
    handleItems,
    deleteItemRow,
    deleteCategoryRow,
    deleteItems,
    deleteCategories,
    handleCategories,
    handleSliderChange,
    handleIsTimeLockedChanged,
    name,
    selectedDate,
    today,
    selectedUser,
    approvalValue,
    selectedTime,
    isTimeLocked,
    sliderValue,
    categoryData,
    convertedItems,
    itemsData,
    closeSnackBar,
    isSnackBarOpen,
    snackBarSeverity,
    snackbarMessage,
    snackBarDuration,
    isSmartCount,
    blocker,
    handleLeavePageModalClose,
    handleLeavePageModalConfirm,
    isSubmitting,
    currencySymbol,
  };
  
  const fireBaseProviderValue = useMemo(() => ({ passedValue }), [passedValue]);

  return (
    <StockCountCreateContext.Provider value={fireBaseProviderValue.passedValue}>
      {children}
    </StockCountCreateContext.Provider>
  );
};