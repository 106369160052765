import { Alert, Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import { ChangeEvent, FormEvent, SyntheticEvent, useContext, useState } from 'react';
import useNswagClient from './../../../../hooks/api/useNswagClient';
import { DcShortageReportRequest, Store } from './../../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { formatDateForReport } from './../../../../utils';
import { UserContext } from './../../../../components/shared/useUser';
import dayjs from 'dayjs';

type IProps = {
  onClose?: () => void;
}

const DcShortageReportForm = (props: IProps) => {
  const { user } = useContext(UserContext);
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [date, setDate] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);
  const [checkedAllStores, setCheckedAllStores] = useState<boolean>(false);
  const { runDcShortageReport } = useNswagClient();
  const { t } = useTranslation('common');

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectedStores([]);
    setCheckedAllStores(checked);
  };

  const getMaxDate = () => {
    const today = dayjs().format('YYYY-MM-DD');
    return today;
  };

  const getMinDate = () => {
    const minDate = new Date('1900-01-01').toISOString().split('T')[0];
    return minDate;
  };

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const handleSelectChange = (event: SyntheticEvent, value: Store[]) => {
    setSelectedStores(value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsGenerating(true);
    setErrorMessage('');
    setSuccessMessage('');

    const reportDate = dayjs(date).startOf('day').toDate();
    const selectedAllStores = checkedAllStores || (selectedStores.length > 0 && selectedStores.length == user?.stores?.length);

    const request: DcShortageReportRequest = {
      reportOnAllAvailableStores: selectedAllStores,
      stores: selectedAllStores ? undefined : selectedStores.map(x => x.storeNumber as string),
      date: formatDateForReport(reportDate),
    };

    runDcShortageReport(request).then(() => {
      setSuccessMessage(t('reporting.generic.post-generation-message'));
      setIsRequestSubmitted(true);
    }).catch((error) => {
      if (typeof(error) === 'string') {
        setErrorMessage(error);
      }
      console.log(error);
    }).finally(() => {
      setIsGenerating(false);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container
        spacing={10}
        sx={{ pt: '10px' }}>
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              options={user?.stores ?? []}
              value={selectedStores}
              onChange={handleSelectChange}
              disabled={checkedAllStores}
              getOptionKey={(option) => option.storeNumber ?? ''}
              getOptionLabel={(option) => option.storeName ?? ''}
              renderInput={(params) => (
                <TextField {...params}
                  required={selectedStores.length === 0 && !checkedAllStores}
                  label={t('reporting.select-stores')}
                  placeholder={t('reporting.select-stores')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormGroup style={{ textAlign: 'right' }}>
            <FormControlLabel
              control={<Checkbox
                checked={checkedAllStores}
                onChange={handleSelectAllChange} />}
              label={t('reporting.all-stores')}
            />
          </FormGroup>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <TextField
            label={t('date')}
            type="date"
            required
            value={date}
            onChange={handleDateChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: getMinDate(), max: getMaxDate() } }}
            fullWidth
          />
        </Grid>
        {
          errorMessage && 
          <Grid item
            xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        }
        {
          successMessage && 
          <Grid item
            xs={12}>
            <Alert severity="success">{successMessage}</Alert>
          </Grid>
        }
        <Grid item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', columnGap: '10px' }}>
          <Button size='lg'
            variant='secondary' 
            onClick={props.onClose}>{t('cancel')}</Button>
          <Button size='lg'
            disabled={isGenerating || isRequestSubmitted}
            type='submit'>{t('reporting.generate')}</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DcShortageReportForm;