import { Box, useTheme, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DrawerContext, DrawerValues } from '../contexts/DrawerContext';
import { useContext } from 'react';

export const Header = () => {
  const theme = useTheme();
  const { isMobile, setOpen } = useContext(DrawerContext) as DrawerValues;

  return (
    <Box
      sx={{ 
        
        paddingTop:'32px',
        paddingBottom:'26px',
        paddingLeft:'8px',
      }}
    >
      <img alt='logo'
        src={'/logo-reverse.svg'}
        height='26px' />

      {isMobile && 
      <IconButton onClick={() => setOpen(false)}
        style={{ position: 'absolute', right: '4px', top: '4px', color: theme.palette.primary[300] }}>
        <CloseIcon />
      </IconButton>
      }
    </Box >);
};