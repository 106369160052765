
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { theme } from '../../../../theme';
import { DrawerContext, DrawerValues } from '../contexts/DrawerContext';
import { DrawerNavItem } from '../interfaces/DrawerNavItem';

export const NavItem = (props: { item: DrawerNavItem, onClick: (() => void) | undefined }) => {
  const { t } = useTranslation('drawer');
  const { pathname, search } = useLocation();
  const { isHighLighted, selectedNavItem, isMobile } = useContext(DrawerContext) as DrawerValues;
  const showAsHighlighted = isHighLighted(props.item, pathname, search);
  const selectedNavItemToUse = isMobile ? selectedNavItem : undefined;
  const isCurrentSelected = props.item.translationKey === selectedNavItemToUse?.translationKey;

  const colourToUse = () => {
    if (showAsHighlighted && !isMobile) return theme.palette.primary[400];
    if (isCurrentSelected && !isMobile) return theme.palette.primary[500];
    return 'transparent';
  };
  const unSelectedColor = theme.palette.primary.contrastText == '#000000' ? theme.palette.primary[900] : theme.palette.primary[100];

  if (!props.item.canRender) {
    return <></>;
  }

  return (
    <ListItem
      sx={{
        flexDirection: 'column',
        alignItems: 'stretch',
        borderRadius: '4px',
        p: 0,
        backgroundColor: colourToUse(),
      }}
    >
      <ListItemButton onClick={props.onClick}
        sx={{
          borderRadius: '4px',
          height: '40px',
          display: 'flex',
          padding: '8px 12px',
          flexDirection: 'row',
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            fontSize: 8,
            height: '24px',
            color: `${showAsHighlighted ? 'common.white' : 'primary.light'}`,
          }}
        >
          {props.item.variant == 'customIcon' ? props.item.icon : <SvgIcon>{props.item.icon}</SvgIcon>}
        </ListItemIcon>
        <ListItemText
          primary={t(props.item.translationKey ?? '')}
          primaryTypographyProps={{
            color: `${showAsHighlighted ? theme.palette.common.white : theme.palette.primary.contrastText}`,
            fontSize: 16,
            height: '24px',
            fontWeight: 600,
            pl: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};