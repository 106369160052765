import { Box, Typography, Stack, Tooltip, useTheme } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../../../shared/useUser';
import { useSelectedBranding } from '../../../../../hooks/useSelectedBranding';

const textClamp = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};

export const StoreDisplay = () => {
  const theme = useTheme();
  const branding = useSelectedBranding();
  const { selectedStore } = useContext(UserContext);

  const storeNumber = selectedStore?.starbucksStoreNumber || '';
  const franchiseName = selectedStore?.franchiseName;
  let storeName = selectedStore?.storeName || '';

  if(storeName.indexOf('@') > -1){
    const storeNameParts = storeName.split('@');

    storeName = storeNameParts[1];
  }else{
    storeName = storeName.replace(storeNumber, '');
  }

  storeName = storeName.replace(/^[\W]+/, '');

  return (<Box
    display='flex'
    marginBottom={'16px'}
    marginTop={'16px'}
    marginLeft={'6px'}
    marginRight={'6px'}
    alignItems='stretch'
  >
    <img src={`${branding.logoImage}`}
      height='40px'
      alt='avatar' />
    <Tooltip title={selectedStore ? selectedStore.storeName : 'No store selected'}>
      <Stack
        textAlign='left'
        marginLeft={'8px'}
        justifyContent='space-between'
      >
        <Typography
          color={theme.palette.primary.contrastText}
          variant='textSM'
          sx={{
            ...textClamp,
          }}>
          {selectedStore ? `${storeNumber} - ${franchiseName}` : 'No store selected'}
        </Typography>
        <Typography
          color={theme.palette.primary.contrastText}
          variant='textXS'
          sx={{
            fontWeight:'300',
            ...textClamp,
          }}
        >
          {selectedStore ? storeName : 'No location'}
        </Typography>
      </Stack>
    </Tooltip>
    
  </Box>);
};
