import { Grid, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import { RecipeComponent } from '../../../app/services/api/generated';
import { FC, useState } from 'react';
import { Trash01 } from '../../../assets';
import DeleteRecipeComponentModal from './DeleteRecipeComponentModal';

type Props = {
  item: RecipeComponent;
  index: number;
  handleRecipeComponentChanges: (index: number, field: 'weight' | 'quantity' | 'volume', value: string) => void;
  onRemoveComponent: (index: number) => void;
  isFormValid: boolean;
};

const RecipeComponentRow: FC<Props> = ({ item, index, handleRecipeComponentChanges, onRemoveComponent, isFormValid }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tempValues, setTempValues] = useState<Record<string, string | number>>({});

  const handleRemoveComponent = () => {
    setIsOpen(true);
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    if (isNaN(Number(value))) {
      value = '0';
    }

    setTempValues((prev) => ({
      ...prev,
      [`${index}-${field}`]: value,
    }));

    handleRecipeComponentChanges(index, field as 'weight' | 'quantity' | 'volume', value);
  };

  const removeTempValues = () => {
    setTempValues({});
  };

  return (
    <>
      <Grid
        container
        display='flex'
        flex={1}
        flexDirection='row'
        alignItems='center'
        spacing={6}
        mb={1}
      >
        <Grid item
          flex={1}>
          <Typography
            variant="textMD">{item.componentItemNumber}</Typography>
        </Grid>
        <Grid item
          flex={2.5}>
          <Typography
            variant="textMD">{item.componentDescription}({ item.componentWeightNet } GRM)</Typography>
        </Grid>
        <Grid item
          flex={1}>
          <TextField
            placeholder='0'
            value={tempValues[`${index}-weight`] ?? item.weight}
            onChange={(e) => handleInputChange(index, 'weight', e.target.value)}
            onBlur={removeTempValues}
            size='small'
            error={!isFormValid && item.weight === 0}
            type="number"
            inputProps={{
              step: '0.01',
              min: '0',
            }}
          />
        </Grid>
        <Grid item
          flex={1}>
          <TextField
            placeholder='0'
            value={tempValues[`${index}-quantity`] ?? item.quantity}
            onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
            onBlur={removeTempValues}
            InputProps={{
              endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
            }}
            size='small'
            error={!isFormValid && item.quantity === 0}
            type="number"
            inputProps={{
              step: '0.01',
              min: '0',
            }}
          />
        </Grid>
        <Grid item
          flex={1}>
          <TextField
            placeholder='0'
            disabled={(item?.componentVolume || 0) <= 0}
            value={tempValues[`${index}-volume`] ?? item.volume}
            onChange={(e) => handleInputChange(index, 'volume', e.target.value)}
            onBlur={removeTempValues}
            size='small' />
        </Grid>
        <Grid item
          flex={0.5}>
          <SvgIcon onClick={handleRemoveComponent}
            color='error'
            sx={{ cursor: 'pointer' }}>
            <Trash01 />
          </SvgIcon>
        </Grid>
      </Grid>
      <DeleteRecipeComponentModal
        open={isOpen}
        onClose={handleOnClose}
        index={index}
        item={item}
        onRemoveComponent={onRemoveComponent}
      />
    </>
  );
};

export default RecipeComponentRow;