import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { IconButton, SvgIcon, TableSortLabel, Typography } from '@mui/material';
import { PagedData_1OfWasteHeaders, WasteHeaders } from '../../../app/services/api/generated';
import { useNavigate } from 'react-router-dom';
import { formatDateHourString } from '../../../utils';
import { Trash01 } from '../../../assets';
import { useEffect } from 'react';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import RoundAndFormatValue from '../../../components/shared/RoundAndFormatValue';
import { WasteChecksheetTab } from '../enums/WasteChecksheetTab';
import WasteChecksheetDeleteDialog from './WasteChecksheetDeleteDialog';
import useNswagClient from '../../../hooks/api/useNswagClient';
import useLogError from '../../../hooks/useLogError';

export default function WasteChecksheetTable(props: {
  readonly list: PagedData_1OfWasteHeaders, readonly setPageZero: number, readonly activeTab: WasteChecksheetTab;
  readonly loadData: (skip: number, take: number) => void, readonly currencyCode?: string
}) {
  const { t } = useTranslation('wasteChecksheet');
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);

  const [data, setData] = React.useState(props.list.data);
  const rowsPerPage = 10;
  const maxPages = props.list?.totalCount
    ? Math.max(1, Math.ceil(props.list.totalCount / rowsPerPage))
    : 1;

  //Header sort
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('createdDate');
  const [loading, setLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(-1);
  const { deleteWaste } = useNswagClient();
  const { logError } = useLogError();

  const comparator = (property: keyof WasteHeaders) => (a: WasteHeaders, b: WasteHeaders): number => {
    const isAsc = orderBy === property && order === 'asc';
    if (typeof a[property] === 'string' || a[property] instanceof Date) {
      return isAsc
        ? String(a[property]).localeCompare(String(b[property]))
        : String(b[property]).localeCompare(String(a[property]));
    } else {
      return isAsc ? Number(a[property]) - Number(b[property]) : Number(b[property]) - Number(a[property]);
    }
  };

  const toSort = (arr: WasteHeaders[], compareFn: (a: WasteHeaders, b: WasteHeaders) => number): WasteHeaders[] => {
    return arr.slice().sort(compareFn);
  };
  
  const handleSort = (property: keyof WasteHeaders ) => {
    const isAsc = orderBy === property && order === 'asc';
    if(data){
      const sortedData = toSort(data, comparator(property));
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setData(sortedData);
    }
  };

  useEffect(() => {
    setData(props.list.data);
    setLoading(false);

  }, [props.list]);

  useEffect(() => {
    setLoading(true);
    props.loadData(page * rowsPerPage, rowsPerPage);
  }, [page]);

  useEffect(() => {
    setPage(0);
  }, [props.setPageZero]);

  const deleteButtonHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    event.stopPropagation();
    setDeleteId(id);
    setOpenModal(true);
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  const handleConfirmModal = async () => {
    deleteWaste(deleteId).catch((error) => {
      logError(error);
    }).finally(() => {
      setOpenModal(false);
      if(page !== 0){
        setPage(0);
      }else{
        setLoading(true);
        props.loadData(page * rowsPerPage, rowsPerPage);
      }
    });
  };

  return (
    <> {loading ? <LoadingWheel /> : (
      data &&
      <>
        <TableContainer component={'div'}>
          <Table>
            <TableHead sx={{ paddingTop: '10px', backgroundColor: theme.palette.custom.gray[200] }}>
              <TableRow>
                <TableCell
                  align="left"
                >
                  <TableSortLabel
                    active={orderBy === 'createdDate'}
                    direction={order}
                    onClick={() => handleSort('createdDate')}
                  >
                    {t('list.createdDate')}
                  </TableSortLabel>
                </TableCell>
                {
                  props.activeTab === WasteChecksheetTab.ARCHIVED &&
                <><TableCell
                  align="left"
                >
                  <TableSortLabel
                    active={orderBy === 'submittedDate'}
                    direction={order}
                    onClick={() => handleSort('submittedDate')}
                  >
                    {t('list.submittedDate')}
                  </TableSortLabel>
                </TableCell><TableCell
                  align="left"
                >
                  <TableSortLabel
                    active={orderBy === 'submittedBy'}
                    direction={order}
                    onClick={() => handleSort('submittedBy')}
                  >
                    {t('list.submittedBy')}
                  </TableSortLabel>
                </TableCell></>
                }
                <TableCell
                  align="left"
                >
                  <TableSortLabel
                    active={orderBy === 'totalWaste'}
                    direction={order}
                    onClick={() => handleSort('totalWaste')}
                  >
                    {t('list.totalWaste')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                >
                  <TableSortLabel
                    active={orderBy === 'totalCost'}
                    direction={order}
                    onClick={() => handleSort('totalCost')}
                  >
                    {t('list.totalCost')}
                  </TableSortLabel>
                </TableCell>
                {props.activeTab === WasteChecksheetTab.ACTIVE &&

                <TableCell
                  align="left"
                >
                  {t('list.delete')}

                </TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/store/daily-waste-checksheet/${row.id}`)}
                >
                  <TableCell>
                    { row.createdDate ? formatDateHourString(row.createdDate) : ''}
                  </TableCell>
                  {
                    props.activeTab === WasteChecksheetTab.ARCHIVED && (
                      <><TableCell>
                        { row.submittedDate ? formatDateHourString(row.submittedDate) : ''}
                      </TableCell>
                      <TableCell>
                        {row.submittedBy}
                      </TableCell></>
                    )
                  }
                  <TableCell>
                    {row.totalWaste}
                  </TableCell>
                  <TableCell>
                    <RoundAndFormatValue value={row.totalCost ?? 0}
                      currencyCode={props.currencyCode} />
                  </TableCell>
                  { props.activeTab === WasteChecksheetTab.ACTIVE &&
                    <TableCell
                      align="left"
                    >
                      <IconButton
                        size='small'
                        onClick={(event) => deleteButtonHandler(event, row.id ?? -1)}>
                        <SvgIcon style={{ color: theme.palette.error[500] }}><Trash01></Trash01></SvgIcon>
                      </IconButton>
                    </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {props.list?.totalCount === 0 ? (
          <Typography variant="textSM">
            {t('noData')}
          </Typography>
        ) : (
          <CustomPagination
            page={page}
            setPage={setPage}
            maxPages={maxPages}
            breakpointForChangeDisplay={120000}
          />
        )}
        <WasteChecksheetDeleteDialog open={openModal}
          onClose={handleCancelModal}
          onConfirm={handleConfirmModal}
        ></WasteChecksheetDeleteDialog>
      </>
    )}
    </>
  );
}


