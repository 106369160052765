import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { getFormattedDate } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { RAGRecord } from '../types/RAGRecord';

export default function DenseTable(props: { 
  readonly list: RAGRecord[];
}) {
  const { t } = useTranslation('common');
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  const rows = props.list;

  const maxPages = () => {
    return Math.ceil(rows.length / rowsPerPage);
  };

  const visibleRows = () => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  return (
    <>
      <TableContainer component={'div'}>
        <Table
          size="small"
          aria-label="a table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {t('date')}
              </TableCell>
              <TableCell align="left">
                {t('store')}
              </TableCell>
              {visibleRows().some(row => row.orderNumber || row.orderType) && (
                <>
                  <TableCell>
                    {t('orderNumber')}
                  </TableCell>
                  <TableCell>
                    {t('purchaseOrderReceipt.orderType')}
                  </TableCell>
                </>
              )} 
              {visibleRows().some(row => row.name) && (
                <TableCell>
                  {t('stockCount')}
                </TableCell>
              )} 
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows().map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  {(!row.itemDate ? '' : getFormattedDate(new Date(row.itemDate)))}
                </TableCell>
                <TableCell align="left">
                  {row.storeName}
                </TableCell>
                {visibleRows().some(r => r.orderNumber || r.orderType) && (
                  <>
                    <TableCell>
                      {row.orderNumber ?? ''}
                    </TableCell>
                    <TableCell>
                      {row.orderType ?? ''}
                    </TableCell>
                  </>
                )}  
                {visibleRows().some(row => row.name) && (
                  <TableCell>
                    {row.name ?? ''}
                  </TableCell>
                )} 
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        maxPages={maxPages()}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>
    </>
  );
}
