import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog, DialogTitle, IconButton, DialogContent, 
  Table, TableBody, TableCell, TableHead, TableRow, 
  TableContainer, 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../../../components/shared/useUser';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import CustomPagination from '../../../components/forms/Pagination';
import { client } from '../../../app/services/api/orderManagementClient';
import { useTranslation } from 'react-i18next';

interface OrderAuditModalProps {
  currentOrderId: number;
  open: boolean;
  onClose: () => void;
}

const OrderAuditModal: React.FC<OrderAuditModalProps> = ({ currentOrderId,  open, onClose }) => {

  const { selectedStore } = useContext(UserContext);
  const { t } = useTranslation('orderDetails');

  const [loading, setLoading] = useState(false);

  const [headers, setHeaders] = useState<string[]>([]);
  const [values, setValues] = useState<string[][]>([]);

  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (open) {
      fetchOrders();
    }
  }, [open, selectedStore]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await client.getOrderAudit(selectedStore?.storeNumber, currentOrderId);

      const headers : string[] = [];
      let prevValue: string = '';

      for(let i = 0; i < response.data.length; i++){
        if(prevValue.startsWith('<th')){
          headers.push(response.data[i] ?? '');
        }

        prevValue = response.data[i] ?? '';
      }

      const rows: string[][] = [];
      let row :string[] = [];
      prevValue = '';

      for(let i = 0; i < response.data.length; i++){
        if(prevValue.startsWith('<td')){
          row.push((response.data[i] ?? '').replace(/<\/tr>/g, '\n').replace(/<[/a-zA-Z0-9\-" ;#:=]*>/g, ' '));
        }

        if(row.length == headers.length){
          rows.push(row);
          row = [];
        }

        prevValue = response.data[i] ?? '';
      }

      setHeaders(headers);
      setValues(rows);
      setTotalPages(Math.ceil(rows.length / pageSize));
      setPage(0);
    } catch (error) {
      console.error('Error fetching audit:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async () => {
    onClose();
  };

  return (
    <Dialog open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth>
      <DialogTitle>
        {t('orderAudit')}
        <IconButton onClick={handleClose}
          style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingWheel />
        ) : (
          <TableContainer component={'div'}>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map(h => <TableCell key={h}>{h}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.slice(pageSize * page, pageSize * page + pageSize).map((r, idx) => (
                  <TableRow key={idx}>
                    {r.map((value, idx) => (<TableCell key={idx}>{value.split('\n').map((x, idx, arr)=> <>{x}{idx < arr.length -1 ?<br/> : null}</>)}</TableCell>))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <CustomPagination
              page={page}
              setPage={setPage}
              maxPages={totalPages}
              breakpointForChangeDisplay={120000}
            />
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OrderAuditModal;
