import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { Trash01, X } from '../../../../assets';
import { useTranslation } from 'react-i18next';

export interface Reasons  {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  name: string | undefined;
 }

const RemoveItemConfirm=( props: Reasons) => {
  const { t } = useTranslation('orderDetails');

  return (
    <Dialog open={props.open}
      onClose={props.onClose}
      maxWidth='md'
      fullWidth>
      <Grid 
        sx={{ m:'2px' }}>
        <Grid
          sx={{ display:'flex',alignItems:'flex-end', justifyContent:'flex-end' }}>
          <X  onClick={props.onClose} />
        </Grid>
        <Grid 
          sx={{ display:'flex',alignItems:'center', justifyContent:'center' }}>
          <Typography variant='textXL'> {t('deleteModalTitle')}</Typography>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid sx={{ pt:'10px', display:'flex', justifyContent:'center',alignContent:'center' }}>
          <Typography variant='textMD'> {t('deleteMultiModalMessage')}</Typography>
        </Grid>
        <Grid sx={{ pt:'10px', display:'flex', justifyContent:'center',alignContent:'center' }}>
          <Typography variant='textMD'
            sx={{ fontWeight:'bold' }}> {props.name}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions >
        <Button 
          variant="secondary"
          onClick={props.onClose}> {t('cancel')}</Button>
        <Button onClick={props.onConfirm}
          variant="bad"
        ><Trash01 />
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog> 
  );
};

export default RemoveItemConfirm;