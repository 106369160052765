import useNswagClient from './../../hooks/api/useNswagClient';
import { UserContext } from './../../components/shared/useUser';
import { theme } from './../../theme';
import useLogError from './../../hooks/useLogError';
import { ChangeEvent, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Franchise, Store } from './../../app/services/api/generated';
import { compare } from './../../utils';
import { PodConfigurationModel, podClient } from './../../app/services/api/podClient';
import PageArea from './../../components/shared/PageArea';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, FormControl, Grid, InputAdornment, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import PageTitle from './../../components/shared/PageTitle';
import LoadingWheel from './../../components/ui/LoadingWheel';
import { ConfigurationLevel } from './enums/ConfigurationLevel';
import { ExpandMore } from '@mui/icons-material';
import CustomPagination from './../../components/forms/Pagination';
import { mergeArrays } from './helpers/ArrayHelper';
import StoreConfigurationsList from './components/StoreConfigurationsList';
import MessagesSnackbar from './../../components/shared/MessagesSnackbar';
import { SearchSm } from './../../assets';
import FranchiseConfigurationsList from './components/FranchiseConfigurationsList';
import { Item, ItemFilterParams, client } from './../../app/services/api/orderManagementClient';
import PodConfigurationInfo from './components/modals/PodConfigurationInfo';

const ManagePodConfigurations = () => {
  const { t } = useTranslation('managePodConfigurations');
  const { user, isAdmin } = useContext(UserContext);
  const { logError } = useLogError();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const rowsPerPage = 10;
  const [search, setSearch] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [allFranchises, setAllFranchises] = useState<Franchise[]>([]);
  const [selectedFranchise, setSelectedFranchise] = useState<Franchise | null>(null);
  const [allStores, setAllStores] = useState<Store[]>([]);
  const [allItems, setAllItems] = useState<Item[]>([]);
  const [allConfigurations, setAllConfigurations] = useState<PodConfigurationModel[]>([]);
  const [existingConfigurations, setExistingConfigurations] = useState<PodConfigurationModel[]>([]);
  const [existingStoreConfigurations, setExistingStoreConfigurations] = useState<PodConfigurationModel[]>([]);
  const [availableConfigs, setAvailableConfigs] = useState<PodConfigurationModel[]>([]);
  const [pickedStore, setPickedStore] = useState<Store | null>(null);
  const [configurationLevel, setConfigurationLevel] = useState<ConfigurationLevel | null>(null);
  const [selectedConfig, setSelectedConfig] = useState<PodConfigurationModel | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isPodInfoModalOpen, setIsPodInfoModalOpen] = useState<boolean>(false);

  const { getFranchises, getFranchiseStores } = useNswagClient();

  useEffect(() => {
    loadFranchises();
  }, []);

  useEffect(() => {
    loadStores();
  }, [selectedFranchise]);

  useEffect(() => {
    getConfigurationLevel();
    setPage(0);
  }, [selectedFranchise, pickedStore]);

  useEffect(() => {
    setAvailableConfigs(getAvailableConfigsFromItems(configurationLevel));
  },[configurationLevel, allFranchises, allStores, allItems]);

  useEffect(() => {
    loadItems(page, search);
  }, [selectedFranchise, pickedStore, page]);

  useEffect(() => {
    const configs = mergeArrays(availableConfigs, existingConfigurations);
    setAllConfigurations(configs ?? []);
  }, [availableConfigs, existingConfigurations]);

  const getConfigurationLevel = () => {
    let configLevel = ConfigurationLevel.Global;

    if (selectedFranchise) {
      configLevel = ConfigurationLevel.Franchise;
    }

    if (pickedStore) {
      configLevel = ConfigurationLevel.Store;
    }

    setConfigurationLevel(configLevel);
    loadConfigurations(configLevel);
  };

  const loadFranchises = () => {
    if (!isAdmin()) {
      const userFranchise: Franchise = {
        franchiseName: user?.franchiseName ?? '',
      };
      setAllFranchises([userFranchise]);
      setSelectedFranchise(userFranchise);
      return;
    }
    setIsLoading(true);
    getFranchises().then(result => {
      if (result.data) {
        setAllFranchises(result.data.sort((a, b) => compare(a, b, 'franchiseName', 'asc')));
        if (result.data.length === 1) {
          setSelectedFranchise(result.data[0]);
        }
      }
    }).catch((error) => {
      logError(error); 
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const loadStores = () => {
    setAllStores([]);
    setPickedStore(null);

    if (!selectedFranchise?.franchiseName) return;

    if (isAdmin()) {
      setIsLoading(true);
      getFranchiseStores(selectedFranchise?.franchiseName)
        .then((result) => {
          if (result.data?.stores) {
            setAllStores(result.data?.stores.filter(x => x.starbucksStoreNumber).sort((a, b) => compare(a, b, 'storeName', 'asc')));
          }
        }).catch((error) => {
          logError(error); 
        }).finally(() => {
          setIsLoading(false);
        });
    }
    else {
      setAllStores(user?.stores ?? []);
    }
  };

  const loadItems = (pageNumber: number, searchTerm: string) => {
    setAllItems([]);
    if (!selectedFranchise) return;

    setIsLoading(true);

    const itemParams: ItemFilterParams = {
      marketName: selectedFranchise?.franchiseName ?? '',
      storeNumber: pickedStore?.storeNumber ?? '',
      search: searchTerm,
      pageNumber: pageNumber,
      pageSize: rowsPerPage,
    };
    client.getItemsByFranchiseName(itemParams).then((result) => {
      if (result.data) {
        const uniqueConfigurations = result.data.items
          .reduce((acc: Item[], config) => {
            if (!acc.some(item => item.sku === config.sku)) {
              acc.push(config);
            }
            return acc;
          }, []);
        setAllItems(uniqueConfigurations);
        setMaxPages(result.data.totalPages);
      }
    }).catch((error) => {
      logError(error); 
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const loadConfigurations = (configLevel: ConfigurationLevel | null) => {
    setAllConfigurations([]);
    setSelectedConfig(null);
    setSelectedIndex(-1);

    if (!configLevel) return;

    setIsLoading(true);

    podClient.getConfigurations(configLevel, selectedFranchise?.franchiseName, pickedStore?.storeNumber)
      .then(res => {
        if (configLevel == ConfigurationLevel.Franchise) {
          setExistingConfigurations(res.filter(x => x.itemNumber));
          setExistingStoreConfigurations(res.filter(x => !x.itemNumber));
          return;
        }
        setExistingConfigurations(res);
      }).catch((error) => {
        logError(error);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const getAvailableConfigsFromItems = (configurationLevel: ConfigurationLevel | null) => {
    return allItems.map((x) => {
      const config: PodConfigurationModel = {
        id: 0,
        franchiseName: selectedFranchise?.franchiseName ?? '',
        itemNumber: x.sku ?? '',
        itemDescription: x.name ?? '',
        configurationLevel: configurationLevel ?? ConfigurationLevel.Store,
        maxOrderQuantity: null,
        safetyStock: 0,
        isParEnabled: false,
        enabled: false,
        storeNumber: '',
        storeName: '',
        starbucksStoreNumber: '',
      };

      if (configurationLevel == ConfigurationLevel.Store) {
        config.storeNumber = pickedStore?.storeNumber ?? '';
        config.storeName = pickedStore?.storeName ?? '';
        config.starbucksStoreNumber = pickedStore?.starbucksStoreNumber ?? '';
      }

      return config;
    });
  };

  const handleFranchiseChange = (event: SyntheticEvent, value: Franchise | null) => {
    setSelectedFranchise(value);
  };

  const handleStoreChange = (event: SyntheticEvent, value: Store | null) => {
    setPickedStore(value);
  };

  const handleConfigChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;

    const minValue = name === 'maxOrderQuantity' ? 1 : 0;
    
    const newValue = Number(value);

    const isValidNumber = /^\d*$/.test(value);

    if (value !== '' && (!isValidNumber || newValue < minValue)) {
      return;
    }

    let hasIndexChanged = false;
    const updatedItems = [...allConfigurations];

    if (selectedConfig && selectedIndex !== index) {
      updatedItems[selectedIndex] = { ...selectedConfig };
      hasIndexChanged = true;
    }

    if (hasIndexChanged || !selectedConfig) {
      setSelectedConfig(allConfigurations[index]);
    }

    updatedItems[index] = { 
      ...updatedItems[index], 
      [name]: value === '' ? '' : newValue.toString(),
    };

    setAllConfigurations(updatedItems);
    setSelectedIndex(index);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = event.target;

    let hasIndexChanged = false;
    const updatedItems = [...allConfigurations];

    if (selectedConfig && selectedIndex !== index) {
      updatedItems[selectedIndex] = { ...selectedConfig };
      hasIndexChanged = true;
    }

    if (hasIndexChanged || !selectedConfig) {
      setSelectedConfig(allConfigurations[index]);
    }

    updatedItems[index] = { 
      ...updatedItems[index], 
      [name]: checked,
    };

    setAllConfigurations(updatedItems);
    setSelectedIndex(index);
  };

  const handleCancel = (index: number) => {
    if (!selectedConfig) {
      return;
    }

    const updatedItems = [...allConfigurations];
    updatedItems[index] = { ...selectedConfig };

    setAllConfigurations(updatedItems);
    setSelectedConfig(null);
    setSelectedIndex(-1);
  };

  const handlePageChange = (pageNumber: number) => {
    handleCancel(selectedIndex);
    setPage(pageNumber);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;

    handleCancel(selectedIndex);
    setSearch(newSearchValue);
  };

  const handleSave = (item: PodConfigurationModel) => {
    setIsLoading(true);
    const isCurrentlyEnabled = selectedConfig?.enabled;
    setSelectedConfig(null);
    setSelectedIndex(-1);

    item.maxOrderQuantity = item.maxOrderQuantity === '' ? null : item.maxOrderQuantity;
    item.safetyStock = item.safetyStock === '' ? null : item.safetyStock;

    if (item.configurationLevel === ConfigurationLevel.Franchise && item.itemNumber) {
      item.storeName = '';
      item.storeNumber = '';
      item.starbucksStoreNumber = '';
    }

    if (item.id > 0) {
      podClient.update(item)
        .then(() => {
          loadConfigurations(configurationLevel);
          if (!isCurrentlyEnabled && item.enabled && item.itemNumber) setIsPodInfoModalOpen(true);
          setShowSuccessMessage(true);
        }).catch((error) => {
          logError(error);
          setShowErrorMessage(true);
        }).finally(() => {
          setIsLoading(false);
        });
      return;
    }

    podClient.create(item)
      .then(() => {
        loadConfigurations(configurationLevel);
        if (item.enabled && item.itemNumber) setIsPodInfoModalOpen(true);
        setShowSuccessMessage(true);
      }).catch((error) => {
        logError(error);
        setShowErrorMessage(true);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = () => {
    if (page === 0) {
      loadItems(0, search);
    }
    setPage(0);
  };

  const handleClear = () => {
    if (search === '' && page === 0) return;

    setSearch('');
    setPage(0);
    loadItems(0, '');
  };

  const hasPendingChanges = (config: PodConfigurationModel) => JSON.stringify(selectedConfig) !== JSON.stringify(config);

  const getItemUom = (itemNumber: string) => {
    const item = allItems.find(x => x.sku === itemNumber);
    
    const primaryUom = item?.primaryUom;
    const orderableUom = item?.orderableUom;
    return primaryUom ?? orderableUom ?? 'EA';
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingWheel />;
    }

    if (configurationLevel === ConfigurationLevel.Global) {
      return (
        <FranchiseConfigurationsList franchises={allFranchises}
          existingFranchiseConfigurations={existingConfigurations}
          handleSave={handleSave}></FranchiseConfigurationsList>
      );
    }

    if (configurationLevel == ConfigurationLevel.Franchise) {
      return (
        <>
          <Accordion sx={{ mb: '25px' }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
            >
              <Box sx={{ display: 'flex', p: '8px' }}>
                <Typography sx={{ pl: '5px' }}>
                  {t('storeConfigurations')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {
                selectedFranchise && 
                <StoreConfigurationsList franchiseName={selectedFranchise?.franchiseName ?? ''}
                  stores={allStores}
                  existingStoreConfigurations={existingStoreConfigurations}
                  handleSave={handleSave}></StoreConfigurationsList>
              }
            </AccordionDetails>
          </Accordion>
          {renderData()}
        </>
      );
    }

    if (allConfigurations.length == 0) {
      return (
        <Typography variant="textMD"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left' }}>{t('noData')}
        </Typography>
      );
    }
    
    return renderData();
  };

  const renderData = () => {
    return (
      <>
        <Grid container
          alignItems={'center'}
          justifyContent={'space-between'}
          spacing={2}
          mb={5}>
          <Grid item
            xs={12}
            md={8}>
            <FormControl fullWidth
              sx={{ p: '10px' }}>
              <TextField placeholder={t('search')}
                size='small'
                autoComplete='off'
                id="search-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon><SearchSm/></SvgIcon>
                    </InputAdornment>
                  ),
                }}
                value={search}
                onChange={handleSearchChange}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item
            xs={12}
            md={2}>
            <Button
              fullWidth
              variant="primary"
              size="lg"
              onClick={() => handleSearch()}>
              {t('search')}
            </Button>
          </Grid>
          <Grid item
            xs={12}
            md={2}>
            <Button
              fullWidth
              variant="secondary"
              size="lg"
              onClick={() => handleClear()}>
              {t('clear')}
            </Button>
          </Grid>
        </Grid>
        <TableContainer sx={{ overflowY:'auto' }}>
          <Table sx={{ tableLayout: 'fixed', minWidth: 900, overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('itemNumber')}</TableCell>
                <TableCell>{t('description')}</TableCell>
                <TableCell>{t('maxOrderQuantity')}</TableCell>
                <TableCell>{t('safetyStock')}</TableCell>
                {
                  configurationLevel === ConfigurationLevel.Store &&
                  <TableCell>{t('parEnabled')}</TableCell>
                }
                <TableCell>{t('predictiveOrdering')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                allConfigurations.map((row, index) => (
                  <TableRow
                    key={`${row.franchiseName}-${row.storeNumber}-${row.itemNumber}`}
                  >
                    <TableCell>
                      {row.itemNumber}
                    </TableCell>
                    <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {row.itemDescription}
                    </TableCell>
                    <TableCell>
                      <TextField value={row.maxOrderQuantity ?? ''}
                        type='text'
                        name='maxOrderQuantity'
                        InputProps={{
                          inputMode: 'numeric',
                          endAdornment: (
                            <InputAdornment position="end">
                              {getItemUom(row.itemNumber)}
                            </InputAdornment>
                          ),
                        }}
                        disabled={!row.enabled}
                        onChange={($event) => handleConfigChange($event, index)}></TextField>
                    </TableCell>
                    <TableCell>
                      <TextField value={row.safetyStock ?? ''}
                        type='text'
                        name='safetyStock'
                        InputProps={{
                          inputMode: 'numeric',
                          endAdornment: (
                            <InputAdornment position="end">
                              {getItemUom(row.itemNumber)}
                            </InputAdornment>
                          ),
                        }}
                        disabled={!row.enabled}
                        onChange={($event) => handleConfigChange($event, index)}>
                      </TextField>
                    </TableCell>
                    {
                      configurationLevel === ConfigurationLevel.Store &&
                      <TableCell>
                        <Checkbox checked={row.isParEnabled}
                          name='isParEnabled'
                          disabled={!row.enabled}
                          onChange={($event) => handleCheckboxChange($event, index)}></Checkbox>
                      </TableCell>
                    }
                    <TableCell>
                      <Checkbox checked={row.enabled}
                        name='enabled'
                        onChange={($event) => handleCheckboxChange($event, index)}></Checkbox>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {
                        selectedIndex === index && hasPendingChanges(row) &&
                        <>
                          <Button
                            variant="primary"
                            sx={{ m: 1 }}
                            size="md"
                            onClick={() => handleSave(row)}>
                            {t('save')}
                          </Button>
                          <Button
                            variant="secondary"
                            sx={{ m: 1 }}
                            size="md"
                            onClick={() => handleCancel(index)}>
                            {t('cancel')}
                          </Button>
                        </>
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {
          allConfigurations.length > 0 ?
            <CustomPagination
              page={page}
              setPage={handlePageChange}
              maxPages={maxPages}
              breakpointForChangeDisplay={120000}
            ></CustomPagination>
            :
            <Typography sx={{ p: '12px' }}>{t('noData')}</Typography> 
        }
      </>
    );
  };

  return (
    <PageArea>
      <Box
        sx={{
          textAlign: 'left',
          color: 'black',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}>
        <PageTitle>{' ' + t('title')}</PageTitle>
      </Box>
      <Grid container
        sx={{ mb: '12px' }}
        alignItems={'center'}
        spacing={5}>
        <Grid item
          xs={12}
          md={6}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              options={allFranchises}
              value={selectedFranchise}
              onChange={handleFranchiseChange}
              getOptionKey={(option) => option.franchiseName ?? ''}
              getOptionLabel={(option) => option.franchiseName ?? ''}
              disabled={allFranchises.length <= 1}
              renderInput={(params) => (
                <TextField {...params}
                  label={t('franchise')}
                  placeholder={t('selectFranchise')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          xs={12}
          md={6}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              options={allStores}
              value={pickedStore}
              onChange={handleStoreChange}
              getOptionKey={(option) => option.storeNumber ?? ''}
              getOptionLabel={(option) => option.storeName ?? ''}
              renderInput={(params) => (
                <TextField {...params}
                  label={t('store')}
                  placeholder={t('selectStore')} />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', p: '12px', border: `solid 1px ${theme.palette.custom.gray[200]}`,
        borderRadius: '4px',
      }}>
        { renderContent() }
      </Box>
      <PodConfigurationInfo
        open={isPodInfoModalOpen}
        onClose={() => setIsPodInfoModalOpen(false)}
      />
      <MessagesSnackbar
        open={showSuccessMessage}
        onClose={() => setShowSuccessMessage(false)}
        message={t('successfullyUpdated')}
        severity="success"
        duration={3000}
      />
      <MessagesSnackbar
        open={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
        message={t('errorUpdating')}
        severity="error"
        duration={null}
      />
    </PageArea>
  );
};

export default ManagePodConfigurations;