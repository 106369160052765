import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Franchise, Notification, NotificationType, Store, SuccessResponse_1OfIEnumerable_1OfFranchise } from '../../../app/services/api/generated';
import { Autocomplete, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import useNswagClient from '../../../hooks/api/useNswagClient';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useLogError from '../../../hooks/useLogError';
import { NotificationTypeEnum } from '../enums/NotificationTypeEnum';
import { UserContext } from '../../../components/shared/useUser';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface SubmitModalProps {
  openModal: boolean;
  onConfirm: Function;
  notification: Notification | undefined;
  onCancel: () => void;
}

const CreateEditModal: React.FC<SubmitModalProps> = ({ openModal, onConfirm, notification, onCancel }) => {
  const { t } = useTranslation('manageNotifications');
  const { getFranchises } = useNswagClient();
  const [request, setRequest] = useState<Notification | undefined>(undefined);
  const [franchises, setFranchises] = useState<Franchise[] | undefined>([]);
  const [stores, setStores] = useState<Store[] | undefined>([]);
  const [selectedFranchises, setSelectedFranchises] = useState<number[]>([]);
  const [selectedStores, setSelectedStores] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { logError } = useLogError();
  const [allStores, setAllStores] = useState(false);
  const SELECT_ALL = 'Select All';
  const UNSELECT_ALL = 'Unselect All';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user }: any = React.useContext(UserContext);
  const isValid = request?.title && request?.notificationType !== undefined && 
  request?.startDate && request?.endDate && request.startDate <= request.endDate &&
  request?.message &&
  ((request?.notificationType === NotificationTypeEnum.SYSTEM_NOTIFICATION && selectedFranchises.length > 0 || selectedStores.length > 0) ||
   request?.notificationType === NotificationTypeEnum.NOTIFICATION && selectedStores.length > 0);

  const isCreate = notification?.notificationId == undefined;

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ size: [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'header': '1' }, { 'header': '2' },{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
  ];

  const modules = {
    toolbar: toolbarOptions,
  };
  useEffect(() => {
    const newRequest = {
      notificationId : notification?.notificationId,
      notificationType : notification?.notificationType ?? 0,
      startDate: convertUtcToLocal(notification?.startDate),
      endDate: convertUtcToLocal(notification?.endDate),
      title : notification?.title ?? '',
      message : notification?.message ?? '',
    };

    setRequest(newRequest);
    setSelectedFranchises(notification?.franchiseIds ?? []);
    setSelectedStores(notification?.storeIds ?? []);
  }, [notification]);
  
  useEffect(() => {
    if (openModal){
      loadData();
    }
  }, [openModal]);

  const loadData = () => {
    setIsLoading(true);
    getFranchises().then((result: SuccessResponse_1OfIEnumerable_1OfFranchise) => {
      if (result?.data) {
        setFranchises(result.data); 
      }
    })
      .catch((error) => {
        logError(error);
      })
      .finally(() => (setIsLoading(false)));

    setStores(user.stores);
    if(!isCreate && user.stores.length === notification?.storeIds?.length){
      setAllStores(true);
    } 
  };

  const convertUtcToLocal = (date: string | undefined) => {
    if (!date) {
      return '';
    }
    return dayjs(new Date(date + 'Z')).format('YYYY-MM-DDTHH:mm');
  };

  const handleCancel = () => {
    if(isCreate){
      const newRequest = {
        notificationId : notification?.notificationId,
        notificationType : notification?.notificationType ?? 0,
        startDate:  '',
        endDate: '',
        title : notification?.title ?? '',
        message : notification?.message ?? '',
      };
      setRequest(newRequest);
    }
    onCancel();
  };

  const handleConfirm = () => {
    let notificationActive = false;  
    if(request?.endDate) {
      const endDateObj = new Date(request?.endDate);
      const today = new Date();
      notificationActive = endDateObj >= today;
    }
   
    const newRequest = {
      ...request,
      startDate: dayjs(request?.startDate).utc().toISOString(), //convert to utc
      endDate: dayjs(request?.endDate).utc().toISOString(),
      franchiseIds: request?.notificationType == NotificationTypeEnum.SYSTEM_NOTIFICATION ? selectedFranchises : undefined,
      storeIds: selectedStores,
      active: notificationActive,
    };
    onConfirm(newRequest);
    setRequest(undefined);
    onCancel();
  };

  useEffect(() => {
    if (request?.notificationType == NotificationTypeEnum.NOTIFICATION){
      setSelectedFranchises([]);
    }
  }, [request?.notificationType]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRequest({ ...request, [name]: value });
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const endDateInput = document.getElementById('datetime-input-end') as HTMLInputElement;
    if (endDateInput) {
      endDateInput.min = event.target.value;
    }
    const { value } = event.target;
    setRequest((prevRequest) => ({
      ...prevRequest,
      startDate: value,
    }));
  };
  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setRequest((prevRequest) => ({
      ...prevRequest,
      endDate: value,
    }));
  };

  const handleNotifTypeChange = (event: SelectChangeEvent<number>) => {
    setRequest({ ...request, notificationType: event.target.value as NotificationType });
  };

  const handleFranchiseChange = (event: React.SyntheticEvent, newValue: Franchise[]) => {
    if (newValue.some(store => store.franchiseName === 'Select All')) {
      setSelectedFranchises(franchises?.map(option => option.franchiseId) as number[] || []);
    } else {
      const selectedIds = newValue.map(option => option.franchiseId);
      setSelectedFranchises(selectedIds as number[]);
    }
  };
  
  const handleStoreChange = (event: React.ChangeEvent<{}>, newValue: Store[]) => {
    if (newValue.some(store => store.storeNumber === SELECT_ALL)) {
      setAllStores(true);
      setSelectedStores(stores?.map(option => option.storeNumber) as string[] || []);
    }
    else if (newValue.some(store => store.storeNumber === UNSELECT_ALL)) {
      setAllStores(false);
      setSelectedStores([]);
    } else {
      const selectedIds = newValue.map(option => option.storeNumber);
      setSelectedStores(selectedIds as string[]);
    }
  };

  const notificationTypesOptions = [
    { key: 0, value: 'Notification' },
    { key: 1, value: 'System Notification' },
  ];

  const getNotificationTypes =() => {
    if (user.userLevel == 'Admin') {
      return notificationTypesOptions;
    } else {
      return notificationTypesOptions.filter(option => option.key === 0);
    }
  };
  const notificationTypes = getNotificationTypes();

  const generateStoreOptions = () => {
    return [
      { storeNumber: allStores ? UNSELECT_ALL : SELECT_ALL, storeName: allStores ? UNSELECT_ALL : SELECT_ALL },
      ...(stores || []),
    ];
  };

  const generateFranchiseOptions = () => {
    return [
      { franchiseId: 0, franchiseName: 'Select All' },
      ...(franchises || []),
    ];
  };

  const formItemStyling = { display: 'flex', flexDirection: 'column', alignItems: 'left'  };

  return isLoading ? <LoadingWheel></LoadingWheel> : (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      sx={{ p: 0 }}
      maxWidth='lg'
    >
      <DialogTitle sx={{ m: 4, p: 4 }}>
        {isCreate ? t('createNotification') : t('editNotification')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.custom.gray[800],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <Grid
          container
          spacing={6}
          alignItems='self-end'
          p={6}
        >
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('title')}</Typography>
            <TextField
              fullWidth
              required
              name='title'
              placeholder='Title'
              id="input-with-icon-textfield"
              value={request?.title}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('notificationType')}</Typography>
            <FormControl>
              <Select
                value={request?.notificationType as number ?? 0}
                onChange={handleNotifTypeChange}
                fullWidth
                required
              >
                {notificationTypes.map((t) => (
                  <MenuItem key={t.key}
                    value={t.key}>
                    {t.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('message')}</Typography>
            <ReactQuill theme="snow"
              style={{ zIndex: 3 }}
              placeholder='Message...'
              value={request?.message} 
              onChange={(e: string | undefined) => setRequest({ ...request, message: e })} 
              modules={modules}/>
          </Grid>
          <Grid item
            sx={{ marginTop: '8px' }}
            sm={6}
            xs={12}>
            <FormControl fullWidth
              size='small'>
              <TextField
                id="datetime-input"
                label={t('startDate')}
                type='datetime-local' 
                size='small'
                variant="outlined"
                value={request?.startDate?.slice(0, 16)}
                onChange={handleStartDateChange}
                InputLabelProps={{ 
                  shrink: true,
                }}
                inputProps={{
                  min: dayjs().startOf('day').format('YYYY-MM-DDTHH:mm'),
                }}
              />
            </FormControl>
          </Grid>
          
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('to')}</Typography>
          </Grid>
          <Grid item
            sm={6}
            xs={12}>
            <FormControl fullWidth
              size='small'>
              <TextField
                id="datetime-input"
                label={t('endDate')}
                type='datetime-local' 
                size='small'
                variant="outlined"
                value={request?.endDate?.slice(0, 16)}
                onChange={handleEndDateChange}
                InputLabelProps={{ 
                  shrink: true,
                }}
                inputProps={{
                  min: request?.startDate?.slice(0, 16) || dayjs().startOf('day').format('YYYY-MM-DDTHH:mm'),
                }}
                
              />
            </FormControl>
          </Grid>
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('stores')}</Typography>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                multiple
                options={generateStoreOptions()}
                value={stores?.filter(option => selectedStores.includes(option.storeNumber ?? '')) ?? []}
                onChange={handleStoreChange}
                getOptionKey={(option) => option.storeNumber ?? 0}
                getOptionLabel={(option) => option.storeName ?? ''}
                renderInput={(params) => (
                  <TextField {...params}
                    required/>
                )}
              />
            </FormControl>
          </Grid>
          {request?.notificationType == NotificationTypeEnum.SYSTEM_NOTIFICATION &&
          <Grid item
            xs={12}
            sx={formItemStyling}>
            <Typography variant='textSM'>{t('franchises')}</Typography>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                multiple
                options={generateFranchiseOptions()}
                value={franchises?.filter(option => selectedFranchises.includes(option.franchiseId ?? 0)) ?? []}
                onChange={handleFranchiseChange}
                getOptionKey={(option) => option.franchiseId ?? 0}
                getOptionLabel={(option) => option.franchiseName ?? ''}
                renderInput={(params) => (
                  <TextField {...params}
                    required/>
                )}
              />
            </FormControl>
          </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mb: 4, mr: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <Button
          variant='primary'
          size="lg"
          disabled={!isValid}
          onClick={handleConfirm}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CreateEditModal;