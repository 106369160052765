import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PageArea from '../../../components/shared/PageArea';
import PageTitle from '../../../components/shared/PageTitle';
import BackButton from '../../../components/shared/actions/BackButton';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useTranslation } from 'react-i18next';
import { Plus, Save01, Trash01 } from '../../../assets';
import { Dropzone, FileMosaic } from '@files-ui/react';
import { useEffect, CSSProperties, useState, useContext } from 'react';
import { TinyColor } from '@ctrl/tinycolor';
import { MenuItem, TextField, Snackbar, Alert, SvgIcon } from '@mui/material';
import { BrandingResponse, Palette, UndetailedBrandingsResponse, Store } from '../../../app/services/api/generated';
import { getFileFromBase64, convertFileToImage } from '../../../utils';
import { theme } from '../../../theme';
import { PageState } from '../../../enums/PageState';
import { Operation } from '../../../enums/Operation';
import { Result } from '../../../enums/Result';
import { MuiColorInput } from 'mui-color-input';
import useLogError from '../../../hooks/useLogError';
import { useParams, Form } from 'react-router-dom';
import { ThemeContext } from '../../../theme/context/themeProviderContext';
import { UserContext } from '../../../components/shared/useUser';
import { useSelectedBranding } from '../../../hooks/useSelectedBranding';
import '../customStyle/CustomizeBranding.css';

const CustomizeBranding = () => {
  const { t } = useTranslation('manageBrandings');
  const [pageState, setPageState] = useState<PageState>(PageState.Default);
  const [selectedBranding, setSelectedBranding] = useState<BrandingResponse>();
  const [allBrandings, setAllBrandings] = useState<UndetailedBrandingsResponse[] | null>(null);
  const [haveAllBrandingsLoaded, setHaveAllBrandingsLoaded] = useState<boolean>(false);
  const { isOrderlyAdmin } = useContext(UserContext);
  const [isSelectedBrandingLoaded, setIsSelectedBrandingLoaded] = useState<boolean>(false);
  const [brandingName, setBrandingName] = useState<string>();
  const [primaryColor, setPrimaryColor] = useState<string>('#000000');
  const [secondaryColor, setSecondaryColor] = useState<string>('#000000');
  const [primaryTextColor, setPrimaryTextColor] = useState<number>(1);
  const [secondaryTextColor, setSecondaryTextColor] = useState<number>(1);
  const [dashboardImage, setDashboardImage] = useState<ExtFile[]>([]);
  const [logoImage, setLogoImage] = useState<ExtFile[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [logoImageValidSize, setLogoImageValidSize] = useState<boolean>(false);
  const [dashboardImageValidSize, setDashboardImageValidSize] = useState<boolean>(false);
  const [operationResult, setOperationResult] = useState<Result>(Result.Fail);
  const [operation, setOperation] = useState<Operation>(Operation.Create);
  const [,changeBranding] = useContext(ThemeContext);
  const { id } = useParams();
  const { logError } = useLogError();
  const { getAllBrandingForFranchise, getBranding, createBranding, updateBranding, deleteBranding, getAllBranding } = useNswagClient();
  const branding = useSelectedBranding();
  const [selectedStore] = useState<Store | null>(() => {
    const storedStore = localStorage.getItem('selectedStore');
    return storedStore ? JSON.parse(storedStore) : null;
  });
  const [isCreating, setIsCreating] = useState<boolean>(false);

  useEffect(() => {
    if (isOrderlyAdmin()) {
      loadAllBrandings();
    } else {
      loadBrandingsForFranchise();
    }
  }, []);

  const loadAllBrandings = () => {
    getAllBranding()
      .then((result) => {
        if (result?.data) {
          setAllBrandings(result?.data);
        }
      })
      .catch((error) => {
        logError(error);
      })
      .finally(() => {
        setHaveAllBrandingsLoaded(true);
      });
  };

  const loadBrandingsForFranchise = () => {
    if (selectedStore?.franchiseId) {
      const franchiseId = id != undefined ? Number(id) : selectedStore.franchiseId;
      getAllBrandingForFranchise(franchiseId)
        .then((result) => {
          if (result?.data) {
            setAllBrandings(result?.data);
          }
        })
        .catch((error) => {
          logError(error);
        })
        .finally(() => {
          setHaveAllBrandingsLoaded(true);
        });
    }
  };

  const loadABranding = (idString: string) => {
    const id = Number(idString);
    setPageState(PageState.Edit);
    setIsSelectedBrandingLoaded(false);
    getBranding(id)
      .then((result) => {
        if (result?.data) {
          setSelectedBranding(result?.data);
          setBrandingName(result?.data.name);
          setPrimaryColor(result?.data.primaryColors?.color500 ?? '#FFFFFF');
          setSecondaryColor(result?.data.secondaryColors?.color500 ?? '#FFFFFF');
          setPrimaryTextColor(result?.data.primaryColors?.contrastTextColor == '#000000' ? 0 : 1);
          setSecondaryTextColor(result?.data.secondaryColors?.contrastTextColor == '#000000' ? 0 : 1);
          setIsSelectedBrandingLoaded(true);

          if (result?.data.logoImage != null) {
            const logoFile = getFileFromBase64(
              result?.data.logoImage,
              'Logo',
            );

            const logoImage : ExtFile = {
              id: 0,
              file: logoFile,
              name: 'logoImage',
              type: logoFile.type,
              size: logoFile.size,
              uploadStatus: 'success',
              valid: true,
              errors: [],
            };

            changeLogoImage([logoImage]);
            setLogoImageValidSize(true);   
          }

          if (result?.data.dashboardImage != null) {
            const dashboardFile = getFileFromBase64(
              result?.data.dashboardImage,
              'Dashboard',
            );

            const dashboardImage : ExtFile  = {
              id: 0,
              file: dashboardFile,
              name: 'dashboardImage',
              type: dashboardFile.type,
              size: dashboardFile.size,
              uploadStatus: 'success',
              valid: true,
              errors: [],
            };

            changeDashboardImage([dashboardImage]);
            setDashboardImageValidSize(true);   
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  const startCreatingABranding = () => {
    restoreDefaults();
    setPageState(PageState.Creation);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeLogoImage = (incomingFiles: any[]) => {
    if (incomingFiles.length > 0) {
      const file = incomingFiles[0].file as File;
      setLogoImageValidSize(false);
      convertFileToImage(file)
        .then((result) => {
          if (result.width != 40 || result.height != 40) {
            incomingFiles[0].valid = false;
            incomingFiles[0].errors = ['Files must be 40px x 40px'];
          } else {
            setLogoImageValidSize(true);          
          }
          setLogoImage(incomingFiles);
        })
        .catch((error) => {
          logError(error);
        });
    } else {
      setLogoImage([]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeDashboardImage = (incomingFiles: any[]) => {
    if (incomingFiles.length > 0) {
      const file = incomingFiles[0].file as File;
      setDashboardImageValidSize(false);
      convertFileToImage(file)
        .then((result) => {
          if (result.width != 2000 || result.height != 1000) {
            incomingFiles[0].valid = false;
            incomingFiles[0].errors = ['Files must be 2000px x 1000px'];
          } else {
            setDashboardImageValidSize(true);
          }
          setDashboardImage(incomingFiles);
        })
        .catch((error) => {
          logError(error);
        });
    } else {
      setDashboardImage([]);
    }
  };

  interface FileParameter {
    data: Blob;
    fileName: string;
  }

  interface ExtFile {
    id: number | string,
    file: File,
    name: string,
    type: string,
    size: number,
    valid: boolean,
    errors: string[],
    uploadStatus: UPLOADSTATUS
  }

  type UPLOADSTATUS = 'preparing' | 'aborted' | 'uploading' | 'success' | 'error';
  
  const extFileToFileParameter = (extFile: ExtFile): Promise<FileParameter> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result) {
          const fileParameter: FileParameter = {
            data: new Blob([e.target.result], { type: extFile.type }),
            fileName: extFile.name,
          };
          resolve(fileParameter);
        } else {
          reject(new Error('File reading failed'));
        }
      };
      
      reader.onerror = () => {
        reject(new Error('File reading error'));
      };
      
      reader.readAsArrayBuffer(extFile.file);
    });
  };

  const generatePalette = (colorCode: string, textColorCode: number) => {
    const colorHSL = new TinyColor(colorCode).toHsl();

    const palette: Palette = {
      25: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 97.5 }).toHexString(false),
      50: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 95 }).toHexString(false),
      100: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 90 }).toHexString(false),
      200: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 80 }).toHexString(false),
      300: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 70 }).toHexString(false),
      400: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 65 }).toHexString(false),
      500: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 55 }).toHexString(false),
      600: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 45 }).toHexString(false),
      700: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 40 }).toHexString(false),
      800: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 35 }).toHexString(false),
      900: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 27.5 }).toHexString(false),
      950: new TinyColor({ h: colorHSL.h, s: colorHSL.s, l: 20 }).toHexString(false),
      contrastText: textColorCode == 0 ? '#000000' : '#FFFFFF',
    };

    return palette;
  };

  const createABranding = async () => {
    setIsCreating(true);
    setOperation(Operation.Create);
    setOperationResult(Result.Fail);
    const primaryColors = generatePalette(primaryColor, primaryTextColor);
    const secondaryColors = generatePalette(secondaryColor, secondaryTextColor);

    try {
      await createBranding(
        brandingName,
        selectedStore?.franchiseId,
        primaryColors[950],
        primaryColors[900],
        primaryColors[800],
        primaryColors[700],
        primaryColors[600],
        primaryColors[500],
        primaryColors[400],
        primaryColors[300],
        primaryColors[200],
        primaryColors[100],
        primaryColors[50],
        primaryColors[25],
        primaryColors.contrastText,
        secondaryColors[950],
        secondaryColors[900],
        secondaryColors[800],
        secondaryColors[700],
        secondaryColors[600],
        secondaryColors[500],
        secondaryColors[400],
        secondaryColors[300],
        secondaryColors[200],
        secondaryColors[100],
        secondaryColors[50],
        secondaryColors[25],
        secondaryColors.contrastText,
        await extFileToFileParameter(logoImage[0]),
        await extFileToFileParameter(dashboardImage[0]));
      setOperationResult(Result.Success);
    } catch (error) {
      logError(error);
    } finally {
      setIsCreating(false);
      setShowMessage(true);
      setPageState(PageState.Default);
      restoreDefaults();
    }
  };

  const updateABranding = async () => {
    setOperation(Operation.Update);
    setOperationResult(Result.Fail);
    const primaryColors = generatePalette(primaryColor, primaryTextColor);
    const secondaryColors = generatePalette(secondaryColor, secondaryTextColor);

    updateBranding(
      selectedBranding?.id,
      brandingName,
      selectedStore?.franchiseId,
      primaryColors[950],
      primaryColors[900],
      primaryColors[800],
      primaryColors[700],
      primaryColors[600],
      primaryColors[500],
      primaryColors[400],
      primaryColors[300],
      primaryColors[200],
      primaryColors[100],
      primaryColors[50],
      primaryColors[25],
      primaryColors.contrastText,
      secondaryColors[950],
      secondaryColors[900],
      secondaryColors[800],
      secondaryColors[700],
      secondaryColors[600],
      secondaryColors[500],
      secondaryColors[400],
      secondaryColors[300],
      secondaryColors[200],
      secondaryColors[100],
      secondaryColors[50],
      secondaryColors[25],
      secondaryColors.contrastText,
      await extFileToFileParameter(logoImage[0]),
      await extFileToFileParameter(dashboardImage[0]))
      .then(() => {
        setOperationResult(Result.Success);
        if(selectedBranding?.id && branding.id == selectedBranding?.id) {
          getBranding(selectedBranding.id)
            .then((result) => {
              if(result?.data) {
                changeBranding(result.data);
                window.location.reload();
              }
            });
        }
      })
      .catch((error) => {
        logError(error);
      })
      .finally(() => {
        setShowMessage(true);
        setPageState(PageState.Default);
        restoreDefaults();
      });
  };

  const restoreDefaults = () => {
    setSelectedBranding(undefined);
    setIsSelectedBrandingLoaded(false);
    setAllBrandings([]);
    setHaveAllBrandingsLoaded(false);
    loadAllBrandings();
    setBrandingName('');
    setPrimaryColor('#8c8c8c');
    setSecondaryColor('#8c8c8c');
    setPrimaryTextColor(0);
    setSecondaryTextColor(0);
    setLogoImage([]);
    setDashboardImage([]);
    setLogoImageValidSize(false);
    setDashboardImageValidSize(false);
  };

  const deleteABranding = () => {
    setOperation(Operation.Delete);
    setOperationResult(Result.Fail);

    if (selectedBranding?.id) {
      deleteBranding(selectedBranding.id)
        .then(() => {
          setOperationResult(Result.Success);
          if(selectedBranding?.id && branding.id == selectedBranding?.id) {
            localStorage.removeItem('branding');
            window.location.reload();
          }
        })
        .catch((error) => {
          logError(error);
        })
        .finally(() => {
          setShowMessage(true);
          setPageState(PageState.Default);
          restoreDefaults();
        });
    }
  };

  const getAlertMessage = (result: Result, operation: Operation) => {
    if (result == Result.Success && operation == Operation.Create)
      return t('successfullyCreatingBranding');

    if (result == Result.Success && operation == Operation.Delete)
      return t('successfullyDeletingBranding');

    if (result == Result.Success && operation == Operation.Update)
      return t('successfullyUpdatingBranding');

    if (result == Result.Fail && operation == Operation.Create)
      return t('errorCreatingBranding');

    if (result == Result.Fail && operation == Operation.Delete)
      return t('errorDeletingBranding');

    if (result == Result.Fail && operation == Operation.Update)
      return t('errorUpdatingBranding');

    return 'Unknown Error';
  };

  const changePrimaryColor = (color: string) => {
    const oldColor = new TinyColor(color).toHsv();
    const newColor = new TinyColor({ h: oldColor.s * 360, s: oldColor.v, l: 55 }).toHexString(false);

    setPrimaryColor(newColor == '#000000' ? '#8c8c8c' : newColor);
  };

  const changeSecondaryColor = (color: string) => {
    const oldColor = new TinyColor(color).toHsv();
    const newColor = new TinyColor({ h: oldColor.s * 360, s: oldColor.v, l: 55 }).toHexString(false);
    
    setSecondaryColor(newColor == '#000000' ? '#8c8c8c' : newColor);
  };

  const getDropdownMessage = () => {
    if(allBrandings?.length == 0)
      return t('brandingDropdownNoSelections');

    if(isInCreateMode())
      return t('brandingDropdownDuringCreation');

    return t('brandingDropdownDefaultValue');
  };

  const isFormValid = () => {
    return brandingName &&
        primaryColor &&
        secondaryColor &&
        dashboardImage[0] &&
        logoImage[0] &&
        dashboardImageValidSize &&
        logoImageValidSize &&
        dashboardImage[0].file.type === 'image/png' &&
        logoImage[0].file.type === 'image/png' &&
        dashboardImage[0].file.size <= 3000000 &&
        logoImage[0].file.size <= 500000;
  };

  const isInCreateMode = () => pageState == PageState.Creation;

  const isInEditMode = () => pageState == PageState.Edit;

  const isALogoImageSelected = () => logoImage.length > 0;

  const isADashboardImageSelected = () => dashboardImage.length > 0;

  const linkStyle: CSSProperties = {
    letterSpacing: 0,
    background: '#F2F4F7',
    border: `1px Solid ${theme.palette.custom.gray[300]}`,
    fontFamily: 'SoDoSans',
  };

  const footerStyle: CSSProperties = {
    letterSpacing: 0,
    padding: '8px',
    color: 'white',
    fontSize: '12px',
    fontWeight: 500,
    background: theme.palette.custom.gray[500],
  };

  return (
    <PageArea>
      <BackButton url='/manage-configurations'
        title={t('back')}
        isLink />
      <Grid container
        spacing={5}>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <PageTitle>{t('heading')}</PageTitle>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography variant='textMD'
            sx={{ my: 4 }}>
            {t('subheading')}
          </Typography>
        </Grid>
      </Grid>
      { !haveAllBrandingsLoaded &&
        <Grid mt={8}>
          <LoadingWheel />
        </Grid> 
      }

      { haveAllBrandingsLoaded &&
        <Grid>
          <Grid container
            mt={8}
            spacing={5}>
            <Grid item
              md={10}
              sm={12}>
              <TextField
                label={getDropdownMessage()}
                select
                disabled={isInCreateMode() || allBrandings?.length == 0}
                fullWidth
                onChange={(event) => {
                  loadABranding(event.target.value);
                }}
                value={selectedBranding}
              >
                {allBrandings?.map((branding) => (
                  <MenuItem key={branding.id}
                    value={branding.id}>
                    {branding.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item
              md={2}
              sm={12}
              spacing={5}>
              <Button
                variant='primary'
                fullWidth
                size='lg'
                startIcon={
                  <SvgIcon>
                    <Plus />
                  </SvgIcon>
                }
                disabled={isInCreateMode()}
                onClick={() => { startCreatingABranding(); }}
              >
                {t('createNew')}
              </Button>
            </Grid>
          </Grid>

          { pageState == PageState.Edit && !isSelectedBrandingLoaded &&
            <Grid mt={8}>
              <LoadingWheel />
            </Grid>
          }

          {( pageState == PageState.Edit && isSelectedBrandingLoaded || pageState == PageState.Creation) &&
            <Form>
              <Grid
                sx={{
                  border: '1px solid',
                  borderColor: theme.palette.custom.gray[300],
                  py: '8px',
                  px: '8px',
                  mt: '8px',
                }}
                spacing={5}
              >
                <TextField
                  value={brandingName}
                  label={isInEditMode() ? t('brandingName') : t('newBrandingName')}
                  required
                  fullWidth
                  onChange={(event) => setBrandingName(event.target.value)}
                  inputProps={{ maxLength: 50 }}
                />
                <Grid my={8}
                  display={'flex'}
                  justifyContent={'space-around'}>
                  <MuiColorInput
                    required
                    label={t('primaryColor')}
                    format={'hex'}
                    value={primaryColor}
                    onChange={(color) => (changePrimaryColor(color))}
                    isAlphaHidden={false}
                  />
                  <MuiColorInput
                    required
                    label={t('secondaryColor')}
                    format={'hex'}
                    value={secondaryColor}
                    onChange={(color) => (changeSecondaryColor(color))}
                    isAlphaHidden={false}
                  />
                </Grid>
                <Grid my={8}
                  display={'flex'}>
                  <TextField
                    required
                    fullWidth
                    sx={{ marginLeft: '4px', marginRight: '4px' }}
                    label={t('choosePrimaryTextColor')}
                    select
                    value={primaryTextColor}
                    onChange={(event) => (setPrimaryTextColor(Number(event.target.value)))}
                  >
                    <MenuItem value={0}>
                      {t('black')}
                    </MenuItem>
                    <MenuItem value={1}>
                      {t('white')}
                    </MenuItem>
                  </TextField>
                  <TextField
                    required
                    fullWidth
                    sx={{ marginLeft: '4px', marginRight: '4px' }}
                    label={t('chooseSecondaryTextColor')}
                    select
                    value={secondaryTextColor}
                    onChange={(event) => (setSecondaryTextColor(Number(event.target.value)))}
                  >
                    <MenuItem value={0}>
                      {t('black')}
                    </MenuItem>
                    <MenuItem value={1}>
                      {t('white')}
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid my={8}
                  item>
                  <Typography>{t('selectALogoImage')}</Typography>
                  <Dropzone
                    style={linkStyle}
                    footerConfig={{ style: footerStyle }}
                    headerConfig={{ validFilesCount: false }}
                    accept={'image/png'}
                    maxFiles={1}
                    maxFileSize={500000}
                    behaviour={'replace'}
                    onChange={changeLogoImage}
                    value={logoImage}
                  >
                    { !isALogoImageSelected() && (
                      <Grid>
                        <Typography>
                          {t('logoImageDropzone')}
                          <br />
                          {t('Or')}
                          <br />
                          {t('logoImageClickzone')}
                        </Typography>
                        <Typography variant='textXS'
                          sx={{ lineHeight: '2px' }}>
                          {t('logoImageDimensions')}
                        </Typography>
                      </Grid>
                    )}
                    {logoImage.map((logoImage) => (
                      <FileMosaic
                        backgroundBlurImage={false}
                        {...logoImage}
                        key={logoImage.id}
                        preview
                      />
                    ))}
                  </Dropzone>
                </Grid>
                <Grid item>
                  <Typography>{t('selectADashboardImage')}</Typography>
                  <Dropzone
                    style={linkStyle}
                    footerConfig={{ style: footerStyle }}
                    headerConfig={{ validFilesCount: false }}
                    accept={'image/png'}
                    maxFiles={1}
                    maxFileSize={3000000}
                    behaviour={'replace'}
                    onChange={changeDashboardImage}
                    value={dashboardImage}
                  >
                    { !isADashboardImageSelected() && (
                      <Grid>
                        <Typography>
                          {t('dashboardImageDropzone')}
                          <br />
                          {t('Or')}
                          <br />
                          {t('dashboardImageClickZone')}
                        </Typography>
                        <Typography sx={{ lineHeight: '2px' }}
                          variant='textXS'>
                          {t('dashboardImageDimensions')}
                        </Typography>
                      </Grid>
                    )}
                    { dashboardImage.map((dashboardImage) => (
                      <FileMosaic
                        backgroundBlurImage={false}
                        {...dashboardImage}
                        key={dashboardImage.id}
                        preview
                      />
                    ))}
                  </Dropzone>
                </Grid>
                { isInCreateMode() && (
                  <Grid
                    container
                    display={'flex'}
                    justifyContent={'flex-end'}
                    spacing={8}
                    mt={4}
                  >
                    <Button
                      variant='secondary'
                      size='lg'
                      onClick={() => {
                        setPageState(PageState.Default);
                        restoreDefaults();
                      }}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      variant='primary'
                      size='lg'
                      sx={{ ml: { sm: 2 } }}
                      startIcon={
                        <SvgIcon>
                          <Plus />
                        </SvgIcon>
                      }
                      onClick={() => { createABranding(); }}
                      disabled={!isFormValid() || isCreating}
                    >
                      {t('createBranding')}
                    </Button>
                  </Grid>
                )}

                { isInEditMode() && (
                  <Grid
                    container
                    display={'flex'}
                    justifyContent={'flex-end'}
                    spacing={8}
                    mt={4}
                  >
                    <Button
                      variant="secondary"
                      size="lg"
                      startIcon={
                        <SvgIcon>
                          <Trash01 />
                        </SvgIcon>
                      }
                      sx={{
                        borderColor: theme.palette.custom.gray[300],
                        color: theme.palette.custom.gray[700],
                      }}
                      onClick={() => {deleteABranding();
                      }}
                    >
                      {t('deleteBranding')}
                    </Button>
                    <Button
                      variant='primary'
                      size='lg'
                      sx={{ ml: { sm: 2 } }}
                      startIcon={
                        <SvgIcon>
                          <Save01 />
                        </SvgIcon>
                      }
                      onClick={() => {
                        updateABranding();
                      }}
                      disabled={!isFormValid()}
                    >
                      {t('saveChanges')}
                    </Button>
                  </Grid>)}
              </Grid>
            </Form>
          }
        </Grid>
      }

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setShowMessage(false)}
        open={showMessage}
        autoHideDuration={5000}
      >
        <Alert
          onClose={() => setShowMessage(false)}
          severity={operationResult == Result.Success ? 'success' : 'error'}
          variant='filled'
          sx={{ width: '100%' }}
        >
          { getAlertMessage(operationResult, operation) }
        </Alert>
      </Snackbar>
    </PageArea>
  );
};

export default CustomizeBranding;