import { Box, Divider, useTheme } from '@mui/material';
import { useContext } from 'react';
import { DrawerContext, DrawerValues } from '../../contexts/DrawerContext';
import { LayeredNavGroup } from '../LayeredNavGroup';
import { UserNavItemsList } from './UserNavItemsList';
import { StoreDisplay } from './StoreDisplay';

interface DesktopFooterProps {
    onLogOut: () => void,
    onSwitchStore: () => void,
}

export const DesktopFooter = ({ onLogOut, onSwitchStore }: DesktopFooterProps) => {
  const theme = useTheme();
  const { systemNavItemsToUse } = useContext(DrawerContext) as DrawerValues;
  
  return (
    <Box>
      <LayeredNavGroup navItemList={systemNavItemsToUse}/>
      <Divider
        variant='middle'
        color={theme.palette.primary[500]}
        sx={{
          m: 'auto',
          width: '100%',
        }}
      />
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        marginBottom='8px'
      >
        <StoreDisplay />
        <UserNavItemsList variant='iconColumn'
          logOutOnClick={onLogOut}
          switchStoreOnClick={onSwitchStore} />
      </Box>
    </Box>
  );
};