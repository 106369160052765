import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../../components/forms/Pagination';
import { useTranslation } from 'react-i18next';
import { CreditRequestRAGRecord } from '../types/CreditRequestRAGRecord';

export default function CreditReportsDenseTable(props: { 
  readonly list: CreditRequestRAGRecord[];
}) {
  const { t } = useTranslation('common');
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  const rows = props.list;

  const maxPages = () => {
    return Math.ceil(rows.length / rowsPerPage);
  };

  const visibleRows = () => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  return (
    <>
      <TableContainer component={'div'}>
        <Table
          size="small"
          aria-label="a table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {t('storeNumber')}
              </TableCell>
              <TableCell align="left">
                {t('storeName')}
              </TableCell>
              <TableCell align="left">
                {t('orderNumber')}
              </TableCell>
              <TableCell align="left">
                {t('creditedAmount')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows().map((row) => (
              <TableRow key={(row.orderNumber ?? '') + (row.creditedAmount ?? '')}>
                <TableCell>
                  {row.storeNumber}
                </TableCell>
                <TableCell>
                  {row.storeName}
                </TableCell>
                <TableCell>
                  {row.orderNumber}
                </TableCell>
                <TableCell>
                  {row.creditedAmount ?? '0'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        maxPages={maxPages()}
        breakpointForChangeDisplay={120000}
      ></CustomPagination>
    </>
  );
}
